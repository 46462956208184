import {Button, Divider, Flex, SnackbarService, String, withRegistration} from "@hps/hops-react";
import {CheckoutBasketPayment, PaymentTypes, PriceUtils} from "@hps/hops-sdk-js";
import Chip from "@mui/material/Chip";
import React, {useState} from "react";

import withReducedFunctionality from "Hoc/withReducedFunctionality";
import withSettings from "Hoc/withSettings";
import BasketService from "Services/BasketService.js";
import StripeService from "Services/StripeService";

import PaymentPanelItemDetails from "./PaymentPanelItemDetails";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import scss from "./PaymentPanelItem.module.scss";


/**
 * Item in the payment sidebar pane
 */
const PaymentPanelItem = props => {

	const [refundInProgress, setRefundInProgress] = useState(false);

	let displayValue;

	switch (props.item.PaymentType) {
		case PaymentTypes.Cash:
			displayValue = props.item.PaymentProcessorData.TenderedAmount;
			break;
		default:
			displayValue = props.item.Value;
	}

	/**
	 * Show an on screen snackbar if something goes wrong.
	 * @param {string} message The message text to show to the end user.
	 */
	const handleError = function (message) {
		SnackbarService.snack(message, "error");
	};

	/**
	 * Removing the payment from the basket.
	 *
	 * @return {void}
	 */
	const handleRemove = async () => {

		if (props.item.PaymentType === PaymentTypes.CardHolderPresent) {

			setRefundInProgress(true);

			const stripeService = new StripeService(
				handleError,
				handleError,
				props.Registration.StripeConnect?.LocationId,
				props.Settings.StripeTerminalSerialNumber,
				props.ReducedFunctionality
			);

			/**
			 * Refund the PI on Stripe, then cancel the payment in the basket.
			 */
			await stripeService.refundPaymentIntent(props.item.PaymentProcessorData.paymentIntent?.id, props.item.Value)
				.then(async () => await BasketService.cancelPayment(props.item))
				.catch(e => (handleError(e)));

			setRefundInProgress(false);

		}
		else {

			// ... we don't yet support refunds for non-Stripe payments

		}


	};

	return (
		<Flex gap={0.5}>
			<Flex gap={0.75} columnar={true}>
				<Flex
					columnar={true}
					justifyContent="space-between"
					width="100%">
					<Flex gap={0.25}>
						<Flex gap={0.5} columnar={true} noFlex={true}>
							<String noFlex={true} str={CheckoutBasketPayment.getUiLabel(props.item)} />
							{props.item.Moto && <Chip
								color="success"
								label="MOTO"
								size="small" />}
						</Flex>
						<PaymentPanelItemDetails item={props.item} />
					</Flex>
					<String
						classNameTypography={scss.price}
						noFlex={true}
						str={PriceUtils.getDisplayStringIntl(displayValue)} />
				</Flex>
			</Flex>
			<Flex
				alignItems="center"
				columnar={true}>
				{props.item.PaymentImmediateCharge && <Button
					disabled={(props.BasketLoading || props.disabled || refundInProgress)}
					label="Refund"
					onClick={handleRemove}
					startIcon={DeleteIcon}
					variant="outlined" />}
			</Flex>
			<Divider />
		</Flex>
	);

};

export default withReducedFunctionality(withRegistration(withSettings(PaymentPanelItem)));
