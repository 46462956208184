import {String} from "@hps/hops-react";
import {Grid} from "@mui/material";
import React from "react";

import UserCard from "./LoginUserSelectorItem.js";

import scss from "./LoginUserSelectorGrid.module.scss";

/**
 * Grid of user cards in the login user selector
 * 
 * @package HOPS
 * @subpackage Login
 * @copyright Heritage Operations Processing Limited
 */
const LoginUserSelectorGrid = props => {

	return (
		<>
			<String
				centre={true}
				str="Select your name to login" />

			<div className={scss.container}>
				<Grid container >
					{
						props.users?.map((user, key) => (
							<UserCard
								key={key}
								onUserSelect={props.onUserSelect}
								user={user} />
						))
					}
				</Grid>
			</div>
		</>
	);

};

export default LoginUserSelectorGrid;
