import {Dialog, Navigator} from "@hps/hops-react";
import React from "react";
import {connect} from "react-redux";

import dLogout from "Dispatchers/dLogout.js";
import dLogoutDialog from "Dispatchers/dLogoutDialog.js";
import withBasket from "Hoc/withBasket.js";

/**
 * Logout dialog
 * 
 * @package HOPS
 * @subpackage Login
 * @copyright Heritage Operations Processing Limited
 */
const LogoutDialog = props => {

	const handleClose = () => dLogoutDialog(false);

	const handleSubmit = () => {
		dLogout();
		Navigator.navigate("/");
	};

	return (
		<Dialog
			onClose={handleClose}
			onOk={handleSubmit}
			okLabel="Logout"
			open={props.Ui.LogoutDialog}
			str="Are you sure you want to logout?"
			title="Logout"
			width="xs" />
	);

};

export default connect(({Ui}) => ({Ui}))(withBasket(LogoutDialog));
