import {Banner, Button, Divider, EmptyStateGate, Flex, Hidden, Loader, Paper, SnackbarService, String, withMobile} from "@hps/hops-react";
import {PriceUtils} from "@hps/hops-sdk-js";
import {useState} from "react";

import Permissions from "App/Permissions.js";
import ConfirmationDialog from "Components/ConfirmationDialog.js";
import dPaymentType from "Dispatchers/dPaymentType.js";
import withAuthUser from "Hoc/withAuthUser.js";
import withBasket from "Hoc/withBasket.js";
import PaymentHandler from "Payment/PaymentHandler.js";
import PaymentMethodButtons from "Payment/PaymentMethodButtons";
import PaymentPane from "Payment/PaymentPane.js";
import BasketService from "Services/BasketService.js";

import CheckoutErrorDetails from "./CheckoutErrorDetails.js";
import CheckoutViewEmptyState from "./CheckoutViewEmptyState.js";

import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";

import scss from "./CheckoutViewCheckoutStage.module.scss";


/**
 * Checkout view in the checkout stage
 *
 * We're waiting for payment to be completed.
 * 
 * @package HOPS
 * @subpackage Checkout
 * @author Carl Booth
 * @copyright Heritage Operations Processing Limited
 */
const CheckoutViewCheckoutStage = props => {

	const hasActiveLicense = !!props.license?.Active;

	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

	const handleConfirmationDialogClose = () => {
		setConfirmationDialogOpen(false);
	};

	const handleConfirmationDialogSuccess = () => {
		handleConfirmationDialogClose();
		props.onCheckout();
	};

	/**
	 * Handle a checkout where the payment is completed.
	 * 
	 */
	const renderPaymentCompleteCheckout = () => {
		return (
			<Paper>
				<Flex>
					<Flex gap={1.5}>
						<Flex alignItems="center" gap={1}>
							<CheckCircleIcon
								color="primary"
								fontSize="large" />

							<String
								bold={true}
								str={props.PaymentsZeroValueBasket ? "No Payment Required" : "Payment Complete"}
								variant="h4" />

							<String
								bold={true}
								color={props.PaymentsChangeIsDue ? "error" : undefined}
								str={`Customer Change: ${PriceUtils.getDisplayStringIntl(props.PaymentsChange)}`}
								variant="h5" />
						</Flex>

						<Divider/>

						<Button
							className={scss.confirmSaleButton}
							disabled={!(!props.BasketLoading && !props.PaymentsInProgress && props.PaymentsIsSettled)}
							label="Confirm Sale"
							size="large"
							startIcon={CheckIcon}
							onClick={props.onCheckout}
							variant="contained" />
					</Flex>
				</Flex>
			</Paper>
		);
	};


	/**
	 * Handle a checkout where payment must be collected
	 * 
	 */
	const renderPaymentCheckout = () => {

		const canDepositOrder = props.hasPermission(Permissions.P527_CREATE_DEPOSIT_ORDER_IN_POS) && !!(props.PaymentsBalanceDue) && props.hasPayments;
		const canProvisionalOrder = props.hasPermission(Permissions.P526_CREATE_PROVISIONAL_ORDER_IN_POS) && !!(props.PaymentsBalanceDue) && props.hasNoPayments;

		return (
			<>
				{/* No payment type set, but a balance to pay - show the options */}
				{props.PaymentType === null &&
					<Paper>
						<Flex>
							<String
								bold={true}
								centre={true}
								noFlex={true}
								str="Checkout"
								variant="h6" />

							<Flex gap={3}>

								<PaymentMethodButtons
									balance={props.PaymentsBalanceDue}
									columnar={true}
									disabled={false}
									onChangePaymentType={handleChangePaymentType}
									showAppPaymentMethod={!!(props.BasketIdentity.OfflineBasketUuid)}
									showPaymentMethods={(!props.PaymentsIsSettled && props.BasketTotal > 0)}
									showRefundMethods={(!props.PaymentsIsSettled && props.BasketTotal < 0)} />

								<Flex gap={0.5}>
									<String
										bold={true}
										noFlex={true}
										str="Partial Orders"
										variant="h6" />
									<Flex
										columnar={true}
										className={scss.columns}>
										<Button
											disabled={!canDepositOrder}
											label="Deposit"
											onClick={setConfirmationDialogOpen}
											size="large"
											value={true} />
										<String
											color="textSecondary"
											gap={0}
											str={["Reserve the space where the customer has committed to using it.", "Partial payment is taken."]}
											variant="caption" />
									</Flex>
									<Flex
										columnar={true}
										className={scss.columns}>
										<Button
											disabled={!canProvisionalOrder}
											label="Provisional"
											onClick={setConfirmationDialogOpen}
											size="large"
											value={true} />
										<String
											color="textSecondary"
											gap={0}
											str={["Reserve the space for the customer to potentially use but they haven't yet confirmed.", "No payment is taken."]}
											variant="caption" />
									</Flex>

									<ConfirmationDialog
										onClose={handleConfirmationDialogClose}
										onSuccess={handleConfirmationDialogSuccess}
										open={confirmationDialogOpen} />

								</Flex>
							</Flex>
						</Flex>
					</Paper>}

				<PaymentHandler
					onPaymentFailure={handlePaymentFailure}
					onPaymentSuccess={BasketService.addPayments}
					PaymentType={props.PaymentType}
					RequestedAmount={props.PaymentsBalanceDue} />
			</>
		);
	};


	/**
	 * Handle switching the current active PaymentType
	 */
	const handleChangePaymentType = PaymentType => {
		dPaymentType(PaymentType);
	};


	/**
	 * Handle a payment provider returning an error
	 */
	const handlePaymentFailure = () => {
		SnackbarService.snack("Payment Cancelled", "info");
		dPaymentType(null);
	};


	/**
	 * Render main checkout stage
	 */
	return (
		<EmptyStateGate
			emptyState={CheckoutViewEmptyState}
			isEmpty={!props.BasketItems?.length}>

			<Flex
				className={props.isMobile ? scss.containerReduced : scss.root}
				columnar={!props.isMobile}
				width="100%">

				<Flex alignItems="center" className={scss.paymentSection}>
					<EmptyStateGate isEmpty={!hasActiveLicense} emptyState={<Banner type="error">Checkout is disabled because your organisation does not have an active HOPS Point of Sale license.</Banner>}>

						<Hidden hidden={!props.error}>
							{(!props.BasketLoading &&
							<Flex alignItems="center" width="100%">
								<CheckoutErrorDetails error={props.error} />

								<Button
									label="Void Order"
									onClick={BasketService.clearItems}
									startIcon={ClearIcon}
									variant="outlined" />
							</Flex>)}
						</Hidden>

						<Hidden hidden={props.error}>
							{(!props.error && props.PaymentsIsSettled) ?
								renderPaymentCompleteCheckout() :
								renderPaymentCheckout()
							}
						</Hidden>

						{(props.BasketLoading && !props.PaymentsInProgress && <Loader size={40} />)}

					</EmptyStateGate>
				</Flex>

				<PaymentPane />

			</Flex>

		</EmptyStateGate>
	);
};

export default withAuthUser(withBasket(withMobile(CheckoutViewCheckoutStage)));
