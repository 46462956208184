import {SeatReservationCheckoutUi} from "@hps/hops-react";
import {SeatReservationUtils} from "@hps/hops-sdk-js";
import {memo, useCallback} from "react";

/**
 * Seat reservation editor
 *
 * Allows editing the seats selected for an item in an order.
 *
 * @package HOPS
 * @subpackage SeatReservation
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const SeatReservationEditor = memo(({
	knownReservations,
	reservationMode,
	selection,
	onChangeSelection,
	onFetchedSeatingData,
	targetOrderId,
	targetJourneys,
	targetSeatCount
}) => {

	/**
	 * Adding a seat to the selection.
	 *
	 * @param {Object} Train
	 * @param {Object} TrainAsset
	 * @param {Integer|Null} TrainScheduleIdDeparture
	 * @param {Integer|Null} TrainScheduleIdArrival
	 * @param {Object} Space
	 * @return {void}
	 */
	const handleAddSeat = useCallback((Train, TrainAsset, TrainScheduleIdDeparture, TrainScheduleIdArrival, Space) => {

		const journeyId = SeatReservationUtils.getJourneyId(
			TrainAsset.Id,
			TrainScheduleIdDeparture,
			TrainScheduleIdArrival
		);

		const updatedSelection = {
			...selection,
			[journeyId]: [...(selection[journeyId] || [])]
		};

		updatedSelection[journeyId].push(Space.Id);

		onChangeSelection(updatedSelection);

	}, [onChangeSelection, selection]);


	/**
	 * Removing a selected seat.
	 *
	 * @param {Integer} TrainAssetId
	 * @param {Integer|Null} TrainScheduleIdDeparture
	 * @param {Integer|Null} TrainScheduleIdArrival
	 * @param {Integer|Null} SpaceId
	 * @return {void}
	 */
	const handleRemoveSeat = useCallback((TrainAssetId, TrainScheduleIdDeparture, TrainScheduleIdArrival, SpaceId) => {

		const journeyId = SeatReservationUtils.getJourneyId(
			TrainAssetId,
			TrainScheduleIdDeparture,
			TrainScheduleIdArrival
		);

		const updatedSelection = {
			...selection,
			[journeyId]: selection[journeyId]?.filter(Id => (Id !== SpaceId))
		};

		if (updatedSelection[journeyId]?.length === 0) {
			delete updatedSelection[journeyId];
		}

		onChangeSelection(updatedSelection);

	}, [onChangeSelection, selection]);


	/**
	 * Render!
	 */
	return (
		<SeatReservationCheckoutUi.Component
			containerLevel={1}
			knownReservations={knownReservations}
			onAddSelection={handleAddSeat}
			onRemoveSelection={handleRemoveSeat}
			onFetchedSeatingData={onFetchedSeatingData}
			reservationMode={reservationMode}
			selection={selection}
			targetJourneys={targetJourneys}
			targetOrderId={targetOrderId}
			targetSeatCount={targetSeatCount} />
	);

});

export default SeatReservationEditor;
