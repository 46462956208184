import {CheckoutBasketItem, OrderableTypes} from "@hps/hops-sdk-js";

/**
 * Get the number of stationery prints required for a unit of a basket item.
 *
 * @param {Object} CheckoutBasketItem-like object
 * @return {Integer}
 */
const getPrintCountForBasketItem = basketItem => {


	/** No stationery */
	if (!basketItem.StationeryTemplateId) {
		return 0;
	}

	/** Default to the number of people, ensuring we print at least one ticket (e.g. dog tickets can be "0 people") */
	let printCount = Math.max(CheckoutBasketItem.getTargetPeopleCount(basketItem), 1);

	/** In this instance, we only want to print one ticket for everyone */
	if (basketItem.OrderableType === OrderableTypes.TicketSession) {
		if (!basketItem.Item?.Session?.Product?.IndividualStationeryPrints) {
			printCount = 1;
		}
	}

	return printCount;

};

export {getPrintCountForBasketItem};
