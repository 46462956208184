import {Dialog} from "@hps/hops-react";
import {memo} from "react";

import SeatReservationHandler from "./SeatReservationHandler.js";

import scss from "./SeatReservationDialog.module.scss";

const SeatReservationDialog = memo(props => {

	return (
		<Dialog
			classNameActions={scss.dialogActions}
			classNameTitle={scss.dialogTitle}
			fullScreen
			onClose={props.onClose}
			onOk={props.onSubmit}
			open={props.open}
			title="Seat Reservation">
			<SeatReservationHandler
				items={props.items}
				targetBasketId={props.targetBasketId}
				targetBasketItem={props.targetBasketItem} />
		</Dialog>
	);

});

export default SeatReservationDialog;
