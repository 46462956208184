import {OrderableTypes} from "@hps/hops-sdk-js";

/*
 * This should probably go in hops-sdk-js, but only POS
 * can print tickets at the moment so it can live here for now
 */

const allowsRefund = mode => {
	return [
		OrderableTypes.Membership,
		OrderableTypes.Addon,
		OrderableTypes.TicketTravel,
		OrderableTypes.TicketSession,
		OrderableTypes.VoucherSale,
		OrderableTypes.Shares
	].includes(mode);
};

const isPrintable = mode => {
	return [
		OrderableTypes.TicketTravel,
		OrderableTypes.TicketSession
	].includes(mode);
};

export {
	allowsRefund,
	isPrintable
};
