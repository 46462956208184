import {Banner, Checkbox, Dialog, SnackbarService, String, withDevice} from "@hps/hops-react";
import {Api, OrderVoucherService, PaymentProcessorsEnum as PaymentProcessors, PaymentTypes, PriceUtils} from "@hps/hops-sdk-js";
import moment from "moment";
import {memo, useCallback, useState} from "react";

import OrderOverRefundWarningAlert from "./OrderOverRefundWarningAlert.js";


const OrderPaymentRefundDialog = memo(({
	amountOwing,
	apiBaseUrl,
	open,
	onClose,
	onRefunded,
	orderVoucher,
	payment,
	refundAmount,
	targetOrderId
}) => {

	const [confirmCheckbox, setConfirmCheckbox] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleToggleConfirmCheckbox = useCallback(() => {
		setConfirmCheckbox(!confirmCheckbox);
	}, [confirmCheckbox]);

	const confirmCheckboxRequired = (payment?.Processor?.Id === PaymentProcessors.HOPS);

	const handleSubmit = useCallback(async () => {

		if (!confirmCheckbox && confirmCheckboxRequired) {
			SnackbarService.snack("You must acknowledge the warning before continuing.", "error");
			return;
		}

		setIsSubmitting(true);

		try {


			const result = await Api.call({
				url: `${apiBaseUrl}/payments/refund/${payment.Uuid}`,
				method: "POST",
				data: {
					Amount: refundAmount,
					Order: targetOrderId
				}
			}).then(({data}) => data);

			onClose();
			onRefunded(result);

			SnackbarService.snack("Refund completed successfully.", "success");

		}
		catch (e) {

			let msg = null;
			const errorCode = e.response?.data?.Error;

			if (errorCode === "InvalidAmount") {
				msg = "The refund amount you've entered is invalid — it must be greater than £0.00";
			}
			else if (errorCode === "InvalidPayment") {
				msg = "The refund couldn't be processed because the payment is in an invalid state.\nOnly Complete payments can be refunded.";
			}
			else if (errorCode === "NotRefundable") {
				msg = "This payment cannot be refunded (it might itself be a refund of another payment).";
			}
			else if (errorCode === "OverRefunding") {
				msg = "Refund rejected because you are refunding more than the maximum refund possible for this payment.";
			}
			else if (errorCode === "Internal") {
				msg = "An internal error occurred while processing your refund request.";
			}

			SnackbarService.snack((msg || e), "error");

		}

		setIsSubmitting(false);

	}, [
		apiBaseUrl,
		confirmCheckbox,
		confirmCheckboxRequired,
		onClose,
		onRefunded,
		payment,
		refundAmount,
		targetOrderId
	]);

	const renderConfirmCheckbox = useCallback(() => {
		return (
			<Checkbox
				checked={confirmCheckbox}
				disabled={isSubmitting}
				label="I understand I must return the funds to the customer separately."
				onChange={handleToggleConfirmCheckbox} />
		);
	}, [isSubmitting, handleToggleConfirmCheckbox, confirmCheckbox]);

	return (
		<Dialog
			disabled={isSubmitting}
			loading={isSubmitting}
			open={open}
			okDisabled={(!confirmCheckbox && confirmCheckboxRequired)}
			onClose={onClose}
			onOk={handleSubmit}
			title="Refund Payment">
			<String
				p={true}
				str={`Are you sure you want to issue this ${PriceUtils.getDisplayString(refundAmount)} refund?`} />
			<OrderOverRefundWarningAlert
				amountOwing={amountOwing}
				refundAmount={refundAmount} />
			{
				((payment?.Type.Id === PaymentTypes.Card) || (payment?.Type.Id === PaymentTypes.CardHolderPresent)) &&
					(
						(payment?.Processor.Id === PaymentProcessors.HOPS) ?
							<Banner
								title="This refund will not return the funds to the customer's card"
								severity="error">
								<String
									str="This payment was processed outside of HOPS. Submitting this refund will create a new refund payment record in HOPS, but will not return the funds to the customer's card. You must use your card system's refund function to issue a refund to the customer." />
								{renderConfirmCheckbox()}
							</Banner> :
							<Banner
								title="You are refunding a card payment">
									The funds will be returned to the customer's card. It could take several days for the refund to be visible on the customer's statement.
							</Banner>
					)
			}
			{
				(payment?.Type.Id === PaymentTypes.Cash) &&
					<Banner
						title="You are refunding a cash payment"
						severity="error">
						<String
							str="Please ensure you've returned the cash to the customer." />
						{renderConfirmCheckbox()}
					</Banner>
			}
			{
				(payment?.Type.Id === PaymentTypes.Voucher) &&
					<Banner title="You are refunding a voucher payment">
						{`The funds will be returned to the voucher's balance.${(orderVoucher ? `\nVoucher: ${OrderVoucherService.getVoucherCodeDisplayString(orderVoucher.Code)}\nExpiry Date: ${(new moment(orderVoucher.ExpiryDate)).format("DD/MM/YYYY")}` : "")}`}
					</Banner>
			}
			{
				![PaymentTypes.Cash, PaymentTypes.Card, PaymentTypes.CardHolderPresent, PaymentTypes.Voucher].includes(payment?.Type.Id) &&
					<Banner title={`You are refunding a ${payment?.Type.Label} payment`} severity="error">
						<String
							str="Submitting this refund will create a new refund payment record in HOPS, but will not automatically return the funds to the customer." />
						{renderConfirmCheckbox()}
					</Banner>
			}
		</Dialog>
	);

});

export default withDevice(OrderPaymentRefundDialog);
