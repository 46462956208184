import {Banner, Button, Flex, Hidden, SnackbarService, String} from "@hps/hops-react";
import {CheckoutBasketItem, OrderableTypes, PaymentTypes, PriceUtils} from "@hps/hops-sdk-js";
import PropTypes from "prop-types";
import React, {useState} from "react";

import Permissions from "App/Permissions.js";
import ConfirmationDialog from "Components/ConfirmationDialog";
import withAuthUser from "Hoc/withAuthUser";
import withInventory from "Hoc/withInventory";
import withReducedFunctionality from "Hoc/withReducedFunctionality";
import BasketService from "Services/BasketService.js";

import HOPSAppIcon from "@mui/icons-material/AppShortcut";
import GiftCardIcon from "@mui/icons-material/CardGiftcard";
import CardIcon from "@mui/icons-material/CreditCard";
import CardNotPresentIcon from "@mui/icons-material/CreditCardOff";
import SubTotalIcon from "@mui/icons-material/Functions";
import CashIcon from "@mui/icons-material/Money";

import scss from "./PaymentMethodButtons.module.scss";

/**
 * Renders the payment method buttons that point to Payment Handlers
 */
const PaymentMethodButtons = props => {

	const [refundToVoucherDialogOpen, setVoucherDialogOpen] = useState(false);
	const [disabled, setDisabled] = useState(false);

	// Attempt to find a suitable voucher product to use
	const voucher = props.Inventory?.Vouchers?.find(v => v.BalanceToIssue === null && v.ExpiryMonths === 12);

	const handleRefundToVoucherDialogSuccess = async () => {

		setDisabled(true);

		try {
			await BasketService.addItems([
				CheckoutBasketItem.construct({
					OrderableType: OrderableTypes.VoucherSale,
					Item: voucher,
					Price: props.BasketTotal * -1,
					Quantity: 1,
					VatProportion: 0,
					VatRate: 0,
					StationeryTemplateId: voucher.Stationery
				})
			]);
		}
		catch (e) {
			SnackbarService.snack(e);
		}

		setDisabled(false);

	};

	const handleRefundToVoucherDialogClose = () => {
		setVoucherDialogOpen(false);
	};


	const renderPaymentMethods = () => {

		return (
			<Flex gap={0.5}>

				{!(props.compactUi) && <String
					bold={true}
					noFlex={true}
					str="Payment Method"
					variant="h6" />}

				<Hidden hidden={!props.balance}>
					<Banner severity="warning" title={`There is a balance of ${PriceUtils.getDisplayStringIntl(props.balance)}.`} />
				</Hidden>

				<Flex
					justifyContent="space-between"
					columnar={props.columnar}
					gap={0.5}>

					<Hidden hidden={props.AlwaysOffline}>
						<Button
							className={scss.additionalPaymentMethodButton}
							disabled={props.disabled || props.ReducedFunctionality}
							label="Voucher"
							onClick={props.onChangePaymentType}
							size="large"
							startIcon={GiftCardIcon}
							value={PaymentTypes.Voucher} />
					</Hidden>

					<Hidden hidden={(props.compactUi || !props.hasPermission(Permissions.P528_COLLECT_MOTO_PAYMENT))}>
						<Button
							className={scss.additionalPaymentMethodButton}
							disabled={props.disabled}
							label="MOTO (Card-Not-Present)"
							onClick={props.onChangePaymentType}
							size="large"
							startIcon={CardNotPresentIcon}
							value={PaymentTypes.Card} />
					</Hidden>

					<Hidden hidden={!props.showAppPaymentMethod}>
						<Button
							className={scss.additionalPaymentMethodButton}
							disabled={props.disabled}
							label={"App"}
							onClick={props.onChangePaymentType}
							size="large"
							startIcon={HOPSAppIcon}
							value={PaymentTypes.Placeholder} />
					</Hidden>

					<Hidden hidden={!props.compactUi}>
						<Button
							className={scss.additionalCheckoutBtn}
							label="..."
							onClick={props.onChangePaymentType}
							value={null} />
					</Hidden>
				</Flex>

				<Flex
					className={props.columnar && scss.containerCheckoutButtons}
					justifyContent="space-between"
					columnar={props.columnar}
					gap={0.5}>
					<Button
						disabled={props.disabled}
						label="Card"
						onClick={props.onChangePaymentType}
						size="large"
						startIcon={CardIcon}
						value={PaymentTypes.CardHolderPresent}
						variant="contained" />
					<Button
						disabled={props.disabled}
						label="Cash"
						onClick={props.onChangePaymentType}
						size="large"
						startIcon={CashIcon}
						value={PaymentTypes.Cash}
						variant="contained" />
					{props.AlwaysOffline && <Hidden hidden={!props.compactUi}>
						<Button
							className={scss.compactUi}
							label="..."
							onClick={props.onChangePaymentType}
							value={null} />
					</Hidden>}
				</Flex>

				{!(props.compactUi) && <String
					color="textSecondary"
					str="Offline Stripe Terminal payments must be made through the HOPS Payment App."
					variant="caption" />}
			</Flex>
		);

	};


	const renderRefundMethods = () => {

		return (
			<>
				<Flex gap={0.5}>
					<Flex>
						<String
							bold={true}
							str="Customer Refund"
							variant="h6" />
					</Flex>
					<Flex
						justifyContent="space-between"
						columnar={props.columnar}
						gap={0.5}>
						<Button
							className={scss.voucherButton}
							disabled={props.disabled || props.ReducedFunctionality}
							label="As Voucher"
							onClick={setVoucherDialogOpen}
							size="large"
							startIcon={GiftCardIcon}
							value={true} />
						<Hidden hidden={!props.compactUi}>
							<Button
								className={scss.additionalCheckoutBtn}
								label="..."
								onClick={props.onChangePaymentType}
								value={null} />
						</Hidden>
					</Flex>
					<Flex
						className={props.columnar && scss.containerCheckoutButtons}
						justifyContent="space-between"
						columnar={props.columnar}
						gap={0.5}>
						<Button
							disabled={props.disabled}
							label="To Card"
							onClick={props.onChangePaymentType}
							size="large"
							startIcon={CardIcon}
							value={PaymentTypes.CardHolderPresent}
							variant="contained" />
						<Button
							disabled={props.disabled}
							label="As Cash"
							onClick={props.onChangePaymentType}
							size="large"
							startIcon={CashIcon}
							value={PaymentTypes.Cash}
							variant="contained" />
					</Flex>
				</Flex>
				<ConfirmationDialog
					disabled={disabled}
					noCloseButton={!!(voucher)}
					noOkButton={!!(voucher)}
					onClose={handleRefundToVoucherDialogClose}
					onSuccess={handleRefundToVoucherDialogSuccess}
					open={refundToVoucherDialogOpen}
					message={[`Add a ${PriceUtils.getDisplayStringIntl(props.BasketTotal * -1)} Voucher to the basket to settle the refund.`]}
					title="Refund to Voucher" />
			</>
		);

	};


	if (props.showPaymentMethods) {

		// We have a payment to collect
		return renderPaymentMethods();
	}
	else if (props.showRefundMethods) {

		// We have a refund to issue
		return renderRefundMethods();
	}
	else {

		// Order Balance is settled
		return (
			<Button
				className={scss.confirmSaleButton}
				disabled={props.disabled}
				label="Sub Total"
				onClick={props.onChangePaymentType}
				size="large"
				startIcon={SubTotalIcon}
				variant="contained"
				value={null} />
		);
	}
};

PaymentMethodButtons.propTypes = {
	columnar: PropTypes.bool,
	compactUi: PropTypes.bool,
	disabled: PropTypes.bool,
	onChangePaymentType: PropTypes.func,
	showAppPaymentMethod: PropTypes.bool,
	showPaymentMethods: PropTypes.bool,
	showRefundMethods: PropTypes.bool
};

PaymentMethodButtons.defaultProps = {
	columnar: true,
	compactUi: false,
	disabled: false,
	showAppPaymentMethod: false,
	showPaymentMethods: false,
	showRefundMethods: false
};

export default withAuthUser(withInventory(withReducedFunctionality(PaymentMethodButtons)));
