import withBasket from "Hoc/withBasket";
import PaymentProcessorOrgVoucher from "Payment/Processors/OrgVoucher/PaymentProcessorOrgVoucher";

const PaymentHandlerVoucher = props => {

	/*
	 * All PaymentTypes.Voucher payments come through this gatehouse.
	 * 
	 * There should only be one payment handler for each PaymentType.
	 * Use this component to determine which collector to use.
	 */

	return (
		<PaymentProcessorOrgVoucher {...props} />
	);
};

export default withBasket(PaymentHandlerVoucher);
