import {withRegistration} from "@hps/hops-react";
import React from "react";
import {Route, Switch} from "react-router-dom";

import withAuthUser from "Hoc/withAuthUser.js";
import MainDenied from "Ui/MainDenied.js";

import Routes from "./Routes.js";

/**
 * Router
 *
 * The application-level router that renders the main routes.
 *
 * @package HOPS
 * @subpackage App
 * @copyright Heritage Operations Processing Limited
 */
const Router = props => {

	const routes = () => {
		return Routes.filter(route => {
			if (!route.allowWithoutAuth && !props.authed) return false;
			else if (!route.allowWithoutDeviceRegistration && !props.registered) return false;
			else return true;
		});
	};

	return (
		<Switch>
			{
				routes().map((route, key) => {
					if ((route.permission && !props.hasPermission(...route.permission))) {
						return <Route
							exact={!route.inexact}
							key={key}
							path={route.uri}
							component={MainDenied} />;
					}
					else {
						return <Route
							component={route.component}
							exact={!route.inexact}
							key={key}
							path={route.uri}
							render={route.render} />;
					}
				})}

		</Switch>
	);

};

export default withAuthUser(withRegistration(Router));
