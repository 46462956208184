import {EmptyStateGate, Flex, TicketBuilder, withMobile} from "@hps/hops-react";
import React, {useState} from "react";

import dTicketsSelectionMerge from "Dispatchers/dTicketsSelectionMerge.js";
import withInventory from "Hoc/withInventory.js";
import withTickets from "Hoc/withTickets.js";
import scss from "Inventory/InventoryBrowserWithToolbar.module.scss";

import ProductBrowser from "./TicketSessionInventoryProductBrowser.js";

/**
 * Ticket session inventory browser
 *
 * Renders available ticket products and sessions.
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const TicketSessionInventoryBrowser = props => {

	const defaultTicketTypeCounts = () => {

		const typeCounts = {};

		for (const type of (props.Inventory?.Tickets?.Types || [])) {
			const defaultSearchCount = (type.DefaultSearchCount || 0);
			if (defaultSearchCount) typeCounts[type.Id] = defaultSearchCount;
		}

		return typeCounts;
	};

	const [activeProduct, setActiveProduct] = useState(null);
	const [selectionTypeCounts, setSelectionTypeCounts] = useState(defaultTicketTypeCounts());

	const handleSelectProduct = product => {

		// Set the active product
		setActiveProduct(product || null);

		// Reset the Ticket Builder selection
		setSelectionTypeCounts(defaultTicketTypeCounts());

	};

	const products = props.Inventory?.Tickets?.Products || [];
	const sessions = (props.Inventory?.Tickets?.Sessions || []).filter(s => (s.Product === activeProduct?.Id));
	const sessionsTicketTypesIds = sessions.map(s => s.Fares.map(f => f.TicketType)).flat();

	return (
		<EmptyStateGate
			isEmpty={!products?.length}>
			<Flex
				className={props.isMobile ? scss.containerReduced : scss.root}
				columnar={!props.isMobile}
				width="100%">
				<TicketBuilder
					elevated={false}
					fareTypes={props.Inventory?.Tickets?.FareTypes}
					hideJourneyTypes={true}
					hideTimingPoints={true}
					hideTravelDate={true}
					noTicketTypesMessage={(!activeProduct ? "Select a product to view available ticket types." : "No ticket types available for this product.")}
					onChange={dTicketsSelectionMerge}
					onChangeTypeCount={setSelectionTypeCounts}
					selection={props.TicketsSelection}
					selectionTypeCounts={selectionTypeCounts}
					timingPoints={[]}
					types={(props.Inventory?.Tickets?.Types || []).filter(tt => sessionsTicketTypesIds.includes(tt.Id))} />
				<ProductBrowser
					activeProduct={activeProduct}
					onSelectProduct={handleSelectProduct}
					products={products}
					selectedTicketTypeCounts={selectionTypeCounts} />
			</Flex>
		</EmptyStateGate>
	);

};

export default withInventory(withMobile(withTickets(TicketSessionInventoryBrowser)));
