import {Divider, EmptyStateGate, Flex, String} from "@hps/hops-react";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import React, {useState} from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


/**
 * Raildays topic browser card
 * 
 * @package HOPS
 * @subpackage CustomerServices
 * @copyright Heritage Operations Processing Limited
 */
const FaqTopicBrowserCard = props => {

	const [expanded, setExpanded] = useState(false);

	const handleChangeQuestionPanel = (expanded, panel) => {
		setExpanded(expanded ? panel : false);
	};

	const renderAnswerInternalRemarks = remarks => {
		return (
			<>
				<String bold={true} color="textSecondary" str="Internal Remarks" />
				<String color="textSecondary" str={remarks} />
			</>
		);
	};


	return (
		<Flex>
			<String str={props.topic?.Name} variant="h5" my={3} />
			<EmptyStateGate isEmpty={!props.topic?.Questions?.length}>
				{
					props.topic?.Questions?.map((faq, key) => (

						<EmptyStateGate isEmpty={!faq.AnswerText && !faq.AnswerInternalRemarks} emptyState={<></>}>
							<Accordion expanded={expanded === key} key={key} onChange={(e, expanded) => handleChangeQuestionPanel(expanded, key)}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<String bold={true} str={faq.QuestionText} />
								</AccordionSummary>
								<AccordionDetails>
									<Flex>
										<String str={faq.AnswerText} />
										{(faq.AnswerText && faq.AnswerInternalRemarks) && <Divider />}
										{faq.AnswerInternalRemarks && renderAnswerInternalRemarks(faq.AnswerInternalRemarks)}
									</Flex>
								</AccordionDetails>
							</Accordion>
						</EmptyStateGate>

					))
				}
			</EmptyStateGate>
		</Flex>
	);

};

export default FaqTopicBrowserCard;
