import React from "react";

import TicketSearchFavouritesBrowser from "./TicketSearchFavouritesBrowser.js";

/**
 * Inventory "favourites" view
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const FavouritesInventoryBrowser = () => {

	return (
		<TicketSearchFavouritesBrowser />
	);

};

export default FavouritesInventoryBrowser;
