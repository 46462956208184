import {Store} from "@hps/hops-react";
import axios from "axios";

/**
 * Gateway service
 *
 * Allows interactions with the HOPSP Gateway integration.
 *
 * @package HOPS
 * @subpackage Gateway
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processingg Limited
 */
class GatewayService {

	/**
	 * Check the connectivity to the configured Gateway server.
	 *
	 * Returns the server application's version string when successful.
	 *
	 * Gateway should only be used when this check returns a positive result.
	 * 
	 * @return {String|null}
	 */
	static async checkConnectivity() {

		const identity = await this.getGatewayIdentity();

		if (identity?.app === "hopsp-gateway") {
			return identity.version;
		}
		else throw new Error("Server signature invalid.");

	}


	/**
	 * Get the available printers.
	 * 
	 * @return {Promise}
	 */
	static getPrinters() {
		return this.request({
			url: `/printers`
		}).then(({data}) => data);
	}


	/**
	 * Print a receipt.
	 *
	 * @param {String} html
	 * @return {Promise}
	 */
	static printReceipt(markdown, cpl) {
		return this.request({
			url: `/receipts/print`,
			method: "POST",
			data: {
				cpl,
				markdown,
				timeout: 300
			}
		}).then(({data}) => data);
	}


	/**
	 * Print order stationery.
	 *
	 * @param {Array<String>} HTML templates
	 * @return {Promise}
	 */
	static printStationery(prints) {
		return this.request({
			url: `/stationery/print`,
			method: "POST",
			data: {
				printer: Store.getState().Gateway.StationeryPrinterId,
				prints
			}
		}).then(({data}) => data);
	}


	/**
	 * Get the Gateway server's identity data.
	 *
	 * This serves as a connectivity check and gets the server app version.
	 *
	 * @return {Object} API response data
	 */
	static getGatewayIdentity() {
		return this.request({url: "/"}).then(({data}) => data);
	}


	/**
	 * Make an Axios request to our configured Gateway server.
	 *
	 * @param {Object} request Axios request
	 * @return {Promise} Axios request
	 */
	static request(request) {
		return this.getNewAxiosInstance().request(request);
	}


	/**
	 * Get a new Axios instance to connect to our configured Gateway server.
	 *
	 * @return {Axios}
	 */
	static getNewAxiosInstance() {
		return axios.create({
			baseURL: this.gatewayBaseUrl,
			headers: {
				Authorization: Store.getState().Gateway.SecurityToken
			},
			withCredentials: true
		});
	}


	/**
	 * Get the base URL of our configured Gateway server connection.
	 *
	 * @return {String}
	 */
	static get gatewayBaseUrl() {
		const {Gateway} = Store.getState();
		return `${Gateway.Host}:${Gateway.Port}`;
	}

}

export default GatewayService;
