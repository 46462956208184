import {EmptyStateGate, Flex} from "@hps/hops-react";
import {CheckoutBasketItem, OrderableTypes} from "@hps/hops-sdk-js";
import React, {useState} from "react";

import withBasket from "Hoc/withBasket.js";
import withInventory from "Hoc/withInventory.js";
import InventoryGrid from "Inventory/InventoryGrid.js";
import BasketService from "Services/BasketService.js";
import AppOfflineGate from "Ui/Connectivity/AppOfflineGate.js";

import InventoryGridItemVoucher from "./InventoryGridItemVoucher.js";
import VoucherLookup from "./VoucherLookup.js";

/**
 * Voucher inventory browser
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const VoucherInventoryBrowser = props => {

	const [addingToBasket, setAddingToBasket] = useState(null);

	/**
	 * Find existing instances of a voucher in the basket.
	 */
	const findVoucherExistingBasketItems = (voucher, targetPrice=undefined) => {
		return props.BasketItems.filter(i => {
			const ot = (i.OrderableType === OrderableTypes.VoucherSale);
			const id = (i.Item?.Id === voucher.Id);
			const price = ((targetPrice === undefined) || (i.Price === targetPrice));
			return (ot && id && price);
		});
	};


	/**
	 * Adding to the basket.
	 * 
	 * @return {void}
	 */
	const handleAddToBasket = async (voucher, price) => {

		setAddingToBasket(voucher);

		/**
		 * When the item's already in the basket, 
		 * update the quantity of that existing item
		 */
		const existingBasketItem = findVoucherExistingBasketItems(voucher, price)?.[0];

		/**
		 * We're adding a unit to an existing item's quantity
		 */
		if (existingBasketItem) {
			try {
				await BasketService.updateItemQty(
					existingBasketItem,
					(existingBasketItem.Quantity + 1)
				);
			}
			catch (e) {
				// ...
			}
		}

		/**
		 * We're purchasing our first instance of this voucher
		 */
		else {
			try {
				await props.onAddToBasket([
					CheckoutBasketItem.construct({
						OrderableType: OrderableTypes.VoucherSale,
						Item: voucher,
						Price: price,
						Quantity: 1,
						VatProportion: 0,
						VatRate: 0,
						StationeryTemplateId: voucher.Stationery
					})
				]);
			}
			catch (e) {
				// ...
			}
		}

		setAddingToBasket(null);

	};

	const items = props.Inventory?.Vouchers;

	return (
		<EmptyStateGate isEmpty={!items?.length}>
			<AppOfflineGate>
				<Flex overflowY="auto" width="100%">
					<VoucherLookup />
					<InventoryGrid>
						{
							items?.map((item, key) => {

								const itemsInBasket = findVoucherExistingBasketItems(item);
								const countInBasket = itemsInBasket.reduce((a, b) => (a + b.Quantity), 0);
								const maxQtyExceeded = ((item.MaxQuantity !== null) && (countInBasket >= item.MaxQuantity));

								return (
									<InventoryGridItemVoucher
										key={key}
										imageSrc={`/uploads/local/${props.Registration.Org.Id}/vouchers/${item.Id}/${item.Id}`}
										item={item}
										loading={(addingToBasket?.Id === item.Id)}
										onAddToBasket={handleAddToBasket}
										skuUnavailable={maxQtyExceeded}
										skuUnavailableMessage={`Maximum of ${item.MaxQuantity} Allowed`} />
								);

							})
						}
					</InventoryGrid>
				</Flex>
			</AppOfflineGate>
		</EmptyStateGate>
	);

};

export default withBasket(withInventory(VoucherInventoryBrowser));
