import {TextField} from "@hps/hops-react";
import {useCallback} from "react";

export default props => {

	const {onChange} = props;

	const handleChange = useCallback(code => {
		onChange((code?.toUpperCase() || null));
	}, [onChange]);

	return (
		<TextField
			label="Discount Code"
			pattern="[A-Z0-9]{1,}"
			{...props}
			onChange={handleChange} />
	);

};
