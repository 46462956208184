import {Flex, UnhandledErrorView, withRegistration} from "@hps/hops-react";
import * as Sentry from "@sentry/react";
import React from "react";

import BasketClaimErrorDialog from "Basket/BasketClaimErrorDialog.js";
import BasketExpirationDialog from "Basket/BasketExpirationDialog.js";
import CheckoutQuestionsDialog from "Checkout/Questions/CheckoutQuestionsDialog.js";
import withAuthUser from "Hoc/withAuthUser.js";
import LogoutDialog from "Login/LogoutDialog.js";
import BasketSeatReservationDialog from "SeatReservation/BasketSeatReservationDialog.js";
import UiBar from "Ui/UiBar.js";

import Router from "./Router.js";

/**
 * Main application component
 *
 * Handles application-level activities and renders the app UI and router.
 *
 * @package HOPS
 * @subpackage App
 * @copyright Heritage Operations Processing Limited
 */
const Main = props => {

	return <>
		{(props.authed && props.registered && <UiBar />)}
		<Flex px={1} py={1}>
			<Sentry.ErrorBoundary fallback={UnhandledErrorView}>
				<Router />
			</Sentry.ErrorBoundary>
		</Flex>
		<BasketClaimErrorDialog />
		<BasketExpirationDialog />
		<LogoutDialog />
		<CheckoutQuestionsDialog />
		<BasketSeatReservationDialog />
	</>;

};

export default withAuthUser(withRegistration(Main));
