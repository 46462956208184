import {Api} from "@hps/hops-sdk-js";

/**
 * Seat reservation service
 *
 * Utility methods to interact with seat reservations.
 *
 * @package HOPS
 * @subpackage SeatReservation
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class SeatReservationService {

	/**
	 * Update an order item's seat reservations.
	 *
	 * @param {Integer} OrderItemId
	 * @param {Array<Object{TrainAssetId: int, DepartureTrainScheduleId: ?int, ArrivalTrainScheduleId: ?int, Space: int}>} API Seat reservation objects
	 * @return {Promise}
	 */
	static updateOrderItemSeatReservations(OrderId, OrderItemId, Seats) {
		return Api.call({
			url: `/api/pos/orders/${OrderId}/items/${OrderItemId}/seats`,
			method: "PUT",
			data: {Seats}
		});
	}

}

export default SeatReservationService;
