import {EmptyStateGate, Flex} from "@hps/hops-react";
import React from "react";

import dInventoryTab from "Dispatchers/dInventoryTab.js";
import dTicketsSelection from "Dispatchers/dTicketsSelection.js";
import withInventory from "Hoc/withInventory.js";
import InventoryGrid from "Inventory/InventoryGrid.js";

import TicketSearchFavouritesBrowserCard from "./TicketSearchFavouritesBrowserCard.js";

/**
 * Render available "favourite" ticket travel searches
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const TicketSearchFavouritesBrowser = props => {

	/**
	 * Ticket search selected.
	 *
	 * @param {Object} search Ticket search object
	 * @return {void}
	 */
	const handleSelect = search => {

		/**
		 * Update the tickets selection data using the search object
		 */
		dTicketsSelection({
			Date: search.Date,
			Class: props.Inventory.Tickets.Classes.find(c => (c.Id === search.Class)),
			FareType: props.Inventory.Tickets.FareTypes.find(ft => (ft.Id === search.FareType)),
			JourneyType: search.JourneyType,
			TimingPointA: props.Inventory.Tickets.TimingPoints.find(tp => (tp.Id === search.TimingPointA)),
			TimingPointB: (search.TimingPointB ? props.Inventory.Tickets.TimingPoints.find(tp => (tp.Id === search.TimingPointB)) : null),
			TimingPointC: (search.TimingPointC ? props.Inventory.Tickets.TimingPoints.find(tp => (tp.Id === search.TimingPointC)) : null),
			TypeCounts: props.Inventory.Tickets.Types.reduce((current, type) => ({...current, [type.Id]: (search.PeopleCounts?.find(pc => (pc.Type === type.Id))?.Count || 0)}), {})
		});

		/**
		 * "Travel" is always the first tab
		 *
		 * If this ticket selection event has occurred, we 
		 * logically must have the tab available too.
		 */
		dInventoryTab(0);

	};


	return (
		<EmptyStateGate isEmpty={!props.Inventory?.Tickets?.Searches?.length}>
			<Flex overflowY="auto" width="100%">
				<InventoryGrid>
					{
						props.Inventory?.Tickets?.Searches?.map((search, key) => (
							<TicketSearchFavouritesBrowserCard
								key={key}
								onSelect={handleSelect}
								search={search} />
						))
					}
				</InventoryGrid>
			</Flex>
		</EmptyStateGate>
	);

};

export default withInventory(TicketSearchFavouritesBrowser);
