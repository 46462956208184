import {Button, Dialog, Flex, Hidden, String} from "@hps/hops-react";
import PropTypes from "prop-types";

const ConfirmationDialog = props => {

	const {
		disabled,
		message,
		noCloseButton,
		noOkButton,
		onClose,
		onSuccess,
		open,
		title
	} = props;

	/**
	 * Render!
	 */
	return (
		<Dialog
			onClose={onClose}
			noCloseButton={noCloseButton}
			noOkButton={noOkButton}
			open={open}
			title={title || "Are you sure?"}>

			{message && <String str={message} />}

			<Hidden hidden={!(noOkButton && noCloseButton)}>
				<Flex
					columnar={true}
					justifyContent="flex-end">

					<Button
						disabled={disabled}
						label="No"
						onClick={onClose}
						variant="outlined" />

					<Button
						disabled={disabled}
						label="Yes"
						onClick={onSuccess}
						variant="contained" />

				</Flex>
			</Hidden>
		</Dialog>
	);

};

ConfirmationDialog.propTypes = {
	disabled: PropTypes.bool,
	message: PropTypes.any,
	noCloseButton: PropTypes.bool,
	noOkButton: PropTypes.bool,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	open: PropTypes.bool,
	title: PropTypes.string
};

ConfirmationDialog.defaultProps = {
	noCloseButton: true,
	noOkButton: true
};

export default ConfirmationDialog;
