import {Flex, Paper, String} from "@hps/hops-react";

import CheckoutView from "Checkout/CheckoutView.js";
import LoginView from "Login/LoginView.js";
import RegistrationView from "Registration/RegistrationView.js";
import AdvancedSearchView from "Search/AdvancedSearch/AdvancedSearchView";
import OrderDetailsView from "Search/OrderDetails/OrderDetailsView";
import SettleOrderPaymentView from "Search/OrderDetails/Refunds/SettleOrderPaymentView";
import SearchView from "Search/SearchView";
import SettingsView from "Settings/SettingsView.js";
import UploadQueueView from "Settings/UploadQueue/UploadQueueView";
import AuthedInvalidUriView from "Ui/AuthedInvalidUriView";
import CustomerServicesView from "Views/CustomerServicesView";
import OrderView from "Views/OrderView.js";

import Permissions from "./Permissions";

const Routes = [
	{
		uri: "/checkout",
		component: CheckoutView
	},
	{
		uri: "/device",
		component: SettingsView,
		allowWithoutAuth: true
	},
	{
		uri: "/cs",
		component: CustomerServicesView
	},
	{
		uri: "/search",
		component: SearchView
	},
	{
		uri: "/search/advanced",
		component: AdvancedSearchView
	},
	{
		uri: "/search/orders/:OrderId",
		component: OrderDetailsView
	},
	{
		uri: "/search/orders/:OrderId/payment",
		render: routeProps => (
			<SettleOrderPaymentView backLink={`/search/orders/${routeProps.match.params.OrderId}`} backLinkLabel="Order Details" />
		)
	},
	{
		uri: "/search/orders/:OrderId/refund",
		render: routeProps => (
			<SettleOrderPaymentView backLink={`/search/orders/${routeProps.match.params.OrderId}`} backLinkLabel="Order Details" />
		),
		permission: [Permissions.P524_RECALL_REFUND_IN_POS]
	},
	{
		uri: "/search/orders/item/:ItemId/order",
		component: OrderDetailsView
	},
	{
		uri: "/uploadqueue",
		render: () => (
			<Flex gap={0.25}>
				<String
					bold={true}
					component="h1"
					str="Offline Order Upload Queue"
					variant="h6" />
				<String
					color="textSecondary"
					str="Administrators can perform additional actions in the Device Settings menu" />
				<>&nbsp;</>
				<Paper>
					<UploadQueueView />
				</Paper>
			</Flex>
		)
	},
	{
		uri: "/",
		component: OrderView
	},
	{
		uri: "/",
		component: LoginView,
		allowWithoutAuth: true
	},
	{
		uri: "/",
		component: RegistrationView,
		allowWithoutAuth: true,
		allowWithoutDeviceRegistration: true
	},
	{
		uri: "/",
		render: routeProps => (<AuthedInvalidUriView {...routeProps} />),
		inexact: true,
		allowWithoutAuth: true,
		allowWithoutDeviceRegistration: true
	}
];

export default Routes;
