const Features = [
	{
		name: "Tickets",
		label: "Tickets"
	},
	{
		name: "TravelOffline",
		label: "Offline Tickets"
	},
	{
		name: "OrgProducts",
		label: "Products"
	},
	{
		name: "VoucherSales",
		label: "Vouchers",
		uri: "/?f12=true"
	}
];

export default Features;
