import {String} from "@hps/hops-react";
import {Api} from "@hps/hops-sdk-js";
import {makeStyles} from "@mui/styles";

const {Chip, Avatar} = require("@mui/material");

const UserChip = props => {

	const photoUri = props.user?.Photo && `${Api.baseURL}/uploads/user_photos/${props.user?.Photo.split("").join("/")}tn`;

	const useStyles = makeStyles(theme => {
		return (
			{
				secondaryText: {
					color: theme.palette.text.secondary
				}
			});
	});

	const classes = useStyles();

	const renderChip = () => {
		return <Chip
			avatar={props.user?.Photo && <Avatar alt="Test Person" src={photoUri} />}
			label={<div><b>{props.user?.Account?.Fname} {props.user?.Account?.Sname}</b> <span className={classes.secondaryText}>(U{props.user?.Id})</span></div>}
		/>;
	};

	return props.user?.Id ? renderChip() : <String color="textSecondary" str="(None)" />;

};

export default UserChip;
