import {Api} from "@hps/hops-sdk-js";
import moment from "moment/moment";

/**
 * Device service
 *
 *
 * @package HOPS
 * @subpackage Services
 * @copyright Heritage Operations Processing Limited
 */
class PosDeviceService {

	/**
	 * Send a transaction outcome log to the HOPS backend.
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static setTxnOutcome(OrderId, Success, BasketState, OutcomeState) {
		return Api.call({
			url: "/api/pos/devices/txn/outcome",
			method: "POST",
			data: {
				OrderId,
				Success,
				BasketState,
				OutcomeState
			}
		}).then(({data}) => data);
	}


	/**
	 * Send a transaction outcome log to the HOPS backend.
	 *
	 * @return {Promise} Resolves with the API's response data
	 */
	static getTicketCache() {

		const today = new moment().format("YYYY-MM-DD");

		return Api.call({
			url: `/api/pos/devices/farecache/${today}`,
			method: "GET"
		}).then(({data}) => data);
	}

}

export default PosDeviceService;
