import {Flex, Loadable, Paper, String, Table, TableList, withRegistration} from "@hps/hops-react";
import {Api} from "@hps/hops-sdk-js";
import moment from "moment";
import React, {useEffect, useMemo, useState} from "react";

import withAuthUser from "Hoc/withAuthUser";

import TimetableGraph from "./TimetableGraph";

import scss from "./TimetableBrowser.module.scss";

/**
 * Timetable browser
 *
 * Renders today's timetable
 * 
 * @package HOPS
 * @subpackage CustomerServices
 * @copyright Heritage Operations Processing Limited
 */
const TimetableBrowser = props => {

	const today = useMemo(() => new moment(), []);
	const orgId = useMemo(() => props.Registration?.Org?.Id, [props]);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [operatingDay, setOperatingDay] = useState({});


	/**
	 * Builds a calling on line ("Calling at X, Y and Z" | "Calling at A only.") for a Service
	 * 
	 * @param {*} Service 
	 * @returns {string} A formatted string listing all the calling on points for the Service.
	 */
	const formatCallingOnPoints = Service => {

		const subsequentDestinationList = [];
		let subsequentDestinationsString = "";

		if (Service.Schedules.length === 2) {

			// Single Destination
			subsequentDestinationsString = `Calling at ${Service.Schedules[1].TimingPoint.Name} only.`;

		}
		else {

			for (let i = 1; i < Service.Schedules.length; i++) {

				const currentService = Service.Schedules[i];

				if (currentService.TimingPoint.Station === false) continue;

				let timingPointLabel = currentService.TimingPoint.Name;

				if (currentService.ArrivalTime) timingPointLabel = timingPointLabel.concat(` (${new moment(currentService.ArrivalTime, "HH:mm:ss").format("HH:mm")})`);

				if (i === (Service.Schedules.length - 2)) {

					// Penultimate destination, no comma
					subsequentDestinationList.push(`${timingPointLabel} `);
				}
				else if (i === (Service.Schedules.length) - 1) {

					// Final destination, and and full stop.
					subsequentDestinationList.push(` and ${timingPointLabel}.`);
				}
				else {

					// In between destination - comma separated.
					subsequentDestinationList.push(`${timingPointLabel}, `);
				}

			}

			subsequentDestinationsString = `Calling at ${subsequentDestinationList.join("")}`;

		}

		return subsequentDestinationsString;

	};


	/**
	 * Component startup
	 */
	useEffect(() => {

		const todayFormatted = today.format("YYYY-MM-DD");

		// Put this in a service somewhere
		const getTrains = async () => {
			setError(null);
			setLoading(true);
			await Api.call({url: `/api/pos/timetable/${todayFormatted}`})
				.then(response => {
					setOperatingDay(response.data);
				})
				.catch(e => setError(e))
				.finally(() => setLoading(false));
		};

		// API call to get the timetable data
		getTrains();

	}, [orgId, today]);


	/**
	 * Definition of columns to show in the Service List table
	 */
	const serviceTableFields = [
		{
			label: "Head Code",
			render: i => <String noFlex={true} str={i.Headcode} />
		},
		{
			label: "Service",
			render(i) {

				const departure = `${new moment(i.Schedules[0].DepartureTime, "HH:mm:ss").format("HH:mm")} ${i.Schedules[0].TimingPoint.Name}`;
				const arrival = i.Schedules[i.Schedules.length - 1].TimingPoint.Name;

				return (
					<>
						<Flex columnar={true}>
							<String str={`${departure} to ${arrival}`} />
							<String color="textSecondary" noFlex={true} str={i.ScheduleType} />
						</Flex>
						<String
							color="textSecondary"
							str={formatCallingOnPoints(i)}
							variant="body2" />
					</>
				);
			}
		}
	];


	/**
	 * Definition of rows to show in the Working Timetable table.
	 */
	const workingTimetableRows = [
		{
			label: "Working Timetable",
			content() {

				let timetableName = operatingDay?.OperatingDay?.Timetable?.Name;
				if (operatingDay?.OperatingDay?.TimetableModifier) timetableName = timetableName.concat(`, ${operatingDay?.OperatingDay?.TimetableModifier.Name}`);

				const timetableString = [];

				if (operatingDay?.OperatingDay?.CalendarNotes) timetableString.push(operatingDay?.OperatingDay?.CalendarNotes);
				timetableString.push(timetableName);
				if (operatingDay?.OperatingDay?.TimetableNonStandard) timetableString.push("(Non-standard Timetable)");


				return (
					<Flex columnar={true} alignItems="center">
						<div style={{
							background: operatingDay?.OperatingDay?.Timetable?.Colour,
							borderRadius: "50%",
							height: "1.5rem",
							width: "1.5rem"
						}} />
						<Flex gap={0}>
							<String gap={0} str={timetableString} />
						</Flex>
					</Flex>
				);
			}
		}
	];


	return (
		<Flex px={1} py={1}>
			<Flex columnar={true}>
				<String bold={true} noFlex={true} str={`Timetable - ${today.format("dddd D MMMM YYYY")}`} variant="h5" />
			</Flex>
			<Loadable
				error={!!error}
				errorStr={error}
				FlexProps={{alignItems: "flex-start"}}
				loading={loading}>
				<TableList data={workingTimetableRows} />
				<Paper>
					<TimetableGraph services={operatingDay.Services} />
				</Paper>
				<Flex gap={0.5}>
					<String bold={true} noFlex={true} str="Service List" variant="h6" />
					<Table
						fields={serviceTableFields}
						items={operatingDay.Services || []}
						tableClassName={scss.table} />
				</Flex>
			</Loadable>
		</Flex>
	);

};

export default withAuthUser(withRegistration(TimetableBrowser));
