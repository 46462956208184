import {CountryPicker, Flex, Hidden, String, TextField} from "@hps/hops-react";
import {PosCustomerDetailsCollectionMode, PosCustomerDetailsType} from "@hps/hops-sdk-js";
import {useCallback} from "react";

import scss from "./CheckoutCustomerDetailsForm.module.scss";

export default props => {

	const {onChange, values} = props;

	const allowedDetailTypes = Object.keys(props.allowedDetails).map(t => parseInt(t));

	/** Country is always set automatically so it's not relevant when considering whether the user has made an input */
	const hasAnyAddressField = Object.keys((values?.Address || {})).filter(k => (k !== "Country")).map(k => (values?.Address || {})[k]).some(a => !!a);

	const isRequired = useCallback(type => {
		return (props.allowedDetails[type] === PosCustomerDetailsCollectionMode.Required);
	}, [props.allowedDetails]);

	const getLabel = useCallback((label, type) => {
		if (isRequired(type) && !props.enforceRequired && ((type !== PosCustomerDetailsType.Address) || !hasAnyAddressField)) {
			label = `${label} *`;
		}
		return label;
	}, [hasAnyAddressField, isRequired, props.enforceRequired]);

	const handleChangeAddress = useCallback((value, name) => {
		onChange({
			Country: "GB",
			...values?.Address,
			[name]: value
		}, "Address");
	}, [onChange, values]);

	return (
		<form autoComplete="off" ref={props.formRef}>
			<Flex>
				{props.title ?
					<String
						color="textSecondary"
						str={props.title}
						variant="h6" /> :
					<String
						color="textSecondary"
						str="Customer Details" />}
				<Flex pl={1}>
					<Hidden hidden={!allowedDetailTypes.includes(PosCustomerDetailsType.Name)}>
						<Flex
							columnar={true}
							width="100%">
							<TextField
								autoComplete="new-password" // https://issues.chromium.org/issues/41163264
								className={scss.nameInput}
								disabled={props.disabled}
								label={getLabel("First Name", PosCustomerDetailsType.Name)}
								maxLength={250}
								name="Fname"
								onChange={onChange}
								required={(props.enforceRequired && isRequired(PosCustomerDetailsType.Name))}
								value={values?.Fname} />
							<TextField
								autoComplete="new-password" // https://issues.chromium.org/issues/41163264
								className={scss.nameInput}
								disabled={props.disabled}
								label={getLabel("Surname", PosCustomerDetailsType.Name)}
								maxLength={250}
								name="Sname"
								onChange={onChange}
								required={(props.enforceRequired && isRequired(PosCustomerDetailsType.Name))}
								value={values?.Sname} />
						</Flex>
					</Hidden>
					<Hidden hidden={!allowedDetailTypes.includes(PosCustomerDetailsType.Email)}>
						<TextField
							autoComplete="new-password" // https://issues.chromium.org/issues/41163264
							disabled={props.disabled}
							label={getLabel("Email Address", PosCustomerDetailsType.Email)}
							name="Email"
							maxLength={250}
							onChange={onChange}
							required={(props.enforceRequired && isRequired(PosCustomerDetailsType.Email))}
							type="email"
							value={values?.Email} />
					</Hidden>
					<Hidden hidden={!allowedDetailTypes.includes(PosCustomerDetailsType.Phone)}>
						<TextField
							autoComplete="new-password" // https://issues.chromium.org/issues/41163264
							disabled={props.disabled}
							label={getLabel("Phone Number", PosCustomerDetailsType.Phone)}
							name="Phone"
							maxLength={16}
							minLength={8}
							onChange={onChange}
							pattern="^[0-9\+\-\(\) ]+$"
							required={(props.enforceRequired && isRequired(PosCustomerDetailsType.Phone))}
							type="tel"
							value={values?.Phone} />
					</Hidden>
					<Hidden hidden={!allowedDetailTypes.includes(PosCustomerDetailsType.Address)}>
						<String
							bold={true}
							str="Address" />
						<TextField
							autoComplete="new-password" // https://issues.chromium.org/issues/41163264
							disabled={props.disabled}
							label={getLabel("Street", PosCustomerDetailsType.Address)}
							name="Address"
							maxLength={100}
							onChange={handleChangeAddress}
							required={(hasAnyAddressField || (props.enforceRequired && isRequired(PosCustomerDetailsType.Address)))}
							value={values?.Address?.Address} />
						<TextField
							autoComplete="new-password" // https://issues.chromium.org/issues/41163264
							disabled={props.disabled}
							label={getLabel("City/Town", PosCustomerDetailsType.Address)}
							name="City"
							maxLength={100}
							onChange={handleChangeAddress}
							required={(hasAnyAddressField || (props.enforceRequired && isRequired(PosCustomerDetailsType.Address)))}
							value={values?.Address?.City} />
						<Flex columnar={true}>
							<TextField
								autoComplete="new-password" // https://issues.chromium.org/issues/41163264
								disabled={props.disabled}
								className={scss.addressCountyInput}
								label={getLabel("County/State", PosCustomerDetailsType.Address)}
								name="County"
								maxLength={100}
								onChange={handleChangeAddress}
								required={(hasAnyAddressField || (props.enforceRequired && isRequired(PosCustomerDetailsType.Address)))}
								value={values?.Address?.County} />
							<TextField
								autoComplete="new-password" // https://issues.chromium.org/issues/41163264
								disabled={props.disabled}
								className={scss.addressSecondaryInput}
								label={getLabel("Postcode", PosCustomerDetailsType.Address)}
								name="Postcode"
								maxLength={10}
								onChange={handleChangeAddress}
								required={(hasAnyAddressField || (props.enforceRequired && isRequired(PosCustomerDetailsType.Address)))}
								value={values?.Address?.Postcode} />
							<CountryPicker
								className={scss.addressSecondaryInput}
								disabled={props.disabled}
								label={getLabel("Country", PosCustomerDetailsType.Address)}
								name="Country"
								onChange={handleChangeAddress}
								required={(hasAnyAddressField || (props.enforceRequired && isRequired(PosCustomerDetailsType.Address)))}
								value={(values?.Address?.Country || "GB")} />
						</Flex>
					</Hidden>
				</Flex>
			</Flex>
		</form>
	);

};
