import {UnhandledErrorView} from "@hps/hops-react";
import * as Sentry from "@sentry/react";
import React from "react";

import "./CustomerServicesView.module.scss";
import CsBrowser from "CustomerServices/CsBrowser";

/**
 * Customer Services main view
 * 
 * @package HOPS
 * @subpackage CustomerServices
 * @copyright Heritage Operations Processing Limited
 */
const CustomerServicesView = () => {

	return (
		<div className="csView">
			<Sentry.ErrorBoundary fallback={UnhandledErrorView}>
				<CsBrowser />
			</Sentry.ErrorBoundary>
		</div>
	);

};

export default CustomerServicesView;
