import {Banner} from "@hps/hops-react";
import {PriceUtils} from "@hps/hops-sdk-js";
import {memo} from "react";


const OrderOverRefundWarningAlert = memo(({
	amountOwing,
	refundAmount
}) => {

	return (
		(refundAmount > amountOwing) &&
			<Banner
				title="You are refunding more than the amount owed"
				severity="warning">
				{`The customer is currently owed a total refund of ${PriceUtils.getDisplayString(amountOwing)}. Continuing will over-refund the customer. You are responsible for ensuring this is correct.`}
			</Banner>
	);

});

export default OrderOverRefundWarningAlert;
