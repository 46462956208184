import {DeviceConnectionModes, DeviceOfflineTypes} from "@hps/hops-react";
import moment from "moment";
import {connect} from "react-redux";

import ConnectivityService from "Services/ConnectivityService";

const withReducedFunctionality = Component => connect(({Basket, Cache, Registration}) => ({Basket, Cache, Registration}))(props => {

	const isAppOffline = !ConnectivityService.appOnline();
	const isNetworkOffline = !ConnectivityService.networkOnline();
	const connectivityChanged = (isNetworkOffline !== isAppOffline);

	const basketOfflineUuid = props.Basket?.Identity?.OfflineBasketUuid;
	const isReducedFunctionality = (isAppOffline || !(basketOfflineUuid === null));

	const today = new moment().format("YYYY-MM-DD");

	const ticketCacheValid = (
		props.Cache?.Fares?.Tickets &&
		props.Cache?.Fares.Tickets.length &&
		props.Cache?.Fares.ValidDate === today);

	const deviceConnectionMode = props.Registration?.Device?.ConnectionMode;

	let offlineType = null;

	if (isAppOffline) {

		switch (deviceConnectionMode) {

			case DeviceConnectionModes.Online:
				// Working in online mode, network disconnected
				offlineType = DeviceOfflineTypes.Disconnected;
				break;

			case DeviceConnectionModes.OfflineSync:
				// Working in offline+sync mode
				offlineType = DeviceOfflineTypes.AlwaysOffline;
				break;

			default:
				offlineType = null;
		}

	}

	const alwaysOffline = offlineType === DeviceOfflineTypes.AlwaysOffline;

	return (<Component
		{...props}
		AlwaysOffline={alwaysOffline}
		ConnectivityChanged={connectivityChanged}
		DeviceConnectionMode={deviceConnectionMode}
		Offline={isAppOffline}
		OfflineNetwork={isNetworkOffline}
		OfflineType={offlineType}
		ReducedFunctionality={isReducedFunctionality}
		ticketCacheValid={ticketCacheValid} />);
});

export default withReducedFunctionality;
