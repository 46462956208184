import {String} from "@hps/hops-react";

import scss from "./ItemCardLabel.module.scss";

const ItemCardLabel = ({color, label, noCentring, style}) => (
	<String
		centre={true}
		color={(!color ? "primary" : color)}
		classNameTypography={`${scss.typography} ${(!noCentring ? scss.typographyCentred : "")}`.trim()}
		noFlex={true}
		str={label}
		style={style}
		variant="h6" />
);

export default ItemCardLabel;
