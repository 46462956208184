import {Button, Dialog, Flex, Grid, TextField} from "@hps/hops-react";
import React, {createRef, useEffect, useState} from "react";

/**
 * User email address input dialog (for receipts)
 * 
 * @package HOPS
 * @subpackage Checkout
 * @copyright Heritage Operations Processing Limited
 */
const CheckoutCustomerEmailForm = props => {

	const formRef = createRef();
	const inputRef = createRef();

	const [error, setError] = useState(false);
	const [email, setEmail] = useState(null);

	const emailProviders = [
		"@aol",
		"@gmail",
		"@hotmail",
		"@icloud",
		"@outlook",
		"@proton",
		"@sky",
		"@yahoo"
	];

	const topLevelDomains = [
		".au",
		".com",
		".co.uk",
		".me",
		".net",
		".org",
		".uk"
	];

	const handleAppendDomain = domain => {
		handleChange(email ? email.concat(domain) : domain);
	};

	const handleChange = email => {
		setEmail(email);
		setError(false);
	};

	useEffect(() => {
		if (error) inputRef?.current?.select?.();
	}, [error, inputRef]);

	const handleSubmit = async e => {

		e?.preventDefault?.();

		if (!formRef?.current?.reportValidity?.()) {
			setError(true);
			return;
		}

		// if email valid
		await props.onSubmit(email);
		// else setError(true);

	};

	const formStyles = {width: "100%"};

	return (
		<Dialog
			onClose={props.onClose}
			onOk={handleSubmit}
			open={props.open}
			title="Customer Email Address">
			<form
				onSubmit={handleSubmit}
				ref={formRef}
				style={formStyles}>
				<Flex>
					<TextField
					// We're on a shared POS, don't save email addresses to the browser
						autoFocus={true}
						error={error}
						errorText="Please enter a valid email address."
						fullWidth={true}
						inputCharPatternMatchesPattern={true}
						// inputProps={inputProps}
						inputRef={inputRef}
						label="Customer Email Address"
						onChange={handleChange}
						onEnter={handleSubmit}
						// pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
						required={true}
						type="email"
						value={email}
						variant="standard" />
					<Grid container spacing={0.5}>
						{emailProviders.map((button, key) => {
							return (
								<Grid item key={key}>
									<Button label={button} onClick={handleAppendDomain} value={button} />
								</Grid>
							);
						})}
					</Grid>
					<Grid container spacing={0.5}>
						{topLevelDomains.map((button, key) => {
							return (
								<Grid item key={key}>
									<Button label={button} onClick={handleAppendDomain} value={button} />
								</Grid>
							);
						})}
					</Grid>
				</Flex>
			</form>
		</Dialog>
	);

};

export default CheckoutCustomerEmailForm;
