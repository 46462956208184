import {Dialog} from "@hps/hops-react";
import React from "react";

import ReceiptUi from "Receipts/ReceiptUi";

/**
 * Price input dialog
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const RePrintReceiptDialog = props => {

	return (
		<Dialog
			onClose={props.onClose}
			open={props.open}
			title="Customer Receipt">

			<ReceiptUi
				DisableAutomaticPrinting={true}
				PaymentOutcome={props.PaymentOutcome}
				Reprint={true} />

		</Dialog>
	);

};

export default RePrintReceiptDialog;
