import {Flex, Hidden, Loader, NumericInput, SnackbarService, String} from "@hps/hops-react";
import {CheckoutService, PriceUtils} from "@hps/hops-sdk-js";
import {useEffect} from "react";

import dBasketIdentity from "Dispatchers/dBasketIdentity";
import dBasketIdentityOffline from "Dispatchers/dBasketIdentityOffline";
import withBasket from "Hoc/withBasket";
import withReducedFunctionality from "Hoc/withReducedFunctionality";

/**
 * A base for implementing functionality that's common to all Payment Processors
 * such as a keypad for entering a tender amount, extending the basket lifetime, OK and
 * Cancel buttons, etc.
 * 
 * Other Payment Processors then only have to implement their vendor-specific logic.
 * 
 * @param {boolean} CanOvercharge allows the cashier to tender a payment above the requested amount (i.e. Cash)
 * @param {string} BasketIdentity the ID of the basket, so we can extend the lifetime.
 * @param {number} children any extra buttons to render at the side of the keypad
 * @param {object} onCancel callback for cancel button pressed
 * @param {object} onChange callback value changed
 * @param {object} onOK callback for OK button pressed
 * @param {object} RequestedAmount in the currency's smallest minor unit (i.e. British Pennies in the UK)
 * @param {object} PaymentsInProgress HOC if payments is loading
 * @param {object} value of the amount entered
 * @returns JSX
 */
const PaymentProcessorBase = ({CanOvercharge=false, BasketIdentity, children, onCancel, onChange, onOK, RequestedAmount=null, PaymentsInProgress=false, ReducedFunctionality, value=0}) => {

	// Reset our basket timer when we start a new payment collection
	useEffect(() => {
		const extendBasket = async () => {
			try {

				if (!ReducedFunctionality) {
					const basket = await CheckoutService.extendBasketLifetime(BasketIdentity?.Id);
					dBasketIdentity(basket?.Id, basket?.Expiry);
				}
				else {
					dBasketIdentityOffline();
				}

			}
			catch (e) {
				SnackbarService.snack(e);
			}
		};
		extendBasket();
	}, [BasketIdentity?.Id, ReducedFunctionality]);


	const handleChangeAmount = amount => {

		// Bound to the requested amount. For open-ended payment providers like cash just don't supply this.
		const isValid = (RequestedAmount && CanOvercharge === false ? (amount <= RequestedAmount) : true);

		if (isValid) {
			onChange(amount);
		}
		else {
			SnackbarService.snack(`A tender amount of ${PriceUtils.getDisplayStringIntl(amount)} would overcharge the customer.`, "warning");
			onChange(0);
		}

	};


	return (
		<Flex>
			<Hidden hidden={PaymentsInProgress}>
				<NumericInput
					formatAsCurrency={true}
					label="Amount Tendered"
					onCancel={onCancel}
					onChange={handleChangeAmount}
					onSubmit={onOK}
					value={value}>
					{(children)}
				</NumericInput>
				{(RequestedAmount && CanOvercharge === false) && <String
					color="textSecondary"
					str={`Maximum Tender: ${PriceUtils.getDisplayStringIntl(RequestedAmount)}`} />}
			</Hidden>


			{(PaymentsInProgress && <Loader size={40} />)}
		</Flex>
	);
};

export default withBasket(withReducedFunctionality(PaymentProcessorBase));
