import {Container, Link, String} from "@hps/hops-react";
import React from "react";
import {withRouter} from "react-router-dom";

import withAuthUser from "Hoc/withAuthUser.js";


/**
 * Access denied screen.
 *
 * @package HOPS
 * @subpackage Ui
 * @copyright Heritage Operations Processing Limited
 */
const MainDenied = props => {

	const targetUri = props.history.location.pathname;

	/**
	 * Render details.
	 * 
	 * @return {ReactNode}
	 */
	const renderDetails = () => {
		return (
			<pre>
				<p><strong>P: </strong> {(props.AccessReason?.pid ?? "-")}</p>
				<p><strong>V: </strong> {(props.AccessReason?.pvar ?? "-")}</p>
				<p><strong>R: </strong> {(props.AccessReason?.org ?? "-")}</p>
				<p><strong>T: </strong> {(targetUri || "-")}</p>
				<p><strong>I: </strong> {(props?.AccessReason?.internal ? "Yes" : "-")}</p>
				<p><strong>X: </strong> {`${(props.AccessReason?.request || "-")} ${(props.AccessReason?.response ? `(${props.AccessReason?.response})` : "")}`.trim()}</p>
			</pre>
		);
	};


	const renderLoginLink = () => {
		return (
			<>
				<Link endArrow={true} uri={`/?redirect=${encodeURI(targetUri)}`} label="Login to POS" />
			</>
		);
	};


	return (
		<Container>
			<String str="Access Denied" variant="h5" />
			<String color="textSecondary" str="You don't have permission to access this view." />
			{(!props.authed && renderLoginLink())}
			{(props.authed && renderDetails())}
			{(props.authed && <String str={[
				"Please include this information if you need to report this as a fault.",
				"Try logging out and back in again if you keep seeing this and you believe you should have permission."
			]} />)}
		</Container>
	);

};

export default withAuthUser(withRouter(MainDenied));
