import {EmptyStateGate, Flex, TabPanel, withRegistration} from "@hps/hops-react";
import React from "react";

import dCustomerServicesTab from "Dispatchers/dCustomerServicesTab.js";
import withUi from "Hoc/withUi.js";

import CommercialFaqsCsBrowser from "./CommercialFaqs/CommercialFaqsCsBrowser.js";
import CsNoFeaturesError from "./CsNoFeaturesError.js";
import TimetableBrowser from "./Timetable/TimetableBrowser.js";

import scss from "./CsBrowser.module.scss";


/**
 * Renders all available customer services panels as a tabbed layout
 * 
 * @package HOPS
 * @subpackage CustomerServices
 * @copyright Heritage Operations Processing Limited
 */
const CSBrowser = props => {

	const TabPanelFlexProps = {className: scss.root};

	const tabs = [
		{
			label: "Commercial FAQs",
			component: CommercialFaqsCsBrowser
		},
		{
			label: "Timetable",
			hidden: !props.hasRegistrationFeature("Tickets"),
			component: TimetableBrowser
		}
	].filter(t => !t.hidden);

	const activeTab = Math.min(Math.max(props.Ui.CustomerServicesTab, 0), tabs.length);

	if (!tabs.length) {
		return (
			<Flex px={1} py={1}>
				<EmptyStateGate isEmpty={true} />
			</Flex>
		);
	}

	return <>
		<TabPanel
			FlexProps={TabPanelFlexProps}
			gap={1}
			noTabsState={CsNoFeaturesError}
			onChange={dCustomerServicesTab}
			tabs={tabs}
			scrollButtons="auto"
			value={activeTab}
			variant="scrollable" />
	</>;

};

export default withRegistration(withUi(CSBrowser));
