import {Button, Flex, Paper, SnackbarService, String} from "@hps/hops-react";
import copy from "copy-to-clipboard";
import React from "react";

import FileCopyIcon from "@mui/icons-material/FileCopy";

import scss from "./CheckoutErrorDetails.module.scss";

/**
 * Checkout error details component
 *
 * Renders the information associated with a checkout error.
 * 
 * @package HOPS
 * @subpackage Checkout
 * @copyright Heritage Operations Processing Limited
 */
const CheckoutErrorDetails = props => {

	const handleCopyToClipboard = () => {
		copy(JSON.stringify(props.error?.response, null, 4));
		SnackbarService.snack("Diagnostic information copied to your device clipboard.", "success");
	};

	let msg, str;
	const errorCode = props.error?.response?.data?.Error;
	const errorName = props.error?.response?.data?.ErrorName;

	if (errorCode !== undefined) {
		if (errorCode === 3) {
			msg = "The basket content is invalid.\nThis could be because an item has changed price or been removed from sale since it was added to the basket.\nPlease return to the basket, refresh the device registration data, and remove and replace any basket items that have changed price or availability data.\nPlease include details of all the items in the basket if you contact support about this error.";
		}
		str = `${(errorName || "Unknown")} (${(errorCode || "-")})`;
	}
	else str = (props.error?.toString() || "Unhandled Error");

	return (
		<Paper
			className={scss.paper}
			variant="outlined">
			<Flex>
				<Flex
					px={1}
					py={1}>
					<String
						bold={true}
						color="error"
						str="Checkout Failed" />
					<String
						str={str} />
					<Flex
						className={scss.root}
						px={0.5}
						py={0.5}
						width="100%">
						<Flex columnar={true}
							justifyContent="space-between">
							<String str={"Diagnostic data to report to HOPS."} />
							<Button
								onClick={handleCopyToClipboard}
								startIcon={FileCopyIcon}
								label="Copy"
								variant="contained" />
						</Flex>
						<pre className={scss.pre}>
							{JSON.stringify(props.error.response, null, 4)}
						</pre>
					</Flex>
					{
						msg &&
						<String
							gap={1}
							str={msg} />
					}
				</Flex>
			</Flex>
		</Paper>
	);

};

export default CheckoutErrorDetails;
