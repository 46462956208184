import {DeviceConnectionModes, Store} from "@hps/hops-react";

class ConnectivityService {

	/**
	 * Determines the current app state
	 */
	static appOnline() {

		const deviceConnectionMode = Store.getState().Registration?.Device?.ConnectionMode;

		// Device Connection Mode = 0 (Offline + Sync)
		if (deviceConnectionMode === DeviceConnectionModes.OfflineSync) return false;

		// Device Connection Mode = 1 (Online)
		return Store.getState().Ui?.OnlineApp;

	}


	/**
	 * Determines the current network state
	 */
	static networkOnline() {

		const deviceConnectionMode = Store.getState().Registration?.Device?.ConnectionMode;

		// Device Connection Mode = 0 (Offline + Sync)
		if (deviceConnectionMode === DeviceConnectionModes.OfflineSync) return false;

		// Device Connection Mode = 1 (Online)
		return Store.getState().Ui?.OnlineNetwork;

	}

}

export default ConnectivityService;
