import {Button, Flex, Hidden, TextField, useValue} from "@hps/hops-react";
import {useCallback, useState} from "react";

import {handleScan} from "Services/ScannerService.js";

/**
 * Barcode / QR Code Scan Box
 *
 * @package HOPS
 * @subpackage Components
 * @copyright Heritage Operations Processing Limited
 */
const ScanBox = props => {

	const {
		label,
		placeholder,
		showSubmit,
		variant
	} = props;

	const textFieldInput = useValue(null);

	const [loading, setLoading] = useState(false);

	/**
	 * Submitting.
	 *
	 * @async
	 * @return {void}
	 */
	const handleSubmit = useCallback(e => {

		e?.preventDefault?.();

		setLoading(false);

		handleScan(textFieldInput.value);

		setLoading(false);

	}, [textFieldInput]);


	/**
	 * Render!
	 */
	return (
		<>
			<form onSubmit={handleSubmit}>
				<Flex columnar={true}>
					<TextField
						autoFocus={true}
						disabled={loading}
						label={(label || "Barcode / QR Code")}
						onChange={textFieldInput.setValue}
						placeholder={placeholder}
						required={true}
						value={textFieldInput.value}
						variant={(variant || "outlined")} />

					<Hidden hidden={!showSubmit}>
						<Button
							label="Search"
							loading={loading}
							type="submit"
							variant="contained" />
					</Hidden>
				</Flex>
			</form>
		</>
	);

};

export default ScanBox;
