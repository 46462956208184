import {Select, useData} from "@hps/hops-react";
import {memo, useCallback, useEffect, useMemo} from "react";

import GatewayService from "Gateway/GatewayService.js";

const GatewayPrinterPicker = memo(props => {

	const {disabled, helperText, label, name, onChange, value} = props;

	const state = useData(useCallback(() => {
		return GatewayService.getPrinters();
	}, []));


	const printerOptions = useMemo(() => {
		return (state.data || []).map(printer => {
			return {
				id: printer.Id,
				label: printer.Description,
				value: printer.Id
			};
		});
	}, [state.data]);


	useEffect(() => {
		if (state.data && printerOptions?.length) {
			const printerIds = printerOptions.map(o => o.id);
			if (value && !printerIds.includes(value)) {
				onChange(null, name);
			}
		}
	}, [name, onChange, printerOptions, state.data, value]);


	return (
		<Select
			disabled={disabled}
			helperText={helperText}
			label={(label || "(Default Printer)")}
			loading={state.loading}
			loadingError={!!state.error}
			name={name}
			onChange={onChange}
			options={printerOptions}
			value={value} />
	);

});

export default GatewayPrinterPicker;
