import {useElements, useStripe} from "@stripe/react-stripe-js";
import {memo, useEffect} from "react";

const StripeElementsContextConsumer = memo(({onUpdateContext}) => {

	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		onUpdateContext?.({stripe, elements});
	}, [onUpdateContext, stripe, elements]);

	return null;

});

export default StripeElementsContextConsumer;
