import {Flex, String} from "@hps/hops-react";
import {Api, PriceUtils} from "@hps/hops-sdk-js";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";

import InventoryGridItemBase from "Inventory/InventoryGridItemBase.js";

import scss from "./InventoryGridItemVoucher.module.scss";

/**
 * Inventory grid item
 *
 * An item displayed in an inventory grid.
 *
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const InventoryGridItemVoucher = props => {

	const accentColour = props.backgroundColor === "000000" ? null : props.backgroundColor;

	// Text to show for the balance to issue
	const balanceToIssueText = props.item.BalanceToIssueTextOverride || (
		(props.item.BalanceToIssue !== null) ?
			PriceUtils.getDisplayStringIntl(props.item.BalanceToIssue) :
			(props.item.BalanceToIssue !== undefined) ? "Custom Amount" : undefined);

	const label = (props.item.Labels?.Pos || balanceToIssueText);

	// Text to show if the SKU is available
	let skuAvailableMessage = `Expires ${(new moment(props.item.Expiry)).format("DD/MM/YYYY")}`;
	if (props.item.ExpiryMonths) skuAvailableMessage = `Expires in ${props.item.ExpiryMonths} ${pluralize("Month", props.item.ExpiryMonths)}`;

	// Prepend balance to issue text if there's a POS label
	if (props.item.Labels?.Pos) skuAvailableMessage = `${balanceToIssueText}, ${skuAvailableMessage}`;

	const subLabel = (props.skuUnavailable ?
		(props.skuUnavailableMessage || "Not Available") :
		skuAvailableMessage
	);

	const handleAddToBasket = price => {
		props.onAddToBasket?.(props.item, price);
	};

	return <>
		<InventoryGridItemBase
			accentColour={accentColour}
			disabled={(props.skuUnavailable || props.disabled || props.loading)}
			imageSrc={`${Api.baseURL}${props.imageSrc}`}
			price={props.item.BalanceToIssue}
			onAddToBasket={handleAddToBasket}>

			<Flex alignItems="center" gap={0}>
				{/* Voucher Title */}
				<String
					classNameTypography={scss.VoucherButtonTextLabel}
					color="primary"
					style={{color: accentColour}}
					str={label}
					variant="h6" />

				{/* Price Text */}
				<String
					classNameTypography={scss.VoucherPriceLabel}
					color={(!props.skuUnavailable ? "textSecondary" : "error")}
					str={subLabel}
					variant="subtitle1" />

			</Flex>
		</InventoryGridItemBase>
	</>;

};

export default InventoryGridItemVoucher;
