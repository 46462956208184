import {Flex, String, Table} from "@hps/hops-react";
import {PriceUtils} from "@hps/hops-sdk-js";
import {Chip, TableCell} from "@mui/material";
import moment from "moment";
import {memo} from "react";

import scss from "./OrderDetailsViewPaymentsTable.module.scss";

const OrderDetailsViewPaymentsTable = memo(({payments, summaryFooter, summaryFooterTotalLabel}) => {

	const tableFields = [
		{
			label: "Date/Time",
			render(i) {
				return <Flex gap={0}>
					<String str={(new moment(i.Timestamp)).format("DD/MM/YYYY HH:mm:ss")} />
					<String color="textSecondary" str={`Ref: ${i.Id}`} variant="subtitle2" />
				</Flex>;
			}
		},
		{
			label: "Source",
			render: i => i.Source?.Label
		},
		{
			label: "Payment Type",
			render(i) {
				return <Flex gap={0.5} columnar={true} noFlex={true}>
					<String str={i.Type?.Label} noFlex={true} />
					{i.Moto && <Chip
						color="success"
						label="MOTO"
						size="small" />}
				</Flex>;
			}
		},
		{
			label: "Processor",
			render: i => i.Processor?.Label
		},
		{
			label: "Remarks",
			render(i) {

				const statusId = i.Status?.Id;

				return <Flex gap={0}>
					<String
						// Failed, Error, Confirmed, Declined
						bold={[2, 7, 8, 9].includes(statusId)}
						str={i.Status?.Name}
						style={{
							color: (
								// Confirmed
								(statusId === 8) ?
									"green" :
									(
										// Pending
										(statusId === 1) ?
											"grey" :
											(
												// Failed, Error, Declined
												[2, 7, 9].includes(statusId) ?
													"red" :
													undefined
											)
									)
							)
						}} />
					{i.Remarks || <String color="textSecondary" str="(None)" />}
					{i.Offline && <String color="error" str="Device was offline" />}
				</Flex>;
			}
		},
		{
			label: "Amount",
			render: i => PriceUtils.getDisplayStringIntl(i.PaidAmount)
		}
	];

	return (
		<Table
			items={payments}
			fields={tableFields}
			footer={
				<>
					{summaryFooter &&
					<>
						<TableCell
							colSpan={(tableFields.length - 2)} />
						<TableCell
							colSpan={2}
							children={
								<String
									bold={true}
									color="textPrimary"
									noFlex={true}
									str={`${summaryFooterTotalLabel} ${PriceUtils.getDisplayStringIntl(payments.reduce((a, b) => (a + b.PaidAmount), 0))}`} />
							} />
					</>
					}
				</>
			}
			tableClassName={scss.table} />
	);

});

export default OrderDetailsViewPaymentsTable;
