import {BreadcrumbBar, EmptyStateGate, Flex, Hidden, String, withRegistration} from "@hps/hops-react";
import {CheckoutBasketItem, OrderableTypes} from "@hps/hops-sdk-js";
import React, {useState} from "react";

import withBasket from "Hoc/withBasket.js";
import withInventory from "Hoc/withInventory.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality";
import InventoryGrid from "Inventory/InventoryGrid.js";
import InventoryGridItemFolder from "Inventory/InventoryGridItemFolder";
import InventoryGridTileTypes from "Inventory/InventoryGridTileTypes";
import BasketService from "Services/BasketService.js";

import InventoryGridItemProduct from "./InventoryGridItemProduct";

import ArrowBack from "@mui/icons-material/ArrowBack.js";

/**
 * Product inventory browser
 *
 * Renders available org products.
 * 
 * @package HOPS
 * @subpackage Inventory
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const ProductInventoryBrowser = props => {

	/**
	 * Find the Default Folder specified in registration
	 * Fall back to any folder with no parent
	 */
	const rootFolder = props.Registration?.Folders.find(f => {
		if (!(props.Registration?.DefaultFolderId)) return f.Parent === null;
		return f.Id === props.Registration?.DefaultFolderId;
	});

	const [addingToBasket, setAddingToBasket] = useState(null);
	const [currentFolder, setCurrentFolder] = useState(rootFolder);

	const productsUnavailableOffline = props.Inventory?.Products?.filter(p => (p.AvailableOffline === false));

	/**
	 * Find existing items in the basket representing a product.
	 *
	 * @param {Object} product
	 * @param {Integer} targetPrice optional
	 * @return {Array<Object>} `CheckoutBasketItem`-like objects
	 */
	const findExistingProductBasketItems = (product, targetPrice=undefined) => {
		return props.BasketItems.filter(i => {
			const ot = (i.OrderableType === OrderableTypes.Addon);
			const id = (i.Item?.Id === product.Id);
			const price = ((targetPrice === undefined) || (i.Item?.Price === targetPrice));
			return (ot && id && price);
		});
	};


	/**
	 * Adding to the basket.
	 * 
	 * @return {ReactNode}
	 */
	const handleAddToBasket = async (product, price) => {

		setAddingToBasket(product);

		/**
		 * When the item's already in the basket, 
		 * update the quantity of that existing item
		 */
		const existingBasketItem = findExistingProductBasketItems(product, price)?.[0];

		/**
		 * We're adding a quantity of an existing item
		 */
		if (existingBasketItem) {
			try {
				await BasketService.updateItemQty(
					existingBasketItem,
					(existingBasketItem.Quantity + 1)
				);
			}
			catch (e) {
				// ...
			}
		}

		/**
		 * We're adding this item to the basket for the first time
		 */
		else {
			try {
				await props.onAddToBasket([
					CheckoutBasketItem.construct({
						OrderableType: OrderableTypes.Addon,
						Item: product,
						Price: price,
						Quantity: 1,
						VatProportion: product.VatProportion,
						VatRate: product.VatRate
					})
				]);
			}
			catch (e) {
				// ...
			}
		}

		setAddingToBasket(null);

	};


	/**
	 * Return a Folder object with a given Id
	 * 
	 * @return {Object}
	 */
	const getFolderById = folderId => {
		return props.Registration?.Folders.find(f => (f.Id === folderId));
	};


	/**
	 * Recursively walk back through folder parents to build a breadcrumb trail
	 * 
	 * @return {Array}
	 */
	const getFolderBreadcrumbs = (folder, currentBreadcrumbs = []) => {

		if (folder) {

			// We need to check if the current folder is acting as the root (regardless of if it has its own parent)
			const folderParentFolder = folder.Id !== rootFolder.Id ? folder.Parent : null;

			currentBreadcrumbs.push(folder);
			currentBreadcrumbs = currentBreadcrumbs.concat(getFolderBreadcrumbs(getFolderById(folderParentFolder)));
		}

		return currentBreadcrumbs;
	};

	/**
	 * Get available folders and products. Map them on to a generic object.
	 *
	 * @return {Array}
	 */
	const getInventoryTiles = () => {

		// Create an array of generic Tile objects with common properties
		const inventoryTiles = [];
		const currentFolderId = currentFolder ? currentFolder.Id : null;

		// Add Folders
		props.Registration?.Folders.filter(f => (f.Parent === currentFolderId)).forEach(folder => {
			inventoryTiles.push(
				{
					ButtonText: folder.Name, /* Note: This is inconsistent with Products */
					BackgroundColour: folder.BackgroundColour,
					Id: folder.Id,
					Item: folder,
					Name: folder.Name,
					Order: folder.Order,
					TileType: InventoryGridTileTypes.Folder
				}
			);
		});

		props.Inventory?.Products?.filter(p => (p.Folders.includes(currentFolderId))).forEach(product => {

			if (!(props.AlwaysOffline && !product.AvailableOffline)) {
				inventoryTiles.push(
					{
						ButtonText: product.ButtonText,
						BackgroundColour: product.BackgroundColour,
						Id: product.Id,
						Item: product,
						Name: product.Name,
						Order: product.Order,
						TileType: InventoryGridTileTypes.Product
					}
				);
			}
		});

		// Order the combined list
		inventoryTiles.sort((tileA, tileB) => {

			// Sort by Order
			if (tileA.Order < tileB.Order) return -1;
			if (tileA.Order > tileB.Order) return 1;

			// Fall through to crude sort by Name
			if (tileA.Name.toLowerCase() < tileB.Name.toLowerCase()) return -1;
			if (tileA.Name.toLowerCase() > tileB.Name.toLowerCase()) return 1;

			return 0;

		});

		return inventoryTiles;
	};
	const inventoryTiles = getInventoryTiles();

	// We need to check if the current folder is acting as the root (regardless of if it has its own parent)
	const parentFolder = currentFolder.Id !== rootFolder.Id ? getFolderById(currentFolder?.Parent) : null;
	const breadcrumbs = getFolderBreadcrumbs(currentFolder)?.toReversed();

	const handleBreadcrumbClick = id => {
		setCurrentFolder(getFolderById(id));
	};

	return (
		<Flex overflowY="auto" width="100%" gap={0.5}>

			<BreadcrumbBar breadcrumbs={breadcrumbs} onBreadcrumbClick={handleBreadcrumbClick} />

			<InventoryGrid>

				{/* Add a Back button to allow users to navigate through the tree */}
				{parentFolder && <InventoryGridItemFolder
					accentColour="#333"
					item={parentFolder}
					label={`Back to ${parentFolder?.Name}`}
					tileIcon={<ArrowBack sx={{fontSize: 45}} />}
					onChangeFolder={setCurrentFolder}/>}

				<EmptyStateGate isEmpty={!inventoryTiles.length}>
					{
						inventoryTiles?.map((inventoryTile, key) => {

							// For a Folder
							if (inventoryTile.TileType === InventoryGridTileTypes.Folder) {
								return (
									<InventoryGridItemFolder
										accentColour={inventoryTile.Item.BackgroundColour}
										key={`${currentFolder?.Id}.folders.${key}`}
										item={inventoryTile.Item}
										label={inventoryTile.ButtonText}
										onChangeFolder={setCurrentFolder} />
								);
							}

							// For a Product
							else if (inventoryTile.TileType === InventoryGridTileTypes.Product) {

								const itemOffline = (props.ReducedFunctionality && !inventoryTile.Item.AvailableOffline);
								const itemsInBasket = findExistingProductBasketItems(inventoryTile.Item);
								const countInBasket = itemsInBasket.reduce((a, b) => (a + b.Quantity), 0);
								const maxQtyExceeded = ((inventoryTile.Item.MaxQuantity !== null) && (countInBasket >= inventoryTile.Item.MaxQuantity));

								return (
									<InventoryGridItemProduct
										backgroundColor={inventoryTile.Item.BackgroundColour}
										key={`${currentFolder?.Id}.products.${key}`}
										imageSrc={`/uploads/local/${props.Registration.Org.Id}/railway_products/${inventoryTile.Id}/${inventoryTile.Id}`}
										item={inventoryTile.Item}
										label={inventoryTile.ButtonText}
										loading={(addingToBasket?.Id === inventoryTile.Id)}
										onAddToBasket={handleAddToBasket}
										price={inventoryTile.Item.Price}
										skuUnavailable={maxQtyExceeded || itemOffline}
										skuUnavailableMessage={itemOffline ? "Unavailable Offline" : `Maximum of ${inventoryTile.Item.MaxQuantity} Allowed`} />
								);
							}

							else {
								return null;
							}
						})
					}
				</EmptyStateGate>
			</InventoryGrid>
			<Hidden hidden={!(props.AlwaysOffline && productsUnavailableOffline.length)}>
				<String
					color="textSecondary"
					lineHeight="2.5em"
					py={2}
					str={`${productsUnavailableOffline.length} unavailable products are hidden because this device is always offline.`}
					variant="body2" />
			</Hidden>
		</Flex>
	);

};

export default withBasket(withInventory(withReducedFunctionality(withRegistration((ProductInventoryBrowser)))));
