import {Api} from "@hps/hops-sdk-js";
import {loadStripeTerminal} from "@stripe/terminal-js/pure";

/**
 * Stripe Terminal Service
 *
 * Wraps the Stripe Terminal APIs.
 *
 * @package HOPS
 * @subpackage Services
 * @copyright Heritage Operations Processing Limited
 * 
 */
class StripeService {

	// The Stripe APi backup URL to use if we're in reduced functionality mode
	STRIPE_BACKUP_URL = "https://micro.hops.org.uk/";

	// A callback to update the UI with an error
	handleError = null;

	// A callback to update the UI with a progress message
	handleProgressChange = null;

	// The Railway's associated Terminal Location, under the HOPS platform account.
	stripeLocation = null;

	// The Stripe Terminal reader serial number associated with this POS Device.
	stripeReaderSerialNumber = null;

	// A holder for the Stripe Terminal SDK object
	terminal = null;

	useBackupUrl = false;

	// These don't need to be stateful they're just reference.
	constructor(onError, onProgressChange, stripeLocation, stripeReaderSerialNumber, useBackupUrl) {
		this.handleError = onError;
		this.handleProgressChange = onProgressChange;
		this.stripeLocation = stripeLocation;
		this.stripeReaderSerialNumber = stripeReaderSerialNumber;

		/**
		 * We let the Payment Processor set this, rather than looking in the Redux store ourselves.
		 * The state may change while we're in the middle of a transaction.
		 */
		if (process.env.REACT_APP_STRIPE_API_BASE_URL) this.STRIPE_BACKUP_URL = process.env.REACT_APP_STRIPE_API_BASE_URL;

		this.useBackupUrl = useBackupUrl;

	}


	/**
	 * Initializes the Stripe Terminal SDK
	 */
	async init() {

		// Load the Stripe Terminal SDK. It injects its own <script> tags in to the DOM.
		const StripeTerminal = await loadStripeTerminal();

		/*
		 * Set up the Stripe Terminal SDK
		 * 
		 * Provide required handlers for fetching a Connection Token and
		 * dealing with reader disconnects.
		 */
		this.terminal = StripeTerminal.create({
			onFetchConnectionToken: async () => {

				this.handleProgressChange("Fetching Connection Token");

				let config = {
					url: `/api/pos/stripe/terminal/connectionToken`,
					method: "GET"
				};

				// Set API baseURL to backup URL
				if (this.useBackupUrl) config = {...config, baseURL: this.STRIPE_BACKUP_URL};

				try {
					const connectionToken = await Api.call(config);
					return connectionToken.data.token;
				}
				catch (e) {
					let msg = e;
					if (!e?.response) {
						msg = "Couldn't communicate with the HOPS Stripe API.";
					}
					this.handleError(`Stripe Terminal: ${msg}`);
				}

				/**
				 * It makes Stripe Terminal SDK unhappy if you don't return
				 * a valid Connection Token from this function, but I
				 * don't care.
				 */
				return null;
			},
			onUnexpectedReaderDisconnect() {

				/**
				 * This happens sporadically, I'm not sure how long you're
				 * supposed to persist the connection for (if at all)
				 * 
				 * Fairly sure we disconnect automatically when the component
				 * calling us dismounts.
				 */

				return;
			}
		});
	}


	/**
	 * See if the Stripe Terminal reader associated with this POS device
	 * is available, and try to connect to it with the Stripe Terminal SDK
	 * 
	 * Don’t cache the Reader object in your application.
	 * Connecting to a stale Reader can fail if the reader’s IP address has changed.
	 * 
	 * @param {*} discoveredReaders The list of Stripe Terminal readers from the SDK
	 * @returns {Promise} that resolves to an object with reader, error.
	 */
	async connectAssociatedReader(discoveredReaders) {

		// Check we have a selected reader
		if (this.stripeReaderSerialNumber === null) throw new Error("There is no Stripe Terminal reader assigned to this device.");

		// Find the one associated with this POS device
		const associatedReader = discoveredReaders.find(r => r.serial_number === this.stripeReaderSerialNumber);

		// Send a status update to the UI
		this.handleProgressChange(`Connecting to ${associatedReader.label}`);

		// Check we have a reader, and it's online.
		if (!(associatedReader && associatedReader.status === "online")) throw new Error("The Stripe Terminal reader assigned to this device isn't available");

		const result = await this.terminal.connectReader(
			associatedReader,
			{
				// The connection fails if the reader is currently connected to a different Terminal SDK.
				fail_if_in_use: true
			}
		);

		if (result.error) {
			throw new Error(result.error.message);
		}
		else {
			this.handleProgressChange(`Connected to ${result.reader.label}`);
			return result;
		}
	}

	/**
	 * Ask the HOPS backend to create us a Stripe Payment Intent.
	 * This runs server side because it needs our secret API key and
	 * it's a terrible idea having that client side.
	 * 
	 * @param {int} Amount How much to charge to the customer's card
	 * @returns {Promise} that resolves to an object with Stripe data.
	 */
	async createPaymentIntent(Amount) {

		this.handleProgressChange("Creating Payment Intent");

		let config = {
			url: `/api/pos/stripe/terminal/paymentIntent`,
			method: "POST",
			data: {Amount}
		};

		// Set API baseURL to backup URL
		if (this.useBackupUrl) config = {...config, baseURL: this.STRIPE_BACKUP_URL};

		try {
			const paymentIntent = await Api.call(config);

			return paymentIntent.data?.Stripe?.PaymentIntent?.client_secret;
		}
		catch (e) {

			let msg = e;

			if (!e?.response) {
				msg = "Couldn't communicate with the HOPS Stripe API.";
			}

			this.handleError(`Stripe Terminal: ${msg}`);
		}

		return null;

	}

	/**
	 * Ask the HOPS backend to refund an in-basket Payment Intent
	 * Use ONLY for in-basket payments that haven't been through checkout.
	 * 
	 * @param {int} Amount How much to charge to the customer's card
	 * @returns {Promise} that resolves to an object with Stripe data.
	 */
	async refundPaymentIntent(PaymentIntentId, Amount) {

		let config = {
			url: `/api/pos/stripe/terminal/paymentIntent/refund`,
			method: "POST",
			data: {
				PaymentIntentId,
				Amount
			}
		};

		// Set API baseURL to backup URL
		if (this.useBackupUrl) config = {...config, baseURL: this.STRIPE_BACKUP_URL};

		return await Api.call(config);

	}

	/**
	 * Create a Payment Method to interact with the customer's card.
	 * See https://stripe.com/docs/terminal/payments/collect-payment?terminal-sdk-platform=js#collect-payment
	 * 
	 * @param {string} clientSecret The client_secret from the Payment Intent received from HOPS.
	 * @returns {Promise} that resolves to an object with the updated paymentIntent, or error.
	 */
	async collectPaymentMethod(clientSecret) {

		this.handleProgressChange("Please follow instructions on the card reader.");

		/*
		 * clientSecret is the client_secret from the PaymentIntent we got
		 * Reader now waiting for card to be presented.
		 */
		const result = await this.terminal.collectPaymentMethod(
			clientSecret,
			{
				config_override: {
					enable_customer_cancellation: true
				}
			}
		);


		if (result.error) throw new Error(result.error.message);

		return result.paymentIntent;
	}

	/**
	 * Cancel an outstanding collectPaymentMethod command.
	 * 
	 * @returns {Promise} that resolves to an object with the updated paymentIntent, or error.
	 */
	async cancelCollectPaymentMethod() {

		this.handleProgressChange("Cancelling payment.");

		if (this.terminal === null) await this.init();

		const result = await this.terminal.cancelCollectPaymentMethod();

		if (result.error) throw new Error(result.error.message);

		return;
	}


	/**
	 * Checks for Stripe Terminal readers at a given location.
	 * (Remember, Stripe Terminal SDK objects live on the platform account)
	 * See https://stripe.com/docs/terminal/payments/connect-reader?reader-type=internet#discover-readers
	 * 
	 * @returns {Promise} that resolves to an object with discoveredReaders, or error.
	 */
	async discoverReaders() {

		this.handleProgressChange("Discovering Available Readers");

		if (this.terminal === null) await this.init();

		const readerConfig = {
			location: this.stripeLocation,
			simulated: false
		};

		// Discover the readers
		const result = await this.terminal.discoverReaders(readerConfig);

		if (result.error) {
			throw new Error(result.error.message);
		}
		else if (result.discoveredReaders.length === 0) {
			throw new Error("No Stripe Terminal readers available at this organisation.");
		}
		else {
			return result.discoveredReaders;
		}
	}


	/**
	 * Process the Payment
	 * See https://stripe.com/docs/terminal/payments/collect-payment?terminal-sdk-platform=js#confirm-payment
	 * The reader prompts the customer to insert or tap their card and then authorises the payment.
	 * 
	 * @param {object} paymentIntent 
	 * @returns {Promise} that resolves to an object with paymentIntent, or error.
	 */
	async processPayment(paymentIntent) {

		this.handleProgressChange("Confirming Payment with Stripe");

		const result = await this.terminal.processPayment(paymentIntent);

		if (result.error) {
			throw new Error(result.error.message);
		}
		else if (result.paymentIntent) {
			return result;
		}

		return null;
	}


	/**
	 * Run the whole Stripe payment flow as a series of dependent promises.
	 * This is a mix of HOPS backend and Stripe Terminal SDK.
	 * See https://stripe.com/docs/terminal/payments/setup-integration
	 * 
	 * @param {int} tenderedAmount The value to charge for
	 * @returns {Promise} that resolves to the final paymentIntent, or error.
	 */
	async RunPaymentFlow(tenderedAmount) {

		if (this.terminal === null) await this.init();

		// Run a series of dependent promises to resolve the payment.
		return await this.discoverReaders()
			.then(async discoveredReaders => await this.connectAssociatedReader(discoveredReaders))
			.then(async () => await this.createPaymentIntent(tenderedAmount))
			.then(async clientSecret => await this.collectPaymentMethod(clientSecret))
			.then(async paymentIntent => await this.processPayment(paymentIntent))
			.catch(e => (this.handleError(e)));
	}

	/**
	 * Resolves a Stripe JavaScript SDK error code in to a user-friendly message.
	 * https://docs.stripe.com/terminal/references/api/js-sdk?locale=en-GB#errors
	 * 
	 * @param {*} ex The Stripe Terminal SDK exception
	 * @returns string
	 */
	static resolveErrorCode(ex) {

		switch (ex?.error?.code) {
			case "no_established_connection":
				return "The command failed because no reader is connected.";
			case "no_active_collect_payment_method_attempt":
				return "Cancelling a Payment Method can only be called when a Payment Method is in progress.";
			case "no_active_read_reusable_card_attempt":
				return "Cancel CollectReusableCard can only be called when readReusableCard is in progress.";
			case "canceled":
				return "The command was canceled.";
			case "cancelable_already_completed":
				return "Cancellation failed because the operation has already completed.";
			case "cancelable_already_canceled":
				return "Cancellation failed because the operation has already been cancelled.";
			case "network_error":
				return "An unknown error occurred when communicating with the server or reader over the network. Refer to the error message for more information.";
			case "network_timeout":
				return "The request timed out when communicating with the server or reader over the network. Make sure both your device and the reader are connected to the network with stable connections.";
			case "already_connected":
				return "Connect Reader failed because a reader is already connected.";
			case "failed_fetch_connection_token":
				return "Unable to fetch a Stripe connection token from HOPS.";
			case "discovery_too_many_readers":
				return "Discover Readers returned too many readers";
			case "invalid_reader_version":
				return "The reader is running an unsupported software version. Please allow the reader to update and try again.";
			case "reader_error":
				return "The reader returned an error while processing the request. Refer to the error message for more information.";
			case "command_already_in_progress":
				return "The action can’t be performed, because an in-progress action is preventing it.";
			default:
				return ex?.message;
		}

	}
}


export default StripeService;
