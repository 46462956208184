import {Section} from "@hps/hops-react";
import {memo} from "react";

import scss from "./OrderDetailsViewPanel.module.scss";

const OrderDetailsViewPanel = memo(({children, footer, header, title}) => {

	return (
		<Section
			classNamePaper={scss.paper}
			footer={footer}
			header={header}
			square={true}
			title={title}>
			{children}
		</Section>
	);

});

export default OrderDetailsViewPanel;
