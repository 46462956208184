import {Flex, String} from "@hps/hops-react";
import {Api, PriceUtils} from "@hps/hops-sdk-js";
import React from "react";

import InventoryGridItemBase from "Inventory/InventoryGridItemBase.js";

import scss from "./InventoryGridItemProduct.module.scss";

/**
 * Inventory grid item
 *
 * An item displayed in an inventory grid.
 *
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const InventoryGridItemProduct = props => {

	const accentColour = props.backgroundColor === "000000" ? null : props.backgroundColor;
	const priceText = (props.priceTextOverride || ((props.price !== null) ? PriceUtils.getDisplayStringIntl(props.price) : ((props.price !== undefined) ? (props.customPriceText || "-") : undefined)));
	const unavailable = (props.soldOut || props.skuUnavailable);
	const skuUnavailableMessage = (props.skuUnavailable ? (props.skuUnavailableMessage || "Not Available") : undefined);
	const soldOutMessage = (props.soldOut ? "Sold Out" : undefined);
	const unavailableMessage = (soldOutMessage || skuUnavailableMessage);
	const label = props.label;
	const subLabel = ((!props.alwaysShowPriceText ? unavailableMessage : priceText) || priceText);

	/**
	 * Adding to basket.
	 *
	 * @param {Integer} price
	 * @return {void}
	 */
	const handleAddToBasket = price => {
		props.onAddToBasket?.(props.item, price);
	};


	return <InventoryGridItemBase
		accentColour={accentColour}
		disabled={(unavailable || props.disabled || props.loading)}
		imageSrc={`${Api.baseURL}${props.imageSrc}`}
		price={props.price}
		onAddToBasket={handleAddToBasket}>

		<Flex alignItems="center" gap={0}>
			{/* Product Title */}
			<String
				classNameTypography={scss.ProductButtonTextLabel}
				color="primary"
				style={{color: accentColour}}
				str={label}
				variant="h6" />

			{/* Price Text */}
			<String
				classNameTypography={scss.ProductPriceLabel}
				color={((!unavailable || props.alwaysShowPriceText) ? "textSecondary" : "error")}
				str={subLabel}
				variant="subtitle1" />

			{/* Unavailable Text */}
			<String
				bold={true}
				color="error"
				str={((unavailable && props.alwaysShowPriceText) ? unavailableMessage : undefined)}
				variant="subtitle1" />
		</Flex>
	</InventoryGridItemBase>;

};

export default InventoryGridItemProduct;
