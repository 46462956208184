import {connect} from "react-redux";

const withOrders = Component => connect(({Orders}) => ({Orders}))(
	props => {

		const uploadQueueLength = props.Orders?.UploadQueue?.length;
		const uploadQueueMaxRetries = props.Orders?.UploadQueue?.filter(q => q.RetryCount >= 10).length;
		const uploadQueueStale = props.Orders?.UploadQueue?.filter(q => q.Timestamp < ((Date.now() / 1000) - 86400)).length > 0;

		return (
			<Component
				{...props}

				uploadQueueLength={uploadQueueLength}
				uploadQueueMaxRetries={uploadQueueMaxRetries}
				uploadQueueStale={uploadQueueStale}
			/>
		);
	}
);

export default withOrders;
