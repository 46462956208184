import {Banner, Button, Checkbox, DeviceSettingsView, Flex, Hidden, Link, Navigator, Select, String, Switch, Table, TextField, useData, useRegistration, withMobile} from "@hps/hops-react";
import {ExitToApp} from "@mui/icons-material";
import React, {useCallback, useMemo, useState} from "react";

import Permissions from "App/Permissions.js";
import dAuthUser from "Dispatchers/dAuthUser";
import dOnlineApp from "Dispatchers/dOnlineApp";
import dSettings from "Dispatchers/dSettings";
import DefaultGatewayConfig from "Gateway/DefaultGatewayConfig.json";
import GatewayPrinterPicker from "Gateway/GatewayPrinterPicker.js";
import GatewayService from "Gateway/GatewayService.js";
import withAuthUser from "Hoc/withAuthUser";
import withOrders from "Hoc/withOrders";
import withReducedFunctionality from "Hoc/withReducedFunctionality";
import withSettings from "Hoc/withSettings";
import useGateway from "Hooks/useGateway";
import StripeTerminalPicker from "Payment/Processors/Stripe/StripeTerminalPicker";

import UploadQueueView from "./UploadQueue/UploadQueueView";

import GatewayStrings from "./GatewaySettings.strings.json";
import PosStrings from "./PosSettings.strings.json";

/**
 * Device settings view
 * 
 * @package HOPS
 * @subpackage Settings
 * @copyright Heritage Operations Processing Limited
 */
const SettingsView = props => {

	const [selectedUserId, setSelectedUserId] = useState(null);

	/**
	 * Access the hooks
	 */
	const gatewaySettings = useGateway();
	const registration = useRegistration();


	/**
	 * Check the connectivity to the Gateway server
	 */
	const serverStatusCheck = useData(
		useCallback(() => {
			if (gatewaySettings.Active) {
				return GatewayService.checkConnectivity();
			}
			else return null;
		}, [gatewaySettings.Active])
	);


	/**
	 * Server status state definitions
	 *
	 * @type {Array<Object>}
	 */
	const serverStatusStates = useMemo(() => {
		return [
			// Online
			{
				message: `Online (v${serverStatusCheck.data})`,
				color: "primary",
				bold: true
			},
			// Offline
			{
				message: `Connection error: Please check your configuration and ensure the server is running.`,
				color: "error",
				bold: true
			},
			// Inactive
			{
				message: `(Integration disabled)`,
				color: "textSecondary"
			},
			// Loading
			{
				message: `Loading...`,
				color: "textSecondary"
			},
			// Invalid security token
			{
				message: `Invalid security token.`,
				color: "error",
				bold: true
			}
		];
	}, [serverStatusCheck]);


	/**
	 * Get our active server status state definition
	 *
	 * @type {Object}
	 */
	const serverStatusState = serverStatusStates[
		(!gatewaySettings.Active ?
			2 :
			(!serverStatusCheck.error ?
				(!serverStatusCheck.loading ? 0 : 3) :
				((serverStatusCheck?.error?.response?.status === 403) ? 4 : 1)
			)
		)
	];

	const getPosUsers = () => {

		const users = registration.Users;

		return (users || []).map(user => {
			return {
				id: user.Id,
				label: `${user.Account?.Fname} ${user.Account?.Sname}`,
				value: user.Id
			};
		});
	};
	const posUsers = getPosUsers();


	/**
	 * POS `TableList` component's data (content) definition
	 */
	const posTableDataDefinition = useMemo(() => {

		const handleMasqueradeUser = () => {

			const user = registration.Users.find(user => user.Id === selectedUserId);

			if (user) {
				dAuthUser(user);
				Navigator.navigate("/");
			}

		};

		const handleToggleAutomaticReceiptPrinting = () => {
			dSettings({...props.Settings, AutomaticReceiptPrinting: !props.Settings.AutomaticReceiptPrinting});
		};

		const handleChangeReceiptsCpl = ReceiptsCpl => {
			dSettings({...props.Settings, ReceiptsCpl: (parseInt(ReceiptsCpl) || null)});
		};

		const handleSetStripeTerminalReader = reader => {
			dSettings({...props.Settings, StripeTerminalSerialNumber: reader});
		};

		const handleClearStripeTerminalReader = () => {
			dSettings({...props.Settings, StripeTerminalSerialNumber: null});
		};

		const handleForceConnectivityState = state => {
			dOnlineApp(state);
		};

		return [
			{
				label: PosStrings.AutomaticReceiptPrinting.label,
				content: (
					<Flex
						alignItems="center"
						columnar={true}
						gap={2.5}>
						<Switch
							checked={props.Settings.AutomaticReceiptPrinting}
							onClick={handleToggleAutomaticReceiptPrinting}
							color="primary" />
						<String
							color="textSecondary"
							str={PosStrings.AutomaticReceiptPrinting.caption} />
					</Flex>
				)
			},
			{
				label: "Connectivity",
				content: (
					<Flex>
						<Flex gap={0.5}>
							<div><b>Device Connection Mode:</b> {props.DeviceConnectionMode === 0 ? "Offline Sync" : "Direct"}</div>
							<div><b>App Offline:</b> {props.Offline ? "Yes" : "No"}</div>
							<div><b>Network Offline:</b> {props.OfflineNetwork ? "Yes" : "No"}</div>
							<div><b>Pending Connectivity Change:</b> {props.ConnectivityChanged ? "Yes" : "No"}</div>
						</Flex>
						<Flex
							alignItems="center"
							columnar={true}
							gap={2.5}>
							<Button
								disabled={!(props.Offline)}
								label="Force App Online"
								onClick={handleForceConnectivityState}
								value={true} />
							<Button
								disabled={props.Offline}
								label="Force App Offline"
								onClick={handleForceConnectivityState}
								value={false} />
						</Flex>
					</Flex>
				)
			},
			{
				label: PosStrings.ReceiptsCpl.label,
				content: (
					<Flex alignItems="flex-start">
						<TextField
							inputCharPattern="^[0-9]+$"
							label={PosStrings.ReceiptsCpl.caption}
							inputProps={{inputMode: "numeric"}}
							onChange={handleChangeReceiptsCpl}
							value={(props.Settings.ReceiptsCpl?.toString() || "")} />

					</Flex>
				)
			},
			{
				label: "Masquerade",
				content: (
					<>
						<Flex columnar={true}>
							<Select
								onChange={setSelectedUserId}
								options={posUsers}
								value={selectedUserId} />
							<Button
								label="Masquerade"
								onClick={handleMasqueradeUser}
								startIcon={ExitToApp} />
						</Flex>
					</>
				),
				// We're only visible if nobody is logged in.
				hidden: props.authed
			},
			{
				label: "Stripe Terminal Reader",
				content: (
					<StripeTerminalPicker
						disabled={(!registration.Org?.StripeConnect?.LocationId)}
						helperText="Sets reader used for card payments."
						name="StripeTerminalSerialNumber"
						onChange={handleSetStripeTerminalReader}
						onClear={handleClearStripeTerminalReader}
						stripeLocation={registration.Org?.StripeConnect?.LocationId}
						value={(registration.Org?.StripeConnect?.LocationId ? props.Settings.StripeTerminalSerialNumber : undefined)} />
				),
				hidden: !registration.Org?.StripeConnect?.LocationId
			}
		];
	}, [props.authed, props.ConnectivityChanged, props.DeviceConnectionMode, props.Offline, props.OfflineNetwork, props.Settings, posUsers, registration, selectedUserId, setSelectedUserId]);

	/**
	 * Gateway `TableList` component's data (content) definition
	 */
	const gatewayTableDataDefinition = useMemo(() => {
		return [
			{
				label: "Host",
				content: (
					<Flex gap={2}>
						<Flex columnar={true}>
							<TextField
								disabled={gatewaySettings.Active}
								name="Host"
								helperText={(!gatewaySettings.Active ? "Protocol (e.g. http://) is required." : GatewayStrings.controlsDisabledBecauseIntegrationActiveHint)}
								label="Host"
								onChange={gatewaySettings.updateConfig}
								placeholder={DefaultGatewayConfig.Host}
								value={gatewaySettings.Host} />
							<TextField
								disabled={gatewaySettings.Active}
								name="Port"
								helperText={(!gatewaySettings.Active ? GatewayStrings.requiresServerMatch : GatewayStrings.controlsDisabledBecauseIntegrationActiveHint)}
								label="Port"
								onChange={gatewaySettings.updateConfig}
								placeholder={DefaultGatewayConfig.Port?.toString()}
								fullWidth={false}
								type="number"
								value={gatewaySettings.Port?.toString()} />
						</Flex>
						<Flex columnar={true}>
							<TextField
								disabled={gatewaySettings.Active}
								name="SecurityToken"
								helperText={(!gatewaySettings.Active ? GatewayStrings.requiresServerMatch : GatewayStrings.controlsDisabledBecauseIntegrationActiveHint)}
								label="Security Token"
								onChange={gatewaySettings.updateConfig}
								placeholder={GatewayStrings.securityToken}
								type="password"
								value={gatewaySettings.SecurityToken} />

						</Flex>
					</Flex>
				)
			},
			{
				label: "Enable integration",
				content: (
					<Flex columnar={true} alignItems="center">
						<Checkbox
							checked={gatewaySettings.Active}
							name="Active"
							onChange={gatewaySettings.updateConfig} />
						<Flex>
							<String
								bold={serverStatusState?.bold}
								color={serverStatusState?.color}
								str={serverStatusState?.message} />
							<Hidden hidden={!gatewaySettings.Active}>
								<Link
									disabled={serverStatusCheck.loading}
									label="Refresh"
									onClick={serverStatusCheck.fetch} />
							</Hidden>
						</Flex>
					</Flex>
				)
			},
			{
				label: "Printer: Receipts",
				content: (
					<Flex alignItems="center"
						columnar={true}
						gap={2.5}>
						<Checkbox
							checked={gatewaySettings.ReceiptsEnabled}
							name="ReceiptsEnabled"
							onChange={gatewaySettings.updateConfig} />
						<String
							color="textSecondary"
							str="POS Gateway must be configured with a compatible receipt printer." />
					</Flex>
				),
				hidden: !gatewaySettings.Active
			},
			{
				label: "Printer: Stationery (tickets)",
				content: (
					<Flex columnar={true}>
						<Checkbox
							checked={true}
							disabled={true} />
						<GatewayPrinterPicker
							disabled={!gatewaySettings.Active}
							name="StationeryPrinterId"
							onChange={gatewaySettings.updateConfig}
							value={gatewaySettings.StationeryPrinterId} />
					</Flex>
				),
				hidden: !gatewaySettings.Active
			}
		];
	}, [gatewaySettings, serverStatusCheck, serverStatusState]);

	const uploadQueueTableDataDefinition = useMemo(() => {

		return [
			{
				label: "Queue",
				content: <UploadQueueView adminActions={true} />
			}
		];

	}, []);


	const renderTicketCacheStatus = () => {
		if (props.ticketCacheValid) return <Banner severity="success" title="Offline fares cache valid" />;
		else return <Banner severity="error" title="Offline fares cache invalid" />;
	};

	const permissionTableFields = [
		{
			label: "",
			render(permission) {
				// eslint-disable-next-line no-unused-vars
				const [key, value] = permission;
				return <String str={value} />;
			}
		},
		{
			label: "Permissions",
			render(permission) {
				// eslint-disable-next-line no-unused-vars
				const [key, value] = permission;
				return <String str={key} />;
			}
		},
		{
			label: "",
			render(permission) {
				// eslint-disable-next-line no-unused-vars
				const [key, value] = permission;
				return <String
					str={props.hasPermission(value) ? "Yes" : "No"}
					style={
						{
							color: "white",
							backgroundColor: (props.hasPermission(value) ? "green" : "red"),
							padding: "0.25em 0.75em",
							textAlign: "center"
						}
					}
				/>;
			}
		}
	];

	return (
		<>
			{!props.AlwaysOffline && renderTicketCacheStatus()}
			{props.AlwaysOffline && <Banner severity="info" title="Device Always Offline" str="This device is set to Offline Sync. Transactions will always run on-device and queue for upload." />}

			<DeviceSettingsView
				appSettingBlocks={
					[
						{
							label: "Pending Uploads",
							dataTableDefinition: uploadQueueTableDataDefinition
						},
						{
							label: "HOPS Gateway",
							dataTableDefinition: gatewayTableDataDefinition
						}
					]
				}
				deviceSettings={posTableDataDefinition}/>

			<Hidden hidden={true}>
				<Flex alignItems="flex-start" px={1}>
					<String
						bold={true}
						str="Your Permissions"/>
					<Table
						fields={permissionTableFields}
						items={Object.entries(Permissions) || []} />
				</Flex>
			</Hidden>

			<Flex alignItems="flex-start" gap={2} px={1}>
				{/* This needs to be a standard anchor because <Link/> doesn't support deep links */}
				<a href="ms-windows-store://pdp?hl=en-gb&gl=gb&productid=9p7bp5vnwkx5">Microsoft Quick Assist</a>
			</Flex>
		</>
	);

};

export default withAuthUser(withMobile(withOrders(withReducedFunctionality(withSettings(SettingsView)))));
