import {Navigator} from "@hps/hops-react";
import {OrderStatuses} from "@hps/hops-sdk-js";
import {Chip} from "@mui/material";

import scss from "./OrderStatusChip.module.scss";

const OrderStatusChip = props => {

	let orderStatusClassName = scss.unknown;

	const status = (props.Status?.Id || props.Status);

	switch (status) {
		case OrderStatuses.Cancelled:
			orderStatusClassName = scss.cancelled;
			break;

		case OrderStatuses.Complete:
			orderStatusClassName = scss.definite;
			break;

		case OrderStatuses.Provisional:
			orderStatusClassName = scss.provisional;
			break;

		case OrderStatuses.Rejected:
			orderStatusClassName = scss.rejected;
			break;

		default:
			orderStatusClassName = scss.unknown;
	}

	const handleClick = () => {
		Navigator.navigate(props.uri);
	};

	return (
		<>
			<Chip
				avatar={<div className={`${scss.chip} ${orderStatusClassName}`}></div>}
				label={`L${props.Id}`}
				onClick={props.uri && handleClick}
			/>
		</>
	);

};

export default OrderStatusChip;
