import {Banner, Button} from "@hps/hops-react";
import React from "react";

import dOnlineApp from "Dispatchers/dOnlineApp";
import withReducedFunctionality from "Hoc/withReducedFunctionality";

import scss from "./ConnectivityBanner.module.scss";

/**
 * Connectivity warning banner. Will show itself if there
 * is a mismatch between the App's offline status and the
 * resolved offline state (based on time checks).
 * 
 * This used to be a scheduled task, but the banner gives more flexibility
 * to the user to ignore it, and the banner will disappear if the connectivity
 * comes back while the POS is unattended.
 * 
 * @package HOPS
 * @subpackage Ui
 * @copyright Heritage Operations Processing Limited
 */
const ConnectivityBanner = props => {

	const AlertProps = {className: scss.root};

	/**
	 * Handle the action button on the banner
	 * @param {boolean} online 
	 */
	const handleBannerAction = online => {
		dOnlineApp(online);
	};


	/**
	 * Render the action button
	 */
	const renderConnectivityAction = () => {
		return (
			<Button
				color="inherit"
				label={props.OfflineNetwork === true ? "Work Offline" : "Reconnect"}
				onClick={handleBannerAction}
				size="small"
				value={!(props.OfflineNetwork)}
				variant="outlined" />
		);
	};


	/**
	 * Render the banner
	 */
	return (
		<>
			{props.ConnectivityChanged && <Banner
				action={renderConnectivityAction()}
				AlertProps={AlertProps}
				gap={0}
				severity={props.OfflineNetwork === true ? "error" : "success"}
				str={props.OfflineNetwork === true ? "There was a problem connecting to the HOPS Server, please retry your last action. If the connection remains unavailable select 'Work Offline' to use reduced functionality mode." : "The connection to the HOPS server is available again. Complete the current transaction then select 'Reconnect' to exit reduced functionality mode."}
				title={props.OfflineNetwork === true ? "Network Unavailable" : "Network Available"}
			/>}
		</>
	);

};


export default withReducedFunctionality(ConnectivityBanner);
