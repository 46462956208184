import {withRegistration} from "@hps/hops-react";
import {useCallback} from "react";
import {connect} from "react-redux";

const withAuthUser = Component => connect(({AuthUser}) => ({AuthUser}))(withRegistration(
	props => {

		const user = ((props.AuthUser && props.Registration?.Users?.find(u => (u.Id === props.AuthUser.Id))) || null);

		/**
		 * Check whether the current authentication context has a given permission.
		 *
		 * @param {Integer} pid
		 * @param {?Integer} pvar optional
		 * @return {Boolean}
		 */
		const hasPermission = useCallback((pid, pvar=0) => {

			const vars = (user?.Permissions?.[pid] || []);
			if (pvar === -1) pvar = null;

			/** 0 = must have 0 as the ONLY variable */
			if (pvar === 0) {
				return ((vars.length === 1) && (vars[0] === 0));
			}
			/** null (-1) = must have null (-1) or any non-0 variable */
			else if (pvar === null) {
				return (vars.includes(null) || !!vars.find(v => (v > 0)));
			}
			/** variable = must have null (-1) or the specific variable */
			else {
				return (vars.includes(null) || vars.includes(pvar));
			}

		}, [user]);

		return (
			<Component
				{...props}
				AuthUser={user}
				authed={!!user?.Id}
				hasPermission={hasPermission}
				permissions={user?.Permissions} />
		);

	}
));

export default withAuthUser;
