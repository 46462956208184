import {Flex, String} from "@hps/hops-react";
import React from "react";

/**
 * Login view "no users" state
 * 
 * @package HOPS
 * @subpackage Login
 * @copyright Heritage Operations Processing Limited
 */
const LoginViewNoUsers = () => {

	return (
		<Flex alignItems="center">
			<String
				centre={true}
				color="textSecondary"
				gap={0.5}
				str={[
					"Welcome to HOPS Point of Sale.",
					"There are no registered users to display.",
					"Users need to be assigned a PIN code and Permission 465 within HOPS to use this system."
				]} />
		</Flex>
	);

};

export default LoginViewNoUsers;
