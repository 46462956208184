import {Flex, String} from "@hps/hops-react";
import {PriceUtils} from "@hps/hops-sdk-js";
import React from "react";

import InventoryGridItemBase from "Inventory/InventoryGridItemBase.js";

import ClockIcon from "@mui/icons-material/AccessTime.js";

import scss from "./InventoryGridItemSession.module.scss";

/**
 * Inventory grid item
 *
 * An item displayed in an inventory grid.
 *
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const InventoryGridItemSession = props => {

	const priceText = (props.priceTextOverride || ((props.price !== null) ? PriceUtils.getDisplayStringIntl(props.price) : ((props.price !== undefined) ? (props.customPriceText || "-") : undefined)));
	const unavailable = (props.soldOut || props.skuUnavailable);
	const skuUnavailableMessage = (props.skuUnavailable ? (props.skuUnavailableMessage || "Not Available") : undefined);
	const soldOutMessage = (props.soldOut ? "Sold Out" : undefined);
	const unavailableMessage = (soldOutMessage || skuUnavailableMessage);
	const priceLabel = ((!props.alwaysShowPriceText ? unavailableMessage : priceText) || priceText);

	/**
	 * Adding to basket.
	 *
	 * @param {Integer} price
	 * @return {void}
	 */
	const handleAddToBasket = price => {
		props.onAddToBasket?.(props.item, price);
	};


	/**
	 * Render price.
	 * 
	 * @return {ReactNode}
	 */
	const renderSessionTime = () => <Flex alignItems="center" columnar={true} justifyContent="space-between" py={0.5} gap={0.5}>
		<ClockIcon sx={{fontSize: 18}} />
		<String
			classNameTypography={scss.sessionTime}
			bold={true}
			str={props.label} />
	</Flex>;


	return <InventoryGridItemBase
		accentColour={props.accentColour}
		disabled={(unavailable || props.disabled || props.loading)}
		horizontal={false}
		mediaElement={renderSessionTime()}
		onAddToBasket={handleAddToBasket}
		xs={12}
		sm={12}
		md={6}
		lg={6}
		xl={4}>

		{/* Price Text */}
		<String
			gap={0}
			color={(!unavailable ? "textSecondary" : "error")}
			str={((unavailable && props.alwaysShowPriceText) ? unavailableMessage : priceLabel)}
			variant="body1" />

	</InventoryGridItemBase>;
};

export default InventoryGridItemSession;
