import {Card, Flex} from "@hps/hops-react";
import React, {useState} from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";

import scss from "./UiClock.module.scss";

/**
 * Clock component
 *
 * @package HOPS
 * @subpackage Ui
 * @copyright Heritage Operations Processing Limited
 */
const UiClock = () => {

	const time = new Date().toLocaleTimeString("en-GB");

	const [currentTime, setCurrentTime] = useState(time);

	const UpdateTime = () => {
		const time = new Date().toLocaleTimeString("en-GB");
		setCurrentTime(time);
	};

	setInterval(UpdateTime, 1000);

	return (
		<Flex>
			<Card
				className={scss.clock}
				classNameContentArea={scss.clockContent}
				px={0}
				py={0}
				outlined={true}>
				<Flex alignItems="center" columnar={true}>
					<AccessTimeIcon />
					{currentTime}
				</Flex>
			</Card>
		</Flex>
	);

};


export default UiClock;
