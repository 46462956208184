import {Banner, Button, Flex, Hidden, Select, String, useData} from "@hps/hops-react";
import {memo, useCallback, useEffect, useMemo, useState} from "react";

import withReducedFunctionality from "Hoc/withReducedFunctionality";
import StripeService from "Services/StripeService.js";

import SyncIcon from "@mui/icons-material/Refresh";

/**
 * A drop-down box for choosing a Stripe Terminal reader.
 */
const StripeTerminalPicker = memo(props => {

	const {disabled, label, name, onChange, ReducedFunctionality, stripeLocation, value} = props;

	const [stripeStatus, setStripeStatus] = useState();

	const state = useData(useCallback(async () => {

		try {

			// Railway isn't configured to use Stripe
			if (stripeLocation === null) {
				setStripeStatus("Your organisation isn't configured with a Stripe Terminal location.");
				return null;
			}

			const stripeService = new StripeService(
				e => (setStripeStatus(e)), // Handle Stripe Provider error messages
				p => (setStripeStatus(p)), // Handle Stripe Provider progress updates
				stripeLocation,
				value,
				ReducedFunctionality
			);

			const readers = await stripeService.discoverReaders();

			setStripeStatus("Ready.");
			return readers;
		}
		catch (ex) {
			setStripeStatus(StripeService.resolveErrorCode(ex)); // Capture the error message
			throw new Error(ex); // Throw the same error again to put the component in an error state
		}

	}, [ReducedFunctionality, stripeLocation, value]));


	const handleClear = () => {
		props.onClear?.();
	};

	const stripeTerminalOptions = useMemo(() => {
		return (state.data || []).map(terminal => {
			return {
				id: terminal.serial_number,
				label: terminal.label,
				value: terminal.serial_number
			};
		});
	}, [state]);


	useEffect(() => {
		if (state.data && stripeTerminalOptions?.length) {
			const terminalIds = stripeTerminalOptions.map(o => o.id);
			if (value && !terminalIds.includes(value)) {
				onChange(null, name);
			}
		}
	}, [name, onChange, stripeTerminalOptions, state.data, value]);


	return (
		<>
			<Flex columnar={true}>
				<Select
					disabled={disabled}
					label={(label || "(pick)")}
					loading={state.loading}
					loadingError={!!state.error}
					name={name}
					onChange={onChange}
					options={stripeTerminalOptions}
					value={value} />
				<Button
					disabled={disabled || state.loading}
					label="Refresh"
					onClick={state.fetch}
					startIcon={SyncIcon} />
				<Button
					disabled={disabled || state.loading || !value}
					label="Clear"
					onClick={handleClear}
					variant="text" />
			</Flex>
			<Hidden hidden={!ReducedFunctionality}>
				<Banner
					severity="warning" title="Stripe Terminal will attempt to use a backup server">
							Connecting to the backup server requires Internet access. You won't<br/>
							be able to take a payment if the connectivity issue is with this device.
				</Banner>
			</Hidden>
			<String
				color={state.error ? "error" : undefined}
				str={stripeStatus}
				variant="subtitle2" />
		</>
	);


});

export default withReducedFunctionality(StripeTerminalPicker);
