import {Dialog, Flex, SnackbarService, String, withRegistration} from "@hps/hops-react";
import {CheckoutDiscountService} from "@hps/hops-sdk-js";
import {useCallback, useEffect, useRef, useState} from "react";

import DiscountCodeInput from "Components/DiscountCodeInput.js";
import dBasketDiscounts from "Dispatchers/dBasketDiscounts.js";
import withBasket from "Hoc/withBasket.js";

import RegistrationDiscountShortcutsList from "./Discounts/RegistrationDiscountShortcutsList.js";

export default withBasket(withRegistration(props => {

	const {
		BasketDiscounts,
		BasketIdentity,
		Registration,
		onClose,
		open
	} = props;

	const discountCodeInputRef = useRef();

	const [discountCode, setDiscountCode] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);


	/**
	 * Handle a manually typed discount code.
	 * Force it to uppercase (HOPS backend only generates uppercase codes)
	 * 
	 * @return {void}
	 */
	const handleChangeDiscountCode = value => {

		setDiscountCode(value?.toUpperCase());

	};


	/**
	 * Submitting a discount code.
	 * 
	 * @async
	 * @return {void}
	 */
	const handleSubmitDiscount = useCallback(async code => {

		/**
		 * We mustn't have already applied this discount code
		 */
		if (BasketDiscounts.find(d => (d.Discount.Code === code))) {
			SnackbarService.snack("The discount code has already been applied.", "error");
			return false;
		}

		try {

			/**
			 * Make the API call
			 */
			const appliedBasketDiscounts = await CheckoutDiscountService.applyDiscountCode(
				BasketIdentity.Id,
				code,
				Registration.Org.Id
			);

			/**
			 * Update our local state with the discounts now in the basket
			 */
			dBasketDiscounts(appliedBasketDiscounts);

			/**
			 * Display a snackbar to inform the user what we've done
			 */
			if (appliedBasketDiscounts.find(d => (d.Discount.Code === code))) {
				SnackbarService.snack("Discount applied successfully.", "success");
				return true;
			}
			else SnackbarService.snack("The discount cannot be applied to any items in the basket.");

		}
		catch (e) {
			if (e?.response?.status === 404) {
				SnackbarService.snack("Invalid discount code.", "error");
			}
			else if (e?.response?.status === 410) {
				SnackbarService.snack("The discount code has expired.", "error");
			}
			else SnackbarService.snack(e);
		}

		return false;

	}, [BasketDiscounts, BasketIdentity, Registration]);


	/**
	 * Submitting.
	 *
	 * @async
	 * @return {void}
	 */
	const handleSubmit = useCallback(async e => {

		e?.preventDefault?.();

		/** We must have an input! */
		if (!discountCode) {
			SnackbarService.snack("Please enter a discount code.", "error");
			return;
		}

		/** Check the validity of the input */
		if (!discountCodeInputRef?.current?.reportValidity?.()) {
			return;
		}

		setIsSubmitting(true);

		/** Apply the discount now */
		const success = await handleSubmitDiscount(discountCode);

		setIsSubmitting(false);
		if (success) onClose();

	}, [discountCode, handleSubmitDiscount, onClose]);


	/**
	 * Discount code shortcut selected.
	 *
	 * @async
	 * @return {void}
	 */
	const handleSelectDiscountShortcut = useCallback(async code => {

		setIsSubmitting(true);
		const success = await handleSubmitDiscount(code);

		setIsSubmitting(false);
		if (success) onClose();

	}, [handleSubmitDiscount, onClose]);


	/**
	 * Reset state on visibility change
	 */
	useEffect(() => {
		if (open) {
			handleChangeDiscountCode(null);
		}
	}, [open]);


	/**
	 * Render!
	 */
	return (
		<Dialog
			loading={isSubmitting}
			onClose={onClose}
			onOk={handleSubmit}
			open={open}
			title="Redeem Discount">
			<form onSubmit={handleSubmit}>
				<Flex gap={2}>

					<Flex>
						<String
							bold={true}
							str="Discount Code" />
						<DiscountCodeInput
							disabled={isSubmitting}
							fullWidth={true}
							inputRef={discountCodeInputRef}
							onChange={handleChangeDiscountCode}
							onEnter={handleSubmit}
							placeholder="DISCOUNT"
							value={discountCode} />
						<RegistrationDiscountShortcutsList
							disabled={isSubmitting}
							onSelect={handleSelectDiscountShortcut} />
					</Flex>
				</Flex>
			</form>
		</Dialog>
	);

}));
