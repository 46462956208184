import {Flex, String} from "@hps/hops-react";
import React from "react";

import Strings from "./InventoryNoFeaturesError.strings.json";

const InventoryNoFeaturesError = () => {
	return (
		<Flex>
			<String
				bold={true}
				str={Strings.label}
				variant="h6" />
			<String
				color="textSecondary"
				gap={0.5}
				str={Strings.body} />
		</Flex>
	);
};

export default InventoryNoFeaturesError;
