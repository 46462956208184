import {Store} from "@hps/hops-react";
import {Api, CheckoutBasketPayment, PosCheckoutService} from "@hps/hops-sdk-js";

import dOrderSynced from "Dispatchers/dOrderSynced";
import dOrderSyncing from "Dispatchers/dOrderSyncing";

/**
 * Order service
 *
 * Wraps the HOPS Order APIs.
 *
 * @package HOPS
 * @subpackage Services
 * @copyright Heritage Operations Processing Limited
 */
class OrderService {


	/**
	 * Add a comms entry for an order
	 *
	 * @param {Integer} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static addCommsLog(OrderIdentity, UserId, CommsLogEntry) {
		return Api.call({
			url: `/api/pos/orders/${OrderIdentity}/commslog`,
			method: "POST",
			data: {
				UserId,
				CommsLogEntry
			}
		}).then(({data}) => data);
	}


	/**
	 * Get an order's details by an order ID (or UUID).
	 *
	 * @param {Integer|String} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static getOrder(OrderIdentity) {
		return Api.call({url: `/api/pos/orders/${OrderIdentity}`}).then(({data}) => data);
	}


	/**
	 * Get an order's details by an item ID (or UUID).
	 *
	 * @param {Integer|String} ItemIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static getOrderByItem(ItemIdentity) {
		return Api.call({url: `/api/pos/orders/items/${ItemIdentity}/order`}).then(({data}) => data);
	}


	/**
	 * Record payments against an order
	 * 
	 * @param {Integer|String} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static recordPayments(OrderIdentity, payments) {
		return Api.call({
			url: `/api/pos/orders/${OrderIdentity}/payments`,
			method: "POST",
			data: {
				Payments: payments.map(p => {
					return {
						Uuid: p.Uuid,
						Moto: p.Moto,
						PaymentMethod: p.PaymentMethod?.Id,
						PaymentPed: p.PaymentPed,
						PaymentProcessor: p.PaymentProcessor,
						PaymentProcessorData: CheckoutBasketPayment.getPaymentApiData(p),
						PaymentType: p.PaymentType,
						Value: p.Value
					};
				})
			}
		}).then(({data}) => data);
	}

	/**
	 * Get an array of orders matching a search query
	 *
	 * @param {object} an object with search paramater values
	 * @return {Promise} Resolves with the API's response data
	 */
	static search(params) {
		return Api.call({
			url: `/api/pos/orders/search`,
			method: "GET",
			params
		}).then(({data}) => data);
	}


	/**
	 * Get an order's details by an item ID (or UUID).
	 *
	 * @param {Integer|String} ItemIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static sendEmailConfirmation(OrderIdentity, BillingEmail = null) {
		return Api.call({
			url: `/api/pos/orders/${OrderIdentity}/emailconfirmation`,
			method: "POST",
			data: {BillingEmail}
		}).then(({data}) => data);
	}


	/**
	 * Attempt to sync the queue.
	 * Lifted and shifted from Time Register.
	 *
	 * This should only be attempted if the device is online.
	 *
	 * @return {void}
	 */
	static async sync() {

		// We'll reset the queue state to this later
		const pending = [];

		/*
		 * We do NOT want multiple concurrent syncs!
		 */
		if (this.syncing) return;
		else dOrderSyncing();


		/*
		 * Sync each order
		 * We sync individually to avoid excessive HOPS load
		 */
		for (const order of this.queue) {
			if (!order.RetryCount || order.RetryCount < 10) {
				await PosCheckoutService.checkout(order).catch(() => {
					const retryOrder = {...order, RetryCount: order.RetryCount ? order.RetryCount + 1 : 1};
					pending.push(retryOrder);
				});
			}
			else {
				pending.push(order); // Just re-queue the order and ignore it, warning displayed in upload queue.
			}
		}

		// Report the sync
		dOrderSynced(pending);

	}


	/**
	 * Update customer details for an order
	 *
	 * @param {Integer} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static updateCustomerDetails(OrderIdentity, BillingCustomerDetails, DeliveryCustomerDetails = null) {
		return Api.call({
			url: `/api/pos/orders/${OrderIdentity}/customerdetails`,
			method: "POST",
			data: {
				BillingCustomerDetails,
				DeliveryCustomerDetails
			}
		}).then(({data}) => data);
	}


	/**
	 * Refund an item by quantity within an order
	 * (Refund item quantities can only go down)
	 *
	 * @param {Integer|String} OrderIdentity
	 * @return {Promise} Resolves with the API's response data
	 */
	static async refundOrderItemQuantity(ItemIdentity, RefundQuantity) {
		return await Api.call({
			url: `/api/pos/orders/items/${ItemIdentity}/refund`,
			method: "POST",
			data: {
				RefundQuantity
			}
		}).then(({data}) => data);
	}


	/**
	 * Get the sync queue contents.
	 *
	 * @return {Array}
	 */
	static get queue() {
		return Store.getState().Orders?.UploadQueue;
	}


	/**
	 * Get whether we're currently syncing.
	 *
	 * @return {Boolean}
	 */
	static get syncing() {
		return Store.getState().Orders?.Syncing;
	}


}

export default OrderService;
