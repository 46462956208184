import {useCallback, useMemo} from "react";
import {useSelector} from "react-redux";

import dGateway from "Dispatchers/dGateway.js";
import GatewayService from "Gateway/GatewayService.js";

/**
 * Hook to utilise the Gateway integration
 * 
 * @package HOPS
 * @subpackage Hooks
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const useGateway = () => {

	const gatewayState = useSelector(store => store.Gateway);


	const checkConnectivity = useCallback(async () => {
		if (gatewayState.Active) {
			try {
				await GatewayService.checkConnectivity();
				return true;
			}
			catch (e) {
				return false;
			}
		}
		else return false;
	}, [gatewayState]);


	const updateConfig = useCallback((value, prop) => {
		dGateway({
			...gatewayState,
			[prop]: value
		});
	}, [gatewayState]);


	return useMemo(() => {
		return {
			...gatewayState,
			checkConnectivity,
			updateConfig
		};
	}, [gatewayState, checkConnectivity, updateConfig]);

};

export default useGateway;
