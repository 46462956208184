import {Banner, Link} from "@hps/hops-react";
import moment from "moment";
import React from "react";

import dTicketsSelectionMerge from "Dispatchers/dTicketsSelectionMerge.js";

/**
 * Advance ticket selection warning banner
 *
 * @return {ReactNode}
 */
const TicketTravelInventoryResultsAdvanceBanner = () => {
	return (
		<Banner
			title="Advance Ticket - Date is set to a future date!"
			severity="warning">
			<Link
				label="Reset to today"
				onClick={() => dTicketsSelectionMerge({Date: (new moment()).format("YYYY-MM-DD")})}
				variant="outlined" />
		</Banner>
	);
};

export default TicketTravelInventoryResultsAdvanceBanner;
