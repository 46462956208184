import {History, HOPSApplication, UnhandledErrorView} from "@hps/hops-react";
import {DeviceTypes} from "@hps/hops-sdk-js";
import {Api} from "@hps/hops-sdk-js";
import * as Sentry from "@sentry/react";
import React, {useEffect} from "react";
import {Router} from "react-router-dom";

import Features from "App/Features.js";
import Tasks from "App/Tasks.js";
import {Detector} from "Components/DetectOffline";
import ConnectivityBanner from "Ui/Connectivity/ConnectivityBanner";

import Main from "./Main";
import {addAsyncReducers} from "./Reducer.js";


/**
 * App entrypoint
 *
 * Renders the root-level application component.
 *
 * @package HOPS
 * @subpackage App
 * @copyright Heritage Operations Processing Limited
 */
const App = props => {

	useEffect(() => {
		// Add our local reducers to the Redux store
		addAsyncReducers();
	}, []);

	return (
		<Sentry.ErrorBoundary>
			<Detector polling={{enabled: true, url: `${Api.baseURL}/api/pos/devices/heartbeat`}} />
			<Router history={History}>
				<HOPSApplication deviceType={DeviceTypes.POS} features={Features} tasks={Tasks}>
					<Sentry.ErrorBoundary fallback={<UnhandledErrorView {...props} />}>
						<Main />
					</Sentry.ErrorBoundary>
					<ConnectivityBanner />
				</HOPSApplication>
			</Router>
		</Sentry.ErrorBoundary>
	);

};

export default App;
