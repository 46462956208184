import {Button, Flex, Navigator, String, TextField, TouchKeyboard} from "@hps/hops-react";
import bcrypt from "bcryptjs";
import React, {useState} from "react";

import dAuthUser from "Dispatchers/dAuthUser.js";
import useQuery from "Hooks/useQuery";

import scss from "./LoginPinEntry.module.scss";


/**
 * Login PIN entry screen
 * 
 * @package HOPS
 * @subpackage Login
 * @copyright Heritage Operations Processing Limited
 */
const LoginPinEntry = props => {

	const inputRef = React.createRef();
	const [pin, setPin] = useState(null);
	const [error, setError] = useState(false);

	const query = useQuery();
	const redirect = query.get("redirect");

	const selectInput = () => inputRef?.current?.select?.();
	const inputProps = {inputMode: "numeric"};

	const handleChange = pin => {
		setPin(pin);
		setError(false);
	};

	const handleSubmit = e => {

		e?.preventDefault?.();

		if (bcrypt.compareSync(pin, props.user.Pin)) {
			dAuthUser(props.user);
			if (redirect) Navigator.navigate(redirect);
		}
		else setError(true).then(selectInput);

	};

	return (
		<>
			<String
				centre={true}
				gap={0}
				str={[`Hello, ${props.user.Account.Fname}.`, `Enter your PIN number to login.`]} />
			<form onSubmit={handleSubmit}>
				<Flex>
					<TextField
						autoFocus={true}
						error={error}
						errorText="PIN code incorrect"
						inputProps={inputProps}
						inputRef={inputRef}
						label="PIN Code"
						onChange={handleChange}
						onEnter={handleSubmit}
						pattern="^[0-9]+$"
						placeholderLabel={true}
						required={true}
						size="medium"
						touchKeyboardIcon={false}
						type="password"
						value={pin} />
					<TouchKeyboard
						fontSize="2rem"
						inline={true}
						numeric={true}
						onChange={handleChange}
						value={pin} />
					<Flex
						columnar={true}
						justifyContent="space-between">
						<Button
							className={scss.loginButton}
							label="Login"
							size="large"
							type="submit"
							variant="contained" />
						<Button
							label="Cancel"
							onClick={props.onCancel}
							size="large" />
					</Flex>
				</Flex>
			</form>
		</>
	);

};

export default LoginPinEntry;
