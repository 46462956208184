import {Button, Dialog, Flex, String, Ul} from "@hps/hops-react";
import {CheckoutBasketItem, ItemClaimErrorCodes} from "@hps/hops-sdk-js";
import React, {useEffect, useState} from "react";

import withBasketClaimData from "Hoc/withBasketClaimData.js";

/**
 * Dialog displaying details of basket claim errors
 *
 * These occur when the API declines to add an item to a basket.
 *
 * This is a connected component controlled by the "basket claim data" state.
 * 
 * @package HOPS
 * @subpackage Basket
 * @copyright Heritage Operations Processing Limited
 */
const BasketClaimErrorDialog = props => {

	/**
	 * Open?
	 *
	 * This is used because if we accessed the basket claim data
	 * directly to set the open state, we'd logically need to 
	 * clear that data on close, which could affect what we 
	 * render and so cause a flash of changing data as the 
	 * dialog closes. Hence we don't mutate the claim data 
	 * at all and instead synchronise this field with it.
	 *
	 * @type {Boolean}
	 */
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setOpen(!!props.BasketClaimData?.Result?.Errors?.length);
	}, [props.BasketClaimData]);

	const handleClose = () => setOpen(false);

	const addedCount = props.BasketClaimData?.Result?.Claims?.length;
	const errorCount = props.BasketClaimData?.Result?.Errors?.length;
	const claimCount = (addedCount + errorCount);

	const title = ((errorCount === claimCount) ? "Unable to add to basket" : "Some items couldn't be added to the basket");

	const renderErrors = () => {
		return (
			<Ul>
				{
					props.BasketClaimData?.Result?.Errors?.map((error, key) => {

						const item = props.BasketClaimData.Items?.find(i => (i?.Uuid === error?.Uuid));

						return (
							<li key={key}>
								<Flex gap={0.25} inline={true}>
									<String
										bold={true}
										noFlex={true}
										str={`${CheckoutBasketItem.getUiLabel(item)} (${CheckoutBasketItem.getUiSubtext(item)})`} />
									<String
										noFlex={true}
										str={ItemClaimErrorCodes.getMessage(error?.Code)} />
								</Flex>
							</li>
						);
					})
				}
			</Ul>
		);
	};

	return (
		<Dialog
			noTransition={true}
			onClose={handleClose}
			noCloseButton={true}
			open={open}
			width="xs">
			<Flex>
				<String
					bold={true}
					centre={true}
					color="error"
					noFlex={true}
					str={title}
					variant="h6" />
				{(((errorCount === claimCount) && (errorCount === 1)) ? <String centre={true} noFlex={true} str={ItemClaimErrorCodes.getMessage(props.BasketClaimData?.Result?.Errors?.[0]?.Code)} /> : renderErrors())}
				<Button
					label="OK"
					onClick={handleClose}
					size="large"
					variant="contained" />
			</Flex>
		</Dialog>
	);

};

export default withBasketClaimData(BasketClaimErrorDialog);
