import {Store} from "@hps/hops-react";

/**
 * Update the properties of an item in the basket.
 *
 * @param {String} item UUID
 * @param {Object} props Properties to merge into the item object
 * @return {void}
 */
const dBasketUpdate = (item, props) => Store.dispatch({type: "basketUpdate", item, props});

export default dBasketUpdate;
