import {Button, Dialog, Flex, Paper, String, TableList, withRegistration, withSnackbars} from "@hps/hops-react";
import {OrderVoucherService, PriceUtils} from "@hps/hops-sdk-js";
import moment from "moment";
import {createRef, useCallback, useEffect, useState} from "react";

import VoucherCodeInput from "Components/VoucherCodeInput.js";

import scss from "./VoucherLookup.module.scss";

export default withRegistration(withSnackbars(props => {

	const {snack, Registration} = props;

	const formRef = createRef();
	const inputRef = createRef();

	const [code, setCode] = useState(null);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [voucher, setVoucher] = useState(null);

	/** Select the input */
	const selectInput = useCallback(() => inputRef?.current?.select?.(), [inputRef]);

	/** Dialog close handler */
	const handleCloseDialog = () => {
		setVoucher(null);
	};

	/** Input change handler */
	const handleInputChange = code => {
		setCode(code);
		setError(false);
		setVoucher(null);
	};

	/** Submission handler */
	const handleSubmit = useCallback(async e => {

		e?.preventDefault?.();

		if (!formRef?.current?.reportValidity?.()) {
			selectInput();
			return;
		}

		setError(false);
		setLoading(true);

		try {

			const voucher = await OrderVoucherService.getVoucherDetails(code.replaceAll("-", ""));

			if (voucher.Org !== Registration.Org.Id) {
				throw new Error("E_INVALID_ORG");
			}

			setVoucher(voucher);
		}
		catch (e) {

			setError(e);

			if ((e?.response?.status === 404) || (e?.message === "E_INVALID_ORG")) {
				snack("Unknown voucher code.", "error");
			}
			else if (e?.response?.status === 410) {
				snack("The voucher has expired or been revoked.", "error");
			}
			else if (e?.response?.status === 429) {
				snack("This device is being rate limited. Please wait a while before retrying.", "error");
			}
			else snack(e);

		}

		setLoading(false);

	}, [code, formRef, selectInput, snack, Registration]);

	/** Select the input after errors */
	useEffect(() => {
		if (error) {
			selectInput();
		}
	}, [error, selectInput]);

	/** Render! */
	return (
		<Paper>
			<Flex>
				<String
					bold={true}
					str="Voucher Lookup" />
				<form
					onSubmit={handleSubmit}
					ref={formRef}>
					<Flex
						className={scss.formContainer}
						columnar={true}>
						<VoucherCodeInput
							disabled={loading}
							fullWidth={true}
							inputRef={inputRef}
							onChange={handleInputChange}
							onEnter={handleSubmit}
							required={true}
							value={code} />
						<Button
							label="Get Details"
							loading={loading}
							type="submit"
							variant="contained" />
					</Flex>
				</form>
				<Dialog
					onClose={handleCloseDialog}
					open={!!voucher}
					title="Voucher Details">
					<TableList
						data={[
							{
								label: "Issued Balance",
								content: PriceUtils.getDisplayStringIntl((voucher?.IssuedBalance || 0))
							},
							{
								label: "Available Balance",
								content: PriceUtils.getDisplayStringIntl((voucher?.AvailableBalance || 0))
							},
							{
								label: "Expiry Date",
								content: (voucher?.ExpiryDate ? (new moment(voucher.ExpiryDate)).format("DD/MM/YYYY") : "-")
							}
						]} />
				</Dialog>
			</Flex>
		</Paper>
	);

}));
