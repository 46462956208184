import {Banner, String} from "@hps/hops-react";
import {memo} from "react";

const SeatReservationEditorErrorDetails = memo(({errorData}) => {

	return (
		<Banner
			title="Error details"
			severity="error">
			{(
				Array.isArray(errorData) ?
					errorData?.map((e, key) => {
						return (
							<String
								key={key}
								noFlex={true}
								str={`- ${e?.Name} (Seat ${e?.Seat})`} />
						);
					}) :
					<String
						noFlex={true}
						str="(Unknown)" />
			)}
		</Banner>
	);

});

export default SeatReservationEditorErrorDetails;
