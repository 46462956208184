import {Banner} from "@hps/hops-react";
import React from "react";

/**
 * No cached fares warning banner
 *
 * @return {ReactNode}
 */
const TicketTravelInventoryResultsNoFaresCacheBanner = () => {
	return (
		<Banner
			title="Offline Ticket Sales Unavailable"
			severity="error">
				This device is offline, and there is no valid fares cache available for the current date.
		</Banner>
	);
};

export default TicketTravelInventoryResultsNoFaresCacheBanner;
