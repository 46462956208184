import {Button, Flex, Hidden, Paper, SnackbarService, String, withMobile, withRegistration} from "@hps/hops-react";
import {CheckoutBasketPayment, OrderVoucherService, PaymentProcessorsEnum, PaymentTypes, PriceUtils} from "@hps/hops-sdk-js";
import moment from "moment";
import {useCallback, useState} from "react";

import VoucherCodeInput from "Components/VoucherCodeInput";
import dPaymentsInProgress from "Dispatchers/dPaymentsInProgress";
import dPaymentType from "Dispatchers/dPaymentType";
import withBasket from "Hoc/withBasket";

import PaymentProcessorBase from "../PaymentProcessorBase";

import scss from "./PaymentProcessorOrgVoucher.module.scss";


const SHORT_DATE_FORMAT = "DD/MM/YYYY";

/**
 * Process a payment made with an organisation's gift vouchers.
 * 
 * @param {object} onPaymentFailure callback for payment successful
 * @param {object} onPaymentSuccess callback for payment failure
 * @param {array} Payments a list of payments already in the basket, to check the voucher hasn't been used already.
 * @param {number} RequestedAmount in the currency's smallest minor unit (i.e. British Pennies in the UK)
 * @param {object} Registration the POS registration to check the voucher belongs to this organisation.
 * @returns JSX
 */
const PaymentProcessorOrgVoucher = ({isMobile, onPaymentFailure, onPaymentSuccess, Payments, Registration, RequestedAmount}) => {

	const [tenderedAmount, setTenderedAmount] = useState(0);
	const [voucherCode, setVoucherCode] = useState("");
	const [voucher, setVoucher] = useState(null);

	/**
	 * Submitting a voucher code.
	 *
	 * @async
	 * @return {Boolean}
	 */
	const handleSubmitVoucher = useCallback(async code => {

		/**
		 * Get the voucher's code without hyphens etc.
		 */
		code = OrderVoucherService.transformVoucherCodeInputToApiCode(code);

		/**
		 * Make sure we've not already applied this voucher
		 */
		if (Payments.find(p => (p.PaymentProcessorData.Code === code))) {
			SnackbarService.snack("The voucher code has already been applied.");
			return false;
		}

		try {

			/**
			 * Make the API call
			 */
			const voucher = await OrderVoucherService.getVoucherDetails(code);

			/**
			 * Handle the result now
			 */
			if (voucher.Org !== Registration.Org.Id) {
				throw new Error("E_INVALID_VOUCHER");
			}
			else if (voucher.AvailableBalance <= 0) {
				SnackbarService.snack("The voucher has no remaining balance.", "error");
			}
			else {
				setVoucher(voucher);
				setTenderedAmount(Math.min(RequestedAmount, voucher.AvailableBalance));
				return true;
			}

		}
		catch (e) {
			if ((e?.response?.status === 404) || (e?.message === "E_INVALID_VOUCHER")) {
				SnackbarService.snack("Invalid voucher code.", "error");
			}
			else if (e?.response?.status === 410) {
				SnackbarService.snack("The voucher has expired or been revoked.", "error");
			}
			else if (e?.response?.status === 429) {
				SnackbarService.snack("This device is being rate limited. Please wait a while before retrying.", "error");
			}
			else SnackbarService.snack(e);
		}

		return false;

	}, [Payments, Registration, RequestedAmount]);


	const handleCancel = () => {
		return onPaymentFailure({Type: "Voucher", Requested: RequestedAmount, Received: null});
	};


	const handleOK = async () => {

		dPaymentsInProgress(true);

		/*
		 * Pass the result back to the checkout
		 * Requires OrderVoucherCode which identifies the voucher to use
		 * https://gitlab.heron-web.com/hps/hops/-/issues/686#note_70728
		 */
		await onPaymentSuccess([
			CheckoutBasketPayment.construct({
				PaymentProcessor: PaymentProcessorsEnum.HOPSOrderVoucher,
				PaymentProcessorData: voucher,
				PaymentType: PaymentTypes.Voucher,
				TenderedAmount: Number(tenderedAmount),
				Value: Number(tenderedAmount)
			})
		]);

		dPaymentsInProgress(false);
		dPaymentType(null);
	};

	return (
		<Flex className={scss.root}>
			<Hidden hidden={!(voucher === null)}>
				<Flex className={scss.lookupRoot}>
					<String bold={true} str="Gift Voucher Payment" variant="h5" />
					<Paper>
						<Flex>
							<String bold={true} str="Voucher Lookup" />
							<Flex className={scss.lookupContainer} columnar={true}>
								<VoucherCodeInput
									autoFocus={true}
									fullWidth={true}
									onChange={setVoucherCode}
									onEnter={handleSubmitVoucher}
									value={voucherCode} />
								<Button
									label="Get Details"
									onClick={handleSubmitVoucher}
									value={voucherCode}
									variant="contained" />
							</Flex>
							<Button
								disabled={!(voucher === null)}
								label="Cancel"
								onClick={handleCancel} />
						</Flex>
					</Paper>
				</Flex>
			</Hidden>
			<Hidden hidden={voucher === null}>
				<Flex>
					<String bold={true} str="Gift Voucher Payment" variant="h5" />
					<Flex columnar={!(isMobile)} justifyContent="center">
						<Paper classNamePaper={scss.voucherSummary}>
							<Flex alignItems="center">
								<String bold={true} str="Gift Voucher" variant="h6"/>
								<String str={voucher?.Code.replace(/(.{4})(.{4})(.{4})(.{4})/, "$1-$2-$3-$4")} variant="h6"/>
								<Flex columnar={true} justifyContent="space-between">
									<String bold={true} str="Balance at Issue" />
									<String className={scss.voucherDetail} str={PriceUtils.getDisplayStringIntl(voucher?.IssuedBalance)} />
								</Flex>
								<Flex columnar={true} justifyContent="space-between">
									<String bold={true} str="Available" />
									<String className={scss.voucherDetail} str={PriceUtils.getDisplayStringIntl(voucher?.AvailableBalance)} />
								</Flex>
								<Flex columnar={true} justifyContent="space-between">
									<String bold={true} str="Expiry Date" />
									<String className={scss.voucherDetail} str={new moment(voucher?.ExpiryDate).format(SHORT_DATE_FORMAT)} />
								</Flex>
							</Flex>
						</Paper>
						<Paper classNamePaper={scss.voucherSummary}>
							<PaymentProcessorBase
								onCancel={handleCancel}
								onChange={setTenderedAmount}
								onOK={handleOK}
								RequestedAmount={Math.min(RequestedAmount, voucher?.AvailableBalance)}
								Title="Redeem Gift Voucher Balance"
								value={tenderedAmount}>
							</PaymentProcessorBase>
						</Paper>
					</Flex>
				</Flex>
			</Hidden>
		</Flex>
	);
};

export default withBasket(withMobile(withRegistration(PaymentProcessorOrgVoucher)));
