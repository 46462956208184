import {Button, CurrencyInput, Divider, Flex, Link, Section, SnackbarService, String} from "@hps/hops-react";
import {PaymentStatuses, PaymentTypes, PriceUtils} from "@hps/hops-sdk-js";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import moment from "moment";
import {memo, useCallback, useEffect, useState} from "react";

import RefundDialog from "./OrderPaymentRefundDialog.js";

import scss from "./OrderPaymentRefundsTable.module.scss";


const OrderPaymentRefundsTable = memo(({
	amountOwing,
	onRefunded,
	orderVouchers,
	payments,
	linkedOrderId,
	targetOrderId
}) => {

	const [activePayment, setActivePayment] = useState(null);
	const [refundDialogOpen, setRefundDialogOpen] = useState(false);
	const [refundValues, setRefundValues] = useState({});


	const getPaymentOrderVoucher = useCallback(payment => {
		if (payment && (payment?.Type.Id === PaymentTypes.Voucher)) {
			return orderVouchers?.find(({Id}) => (Id === parseInt(payment.ProcessorOutcome.ProcessorTransactionId)));
		}
		else return null;
	}, [orderVouchers]);


	const getPaymentRefundableAmount = useCallback(payment => {

		let refundAmount = payment.RefundableAmount;

		if (payment?.Type.Id === PaymentTypes.Voucher) {
			const voucher = getPaymentOrderVoucher(payment);
			if (voucher) refundAmount = Math.min(refundAmount, (voucher.IssuedBalance - voucher.AvailableBalance));
		}

		return Math.min(refundAmount, amountOwing);

	}, [amountOwing, getPaymentOrderVoucher]);


	const getRefundAmountIntValue = useCallback(payment => {
		return Math.round((parseFloat((refundValues[payment?.Uuid] || 0)) * 100));
	}, [refundValues]);


	const handleOpenRefundDialog = useCallback(payment => {

		const refundAmount = getRefundAmountIntValue(payment);
		const maxRefundAmount = getPaymentRefundableAmount(payment);

		if (!refundAmount) {
			SnackbarService.snack("You must enter an amount to refund.", "error");
			return;
		}
		else if (refundAmount > maxRefundAmount) {
			SnackbarService.snack(`The maximum refund you can issue for this payment is ${PriceUtils.getDisplayString(maxRefundAmount)}.`, "error");
			return;
		}

		setActivePayment(payment);
		setRefundDialogOpen(true);

	}, [getPaymentRefundableAmount, getRefundAmountIntValue]);


	const handleCloseRefundDialog = useCallback(() => {
		setRefundDialogOpen(false);
	}, []);


	const handleChangeRefundValue = useCallback((value, paymentUuid) => {
		setRefundValues({
			...refundValues,
			[paymentUuid]: value
		});
	}, [refundValues]);


	useEffect(() => {

		let amountAllocated = 0;
		const paymentsAllocated = [];
		const refundValues = {};

		while (amountAllocated < amountOwing) {

			const allCandidatePayments = payments.filter(p => {
				return (
					!paymentsAllocated.includes(p.Uuid) &&
					(getPaymentRefundableAmount(p) > 0) &&
					(p.Status.Id === PaymentStatuses.Complete)
				);
			});

			const paymentCandidate = (

				/** Suggest refunding to vouchers first... */
				allCandidatePayments.find(p => {
					return (p.Type.Id === PaymentTypes.Voucher);
				}) ||

				/** ...then suggest refunding to cards... */
				allCandidatePayments.find(p => {
					return (
						(p.Type.Id === PaymentTypes.Card) ||
						(p.Type.Id === PaymentTypes.CardHolderPresent)
					);
				}) ||

				/** ...then whatever is left. */
				allCandidatePayments[0]

			);

			if (paymentCandidate) {

				const refundAmount = Math.min(
					getPaymentRefundableAmount(paymentCandidate),
					(amountOwing - amountAllocated)
				);

				amountAllocated += refundAmount;
				refundValues[paymentCandidate.Uuid] = (refundAmount / 100).toFixed(2);

				paymentsAllocated.push(paymentCandidate.Uuid);

			}
			else break;

		}

		setRefundValues(refundValues);

	}, [amountOwing, getPaymentRefundableAmount, payments]);


	return (
		<Section
			classNamePaper={scss.paper}
			header={
				<Typography
					variant="body2">
					Use these options to refund to the original payment method(s). Ensure the "Amount to Refund" is correct and press "Refund Payment". Where more than one method will be refunded, conduct each part separately.
				</Typography>
			}
			title="Refund to Original Payment Method">
			<Table
				className={scss.table}
				size="small">
				<TableHead>
					<TableRow>
						<TableCell
							style={{width: "12rem"}}>
							<String
								bold={true}
								noWordBreak={true}
								p={true}
								str="Date/Time"
								variant="body2" />
						</TableCell>
						<TableCell>
							<String
								bold={true}
								noWordBreak={true}
								p={true}
								str="Payment Deat"
								variant="body2" />
						</TableCell>
						<TableCell>
							<String
								bold={true}
								noWordBreak={true}
								p={true}
								str="Refund Payment"
								variant="body2" />
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						!payments.length &&
							<TableRow>
								<TableCell
									align="center"
									colSpan={(!linkedOrderId ? 11 : 12)}>
									No payments recorded.
								</TableCell>
							</TableRow>
					}
					{
						payments.map((payment, key) => {

							const orderVoucher = getPaymentOrderVoucher(payment);

							const refundableAmount = getPaymentRefundableAmount(payment);
							const isRefundableAmount = (refundableAmount > 0);

							let isRefundable = (isRefundableAmount && (payment.Status.Id === PaymentStatuses.Complete));

							if (orderVoucher?.Expired || orderVoucher?.Revoked) {
								isRefundable = false;
							}

							return (
								<TableRow
									key={key}>
									<TableCell>
										<String
											noWordBreak={true}
											p={true}
											str={(new moment(payment.Timestamp)).format("DD/MM/YYYY HH:mm:ss")}
											variant="body2" />
										<String color="textSecondary" str={`Ref: ${payment.Id}`} variant="subtitle2" />
										{
											linkedOrderId &&
											<TableCell>
												<String
													noWordBreak={true}
													p={true}
													str={`L${payment.Order}`}
													variant="body2" />
											</TableCell>
										}
									</TableCell>
									<TableCell>
										<Typography
											variant="body2">
											<b>Type:</b> {payment.Type.Label} {((payment.Type.Id === PaymentTypes.Voucher) && <>(<Link uri={`/retail_systems.php?t=9&v=${payment.ProcessorOutcome.ProcessorTransactionId}`}>{payment.ProcessorOutcome.ProcessorTransactionId}</Link>)</>)}<br/>
											<b>Processor:</b> {payment.Processor.Label}<br/>
											<b>Source:</b> {payment.Source.Label}<br/>
											<b>Status:</b> {payment.Status.Label}<br/>
											<br/>
											<Divider />
											<br/>
											<b>Amount Paid:</b> {PriceUtils.getDisplayStringIntl(payment.PaidAmount)}<br/>
											<b>Amount Refunded:</b> {PriceUtils.getDisplayStringIntl(payment.RefundedAmount)}
										</Typography>
									</TableCell>
									<TableCell
										style={{
											verticalAlign: "top"
										}}>
										<Flex
											alignItems="flex-start"
											gap={0.5}
											py={1}>
											{
												isRefundable &&
												<CurrencyInput
													disabled={!isRefundable}
													fullWidth={true}
													helperText={`Max: ${PriceUtils.getDisplayString(Math.max(0, refundableAmount))}`}
													label="Amount to Refund"
													max={(refundableAmount / 100)}
													min={0}
													name={payment.Uuid}
													onChange={handleChangeRefundValue}
													value={refundValues[payment.Uuid]}
													variant="outlined" />
											}
											{
												isRefundable &&
													<Button
														disabled={!isRefundable}
														label="Refund Payment"
														onClick={() => handleOpenRefundDialog(payment)}
														variant="outlined" />
											}
											{
												(isRefundableAmount && (payment.Status.Id !== PaymentStatuses.Complete)) &&
													<String
														color="error"
														gap={0}
														str={[
															"Not currently refundable.",
															"Only Complete payments can be refunded."
														]}
														variant="body2" />
											}
											{
												(orderVoucher?.Expired || orderVoucher?.Revoked) &&
													<String
														color="error"
														gap={0}
														str="Voucher has expired or been revoked."
														variant="body2" />
											}
										</Flex>
									</TableCell>
								</TableRow>
							);

						})
					}
				</TableBody>
			</Table>
			<RefundDialog
				amountOwing={amountOwing}
				open={refundDialogOpen}
				onClose={handleCloseRefundDialog}
				onRefunded={onRefunded}
				orderVoucher={getPaymentOrderVoucher(activePayment)}
				payment={activePayment}
				refundAmount={getRefundAmountIntValue(activePayment)}
				targetOrderId={targetOrderId} />
		</Section>
	);

});

export default OrderPaymentRefundsTable;
