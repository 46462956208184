import {AsyncStatefulComponent, EmptyStateGate, Flex, String} from "@hps/hops-react";
import {CheckoutBasketItem, OrderableTypes, PriceUtils} from "@hps/hops-sdk-js";
import moment from "moment";
import React from "react";

import withInventory from "Hoc/withInventory.js";
import withTickets from "Hoc/withTickets.js";
import InventoryGrid from "Inventory/InventoryGrid.js";
import BasketService from "Services/BasketService.js";

import InventoryGridItemSession from "./InventoryGridItemSession";


/**
 * Ticket session inventory browser session select
 *
 * Displays a set of available sessions that should belong to one product.
 * 
 * @package HOPS
 * @subpackage Inventory
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class TicketSessionInventoryBrowserSessionSelector extends AsyncStatefulComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Session object that's being added to the basket
		 *
		 * @type {Object|null}
		 */
		addingToBasket: null

	};


	/**
	 * Adding a session to the basket.
	 *
	 * @param {Object} session
	 * @return {void}
	 */
	handleAddToBasket = async session => {

		this.setState({addingToBasket: session});

		try {

			const items = [];

			const selectedTicketTypes = this.selectedTicketTypes;
			const fares = this.getApplicableSessionFaresMatchingTicketTypes(session, selectedTicketTypes);

			for (const fare of fares) {

				const fareType = (this.props.Inventory?.Tickets?.FareTypes || []).find(ft => (ft.Id === fare.Type));
				const ticketType = (this.props.Inventory?.Tickets?.Types || []).find(tt => (tt.Id === fare.TicketType));

				items.push(
					CheckoutBasketItem.construct({
						OrderableType: OrderableTypes.TicketSession,
						Item: {
							Session: {
								...session,
								Trains: session.Trains.map(t => this.props.Inventory?.Tickets?.Trains?.find(train => (train.Id === t))),
								Product: this.props.Inventory?.Tickets?.Products?.find(p => (p.Id === session.Product))
							},
							FareId: fare.Id,
							FareType: fareType,
							TicketType: ticketType
						},
						Price: fare.Price,
						Quantity: (this.selectedTicketTypesCounts[fare.TicketType] || 1),
						VatProportion: fare.VatProportion,
						VatRate: fare.VatRate,
						StationeryTemplateId: session.Stationery
					})
				);

			}

			await BasketService.addItems(items);

		}
		catch (e) {
			// ...
		}

		this.setState({addingToBasket: null});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<EmptyStateGate
				emptyMessage="No sessions available"
				isEmpty={!this.props.sessions.length}>
				<Flex gap={2} overflowY="auto" pr={1} width="100%">
					{this.renderSessions()}
				</Flex>
			</EmptyStateGate>
		);
	}


	/**
	 * Render all the available sessions.
	 * 
	 * @return {ReactNode}
	 */
	renderSessions() {

		const ticketTypes = {};

		const sessionsByDate = this.sessionsByDate;
		const selectedTicketTypesIds = this.selectedTicketTypes;
		const selectedTicketTypesCounts = this.selectedTicketTypesCounts;
		const totalTicketQtyWanted = selectedTicketTypesIds.reduce((a, b) => (a + (selectedTicketTypesCounts[b] || 0)), 0);

		const getTicketTypeById = tt => {
			if (!ticketTypes[tt]) {
				ticketTypes[tt] = this.props.Inventory.Tickets.Types.find(t => (t.Id === tt));
			}
			return ticketTypes[tt];
		};

		return Object.keys(sessionsByDate).map((date, key) => {

			const StartDate = new moment(date);

			return (
				<Flex key={key}>
					<String
						bold={true}
						color="primary"
						noFlex={true}
						str={StartDate.format("dddd, D MMMM yyyy")}
						variant="h6" />
					<InventoryGrid>
						{
							sessionsByDate[date].map((session, key) => {

								session.Fares = session.Fares.sort((f1, f2) => {
									return getTicketTypeById(f1.TicketType)?.DisplayOrder > getTicketTypeById(f2.TicketType)?.DisplayOrder ? 1 : -1;
								});

								let skuUnavailableMessage = "";

								const fares = this.getApplicableSessionFaresMatchingTicketTypes(session, selectedTicketTypesIds);

								if ((!fares?.length || (fares?.length !== selectedTicketTypesIds.length))) {
									skuUnavailableMessage = ((totalTicketQtyWanted > 0) ? "No Fares Matching Selection" : "No Tickets Selected");
								}
								else {
									for (const ticketTypeId of Object.keys(selectedTicketTypesCounts)) {

										const ticketTypeCount = selectedTicketTypesCounts[ticketTypeId];

										if (ticketTypeCount > 0) {

											const ticketTypeAvailability = session.Availability[ticketTypeId];

											if ((ticketTypeAvailability !== null) && (ticketTypeCount > ticketTypeAvailability)) {
												const ticketType = this.props.Inventory?.Tickets?.Types?.find(t => (t.Id === parseInt(ticketTypeId)));
												skuUnavailableMessage += `${(ticketTypeAvailability)} Available (${ticketType?.Name})\n`;
											}
										}
									}
								}


								const priceText = fares.filter(f => (f.Type === this.props.TicketsSelection.FareType?.Id)).map(fare => {
									return `${getTicketTypeById(fare.TicketType)?.Name}: ${PriceUtils.getDisplayStringIntl(fare.Price)}`;
								});

								return (
									<InventoryGridItemSession
										alwaysShowPriceText={true}
										key={key}
										item={session}
										label={this.constructor.getSessionLabel(session)}
										loading={(this.state?.addingToBasket?.Id === session.Id)}
										onAddToBasket={this.handleAddToBasket}
										priceTextOverride={priceText}
										skuUnavailable={!!skuUnavailableMessage}
										skuUnavailableMessage={skuUnavailableMessage.trim()} />
								);
							})

						}
					</InventoryGrid>
				</Flex>
			);

		});

	}


	/**
	 * Get the session fares that apply to the current tickets selection and a 
	 * given set of separately selected ticket types (does not consider 
	 * the ticket type selection within the current tickets selection - 
	 * performance optimisation given the context of where this is called).
	 *
	 * @param {Object} session
	 * @param {Array<Integer>} selectedTicketTypes IDs
	 * @return {Array<Object>} Fare objects
	 */
	getApplicableSessionFaresMatchingTicketTypes(session, selectedTicketTypes) {
		return session.Fares.filter(f => {
			return (
				(f.Type === this.props.TicketsSelection.FareType?.Id) &&
				selectedTicketTypes.includes(f.TicketType)
			);
		});
	}


	/**
	 * Get the IDs of the ticket types which the user has selected.
	 *
	 * @return {Array<Integer>}
	 */
	get selectedTicketTypes() {

		const counts = this.selectedTicketTypesCounts;
		const sessionsTicketTypes = this.props.sessions.map(s => s.Fares.map(f => f.TicketType)).flat();

		/**
		 * We only care about the ticket types referenced by our sessions
		 *
		 * Others won't be visible but may have default counts that would 
		 * otherwise count towards our required ticket selection.
		 */
		return Object.keys(counts).map(t => parseInt(t)).filter(t => ((counts[t] > 0) && sessionsTicketTypes.includes(t)));

	}


	/**
	 * Get the selected ticket type counts.
	 *
	 * @return {Object} Ticket type ID => quantity selected
	 */
	get selectedTicketTypesCounts() {
		return (this.props.selectedTicketTypeCounts || this.props.TicketsSelection.TypeCounts);
	}


	/**
	 * Get sessions grouped together by their start date.
	 *
	 * @return {Object} Date (YYYY-MM-DD) => sessions with that start date
	 */
	get sessionsByDate() {

		const sessions = {};

		this.props.sessions.forEach(session => {
			const sessionDate = session.StartDate;
			sessions[sessionDate] = (sessions[sessionDate] || []);
			sessions[sessionDate].push(session);
		});

		return sessions;

	}


	/**
	 * Get the inventory item tile label for a session.
	 *
	 * @param {Object} session
	 * @return {String}
	 */
	static getSessionLabel(session) {

		let label;
		const {StartDate, StartTime, EndTime} = session;

		if (StartTime) {
			label = StartTime.substring(0, 5);

			if (EndTime) {
				label += ` - ${EndTime.substring(0, 5)}`;
			}
		}
		else label = new moment(StartDate).format("D MMMM YYYY");

		return label;

	}

}

export default withInventory(withTickets(TicketSessionInventoryBrowserSessionSelector));
