import {Button, Flex} from "@hps/hops-react";

export default props => {

	return (
		<Flex columnar={true} flexWrap={true} gap={0.5}>
			{
				props.shortcuts.map((shortcut, key) => (
					<div
						key={key}
						style={{color: `#${shortcut.Colour}`}}>
						<Button
							color="inherit"
							disabled={props.disabled}
							label={shortcut.Code}
							onClick={props.onSelect}
							size="large"
							value={shortcut.Code} />
					</div>
				))
			}
		</Flex>
	);

};
