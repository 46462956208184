import {withRegistration} from "@hps/hops-react";

import DiscountShortcutsList from "./DiscountShortcutsList.js";


export default withRegistration(props => {

	const shortcuts = (props.Registration?.DiscountShortcuts || []);
	if (!shortcuts.length) return null;

	return (
		<DiscountShortcutsList
			disabled={props.disabled}
			onSelect={props.onSelect}
			shortcuts={shortcuts} />
	);

});
