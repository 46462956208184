import FaresCacheTask from "Tasks/FaresCacheTask.js";
import UploadOrdersTask from "Tasks/UploadOrdersTask.js";

const Tasks = [
	{
		id: "FaresCache",
		task: FaresCacheTask,
		frequency: 60 * 60, // Every hour
		requireHeartbeat: true,
		requireOnline: true
	},
	{
		id: "UploadOrders",
		task: UploadOrdersTask,
		frequency: 1 * 60, // Every minute
		requireRegistration: true
	}
];

export default Tasks;
