import {Store} from "@hps/hops-react";

/**
 * Remove items from the basket.
 *
 * @param {Array<String>} items Item UUIDs
 * @return {void}
 */
const dBasketRemove = items => Store.dispatch({type: "basketRemove", items});

export default dBasketRemove;
