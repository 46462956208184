import {ErrorReporter, SnackbarService} from "@hps/hops-react";
import {Api} from "@hps/hops-sdk-js";
import {customAlphabet as nanoid} from "nanoid";

import dStationeryPrinting from "Dispatchers/dStationeryPrinting.js";
import GatewayService from "Gateway/GatewayService.js";

import TemplateRenderer from "./OrderStationeryTemplateRenderer.js";
import {getPrintCountForBasketItem} from "./OrderStationeryUtils.js";


/**
 * Order stationery service
 *
 * Contains utility methods for interacting with stationery items.
 *
 * @package HOPS
 * @subpackage Stationery
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class OrderStationeryService {

	/**
	 * Create stationery barcodes for a set of basket items.
	 *
	 * @param {Array<Object>} CheckoutBasketItem-like objects
	 * @return {Object} Item claim UUID => barcodes for that item's stationery
	 */
	static createBasketBarcodes(basketItems) {

		/**
		 * Barcodes we've created in this operation
		 *
		 * (To ensure each barcode in the set is unique.)
		 */
		const allBarcodes = [];

		/**
		 * Generated barcodes
		 */
		const barcodes = {};

		/**
		 * Get our basket items that require stationery prints
		 */
		const itemsWithStationery = basketItems.filter(i => !!i.StationeryTemplateId);

		/**
		 * Iterate each basket item with stationery
		 */
		for (const stationeryItem of itemsWithStationery) {

			let i = 0;
			const totalPrints = (stationeryItem.Quantity * getPrintCountForBasketItem(stationeryItem));

			/**
			 * Generate the stationery barcodes
			 *
			 * (This is client-side to support a future offline flow.)
			 */
			while (i < totalPrints) {

				let barcode = null;

				i++;
				barcodes[stationeryItem.Uuid] ||= [];

				while (!barcode) {
					barcode = this.generateNewBarcode();
					if (allBarcodes.includes(barcode)) {
						barcode = null;
					}
				}

				allBarcodes.push(barcode);
				barcodes[stationeryItem.Uuid].push(barcode);

			}

		}

		return barcodes;

	}


	/**
	 * Generate the stationery for a collection of basket items.
	 *
	 * Iterates over the supplied basket items; stationery is only 
	 * generated for items which have an assigned stationery template, 
	 * and where they're included in the provided `barcodes` array. The 
	 * number of stationery items to generate is determined by the number 
	 * of barcodes provided for the item.
	 *
	 * @param {Array<Object>} basketItems CheckoutBasketItem-like objects
	 * @param {Array<Object>} basketDiscounts BasketDiscountClaim-like objects
	 * @param {Object} barcodes Item UUID => array of barcodes (stationery) to generate (indexed with respect to item quantity units)
	 * @param {Object} templates Template ID => template HTML
	 * @param {Integer} orderId
	 * @param {Integer} orderTimestamp
	 * @param {String} deviceIdentifier Identifier for the PoS device (i.e. this device) to show on stationery
	 * @param {Object} checkoutData Checkout API result data (to lookup item details where required, e.g. order voucher issuances)
	 * @return {Array<String>} Rendered templates
	 */
	static generateBasketItemsStationery(
		basketItems,
		basketDiscounts,
		templates,
		orderId,
		orderTimestamp,
		deviceIdentifier,
		checkoutData,
		itemBarcodes,
		itemBarcodesStored
	) {

		const stationery = [];

		/**
		 * Determine final stationery barcodes in case 
		 * the server has had to change them due to conflicts
		 */
		const barcodes = this.resolveItemsCheckoutStoredBarcodes(itemBarcodes, itemBarcodesStored);

		/**
		 * Iterate each of the items in the basket
		 */
		basketItems.forEach(item => {

			/**
			 * Get the template to use for this item
			 */
			const templateId = item.StationeryTemplateId;
			const template = templates[templateId];
			if (!template) return;

			/**
			 * Number of prints to render for this item
			 */
			const printsPerQtyUnit = getPrintCountForBasketItem(item);


			/**
			 * Index of the quantity unit we're currently rendering
			 */
			let qtyIndex = 0;

			/**
			 * Index of the print we're rendering
			 */
			let printIndex = 0;

			/**
			 * Index of the print we're rendering (this quantity index)
			 */
			let printIndexThisQtyIndex = 0;


			/**
			 * Discounts that apply to this item
			 */
			const discounts = (basketDiscounts?.filter(discountClaim => {
				return (discountClaim?.ItemClaim === item.Uuid);
			}) || []);


			/**
			 * Render each stationery item
			 */
			for (const barcode of (barcodes[item.Uuid] || [])) {

				try {

					stationery.push(
						{
							barcode,
							stationeryHtml: TemplateRenderer.render({
								template,
								item,
								discounts,
								qtyIndex,
								printIndex,
								printIndexThisQtyIndex,
								barcode,
								orderId,
								orderTimestamp,
								deviceIdentifier,
								basketItems,
								checkoutData
							})
						}
					);

					printIndex++;
					printIndexThisQtyIndex++;

					if (printIndex === printsPerQtyUnit) {
						qtyIndex++;
						printIndexThisQtyIndex = 0;
					}

				}

				catch (e) {
					ErrorReporter.report(e);
				}

			}

		});


		/**
		 * We need to warn the operator when we have unverified barcodes
		 */
		if (Object.values(barcodes).find(barcodes => barcodes.find(barcode => barcode.endsWith("*")))) {
			SnackbarService.snack(`Warning: One or more stationery items could not be saved to the server.\nThese items will be marked with an asterisk next to their barcode.\nContact HOPS for more information.`, "warning");
		}


		return stationery;

	}


	/**
	 * Resolve final barcodes for an object describing the barcodes to 
	 * generate for a set of order items (as submitted to the HOPS 
	 * checkout API) from an object provided by the HOPS checkout 
	 * API describing the barcodes that were actually stored after 
	 * conflict resolution.
	 *
	 * @param {Object} itemBarcodes Item UUID => array of requested barcodes (indexed with respect to item quantity units)
	 * @param {Object} storedByCheckout Requested barcode => actually assigned barcode
	 */
	static resolveItemsCheckoutStoredBarcodes(itemBarcodes, storedByCheckout) {

		const resolvedBarcodes = [];

		Object.keys(itemBarcodes).forEach(itemUuid => {
			itemBarcodes[itemUuid].forEach(barcode => {

				resolvedBarcodes[itemUuid] ||= [];

				/**
				 * When the barcode isn't included in the server's response, 
				 * it means the server did not store that code due to a problem - 
				 * but we still believe to the best of our knowledge that the 
				 * ticket should be issued, and the customer may expect it.
				 *
				 * We therefore add an asterisk to show that the barcode 
				 * is an unverified ticket.
				 */
				resolvedBarcodes[itemUuid].push((storedByCheckout?.[barcode] || `${barcode} *`));

			});
		});

		return resolvedBarcodes;

	}


	/**
	 * Generate a new stationery barcode.
	 *
	 * @return {String}
	 */
	static generateNewBarcode() {
		return this.barcodeGenerator().toUpperCase();
	}


	/**
	 * Barcode generator instance
	 *
	 * @type {Function}
	 */
	static barcodeGenerator = nanoid("1234567890abcdefghijklmnopqrstuvwxyz", 12);


	/**
	 * Use Gateway to print the stationery.
	 *
	 * @return {void}
	 */
	static async printRenderedStationery(renderedStationery) {

		const printCount = renderedStationery?.length;

		if (printCount) {

			dStationeryPrinting(true);

			try {
				const result = await GatewayService.printStationery(renderedStationery);
				if (result.Printed !== printCount) SnackbarService.snack(`There was an error while printing one or more stationery items.\nPlease check the PoS Gateway server logs for more information.`, "warning");
			}
			catch (e) {

				let msg = e;
				if (e?.response?.status === 404) {
					msg = "Device error (the selected printer might be offline).";
				}
				else if (!e?.response) {
					msg = "Connectivity error (check that the Gateway server is running).";
				}

				SnackbarService.snack(`Stationery Printing Error: ${msg}`, "error");

			}

			dStationeryPrinting(false);

		}

	}

	/**
	 * Record a Print or Re-Print of the Stationery (physical tickets, etc.) associated with an Order Item in an Order.
	 *
	 * @return {void}
	 */
	static async recordPrintItemStationery(OrderIdentity, OrderItemId, Barcodes, InvalidatedReason = null) {
		return await Api.call({
			url: `/api/pos/orders/${OrderIdentity}/items/${OrderItemId}/stationery/print`,
			method: "POST",
			data: {
				Barcodes,
				InvalidatedReason
			}
		}).then(({data}) => data);
	}

}

export default OrderStationeryService;
