import {TextField} from "@hps/hops-react";
import {OrderVoucherService} from "@hps/hops-sdk-js";

export default props => {

	const handleChange = code => props.onChange(OrderVoucherService.transformVoucherCodeInput(code, props.value));

	return (
		<TextField
			autoFocus={props.autoFocus}
			disabled={props.disabled}
			fullWidth={props.fullWidth}
			inputRef={props.inputRef}
			label={(props.label || "Voucher Code")}
			maxLength={19}
			minLength={19}
			onChange={handleChange}
			onEnter={props.onEnter}
			pattern="([A-Z0-9]{4}-){3}[A-Z0-9]{4}"
			placeholder="XXXX-XXXX-XXXX-XXXX"
			required={props.required}
			value={props.value} />
	);

};
