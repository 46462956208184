import {Button, Divider, EmptyStateGate, Flex, String, TextField} from "@hps/hops-react";
import React, {useState} from "react";
import {Marker} from "react-mark.js";

import withRaildaysFaqs from "Hoc/withRaildaysFaqs.js";

import FaqTopicBrowserCard from "./FaqTopicBrowserCard";

/**
 * Render available Raildays FAQ topics
 * 
 * @package HOPS
 * @subpackage CustomerServices
 * @copyright Heritage Operations Processing Limited
 */
const CommercialFaqsCsBrowser = props => {

	const [searchTerm, setSearchTerm] = useState(null);
	const handleClearSearch = () => setSearchTerm(null);

	/**
	 * Barebones text search in the questions for a given search term
	 */
	const getFilteredFaqs = () => {

		const searchResults = [];

		/* If there's no search term, just return all of them and be done with it */
		if (!searchTerm) return props.RaildaysFaqs;

		/* Make a dummy 'Topic' that will just show search results */
		const searchResultTopic = {Id: 0, Name: `Search results for '${searchTerm}'`, Order: 0, Questions: []};

		/* Search for Qs and As for your query */
		props.RaildaysFaqs?.forEach(topic => {

			const topicResults = topic.Questions?.filter(question => (
				question.QuestionText?.includes(searchTerm) ||
				question.AnswerText?.includes(searchTerm) ||
				question.AnswerInternalRemarks?.includes(searchTerm)
			));

			/* Add them to the dummy search results topic */
			searchResultTopic.Questions = searchResultTopic.Questions?.concat(topicResults);
		});

		searchResults.push(searchResultTopic);

		return searchResults;
	};

	const filteredFaqs = getFilteredFaqs();

	return (
		<Flex gap={2} px={1} py={1}>
			<String bold={true} noFlex={true} str="Commercial FAQs" variant="h5" />
			<Flex columnar={true}>
				<TextField
					autoFocus={true}
					/* label="Search" */
					onChange={setSearchTerm}
					placeholder="Search"
					value={searchTerm} />
				<Button
					disabled={!searchTerm}
					onClick={handleClearSearch}
					label="Clear"
					variant="contained" />
			</Flex>
			<Divider />

			<EmptyStateGate isEmpty={!filteredFaqs?.length}>
				<Marker mark={searchTerm || ""}>
					<Flex gap={3}>
						{
							filteredFaqs?.map((faqTopic, key) => (
								<FaqTopicBrowserCard
									key={key}
									topic={faqTopic} />
							))
						}
					</Flex>
				</Marker>
			</EmptyStateGate>
		</Flex>
	);

};

export default withRaildaysFaqs(CommercialFaqsCsBrowser);
