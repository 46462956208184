import BasketService from "Services/BasketService.js";

import dAuthUser from "./dAuthUser.js";
import dLogoutDialog from "./dLogoutDialog.js";
import dQuestionsDialog from "./dQuestionsDialog.js";
import dResetSearch from "./dResetSearch.js";
import dSeatReservationDialog from "./dSeatReservationDialog.js";

const dLogout = () => {

	BasketService.clearItems();

	dAuthUser(null);
	dLogoutDialog(false);
	dQuestionsDialog(false);
	dSeatReservationDialog(null);
	dResetSearch();

};

export default dLogout;
