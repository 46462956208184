import {Button, Flex, String} from "@hps/hops-react";

const CheckoutViewEmptyState = () => (
	<Flex
		alignItems="center"
		gap={2}
		mt={1}
		width="100%">
		<String
			color="textSecondary"
			str="The basket is empty."
			variant="h6" />
		<Button
			label="Home"
			size="large"
			uri="/" />
	</Flex>
);

export default CheckoutViewEmptyState;
