import {Flex, String} from "@hps/hops-react";
import {CheckoutBasketPayment} from "@hps/hops-sdk-js";
import PropTypes from "prop-types";
import React from "react";

/**
 * Renders the details/summary of a `CheckoutBasketPayment`-like object
 */
const PaymentPanelItemDetails = props => {

	const details = CheckoutBasketPayment.getUiDetails(props.item);

	const renderSubtext = () => {
		return (
			<String
				color={props.item?.PaymentImmediateCharge ? "error" : "textSecondary"}
				inline={true}
				noFlex={true}
				str={(CheckoutBasketPayment.getUiSubtext(props.item) || "")}
				variant="body2" />
		);
	};

	return (
		details?.length ?
			<details>
				<summary title="View Details">{renderSubtext()}</summary>
				<Flex gap={0.25} pl={0.75} mt={0.25}>
					{
						details.map((detail, key) => (
							<div key={key}>
								<String
									inline={true}
									noFlex={true}
									noTrim={true}
									str={`${detail.label}${(detail.value ? ": " : "")}`}
									variant="body2"
									whiteSpace="pre-wrap" />
								<String
									color="textSecondary"
									inline={true}
									noFlex={true}
									str={detail.value}
									variant="body2" />
							</div>
						))
					}
				</Flex>
			</details> :
			renderSubtext()
	);

};

PaymentPanelItemDetails.propTypes = {
	item: PropTypes.object
};

export default PaymentPanelItemDetails;
