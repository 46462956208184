import {PaymentTypes} from "@hps/hops-sdk-js";

import PaymentHandlerCardHolderPresent from "Payment/Handlers/PaymentHandlerCardHolderPresent.js";
import PaymentHandlerCardMoto from "Payment/Handlers/PaymentHandlerCardMoto.js";
import PaymentHandlerCash from "Payment/Handlers/PaymentHandlerCash.js";
import PaymentHandlerPlaceholder from "Payment/Handlers/PaymentHandlerPlaceholder.js";
import PaymentHandlerVoucher from "Payment/Handlers/PaymentHandlerVoucher.js";

const PaymentHandler = props => {

	switch (props.PaymentType) {

		/** Pay by Credit/Debit Card (Not Present Online/MOTO) */
		case PaymentTypes.Card:
			return <PaymentHandlerCardMoto {...props}/>;

		/** Pay by Cash */
		case PaymentTypes.Cash:
			return <PaymentHandlerCash {...props} />;

			/** Pay by Gift Voucher */
		case PaymentTypes.Voucher:
			return <PaymentHandlerVoucher {...props} />;

			/**
			 * Not yet implemented
			 *case PaymentTypes.CreditNote:
			 *case PaymentTypes.None:
			 *case PaymentTypes.Cheque:
			 *case PaymentTypes.Bacs:
			 */

		/** Pay with HOPS Payment App */
		case PaymentTypes.Placeholder:
			return <PaymentHandlerPlaceholder {...props} />;

		/** Pay by Credit/Debit Card (In-Person)  */
		case PaymentTypes.CardHolderPresent:
			return <PaymentHandlerCardHolderPresent {...props} />;

		default:
			return <></>;

	}

};

export default PaymentHandler;
