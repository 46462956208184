/**
 * Forked from https://github.com/cwise89/react-detect-offline/tree/master
 * Not updated in 3+ years
 */

/* eslint-disable max-classes-per-file */
import {Store} from "@hps/hops-react";
import PropTypes from "prop-types";
import {Children, Component, createElement, isValidElement} from "react";

import dOnlineNetwork from "Dispatchers/dOnlineNetwork";

const inBrowser = typeof navigator !== "undefined";

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const ping = ({url, timeout}) => {
	return new Promise(resolve => {
		const isOnline = () => resolve(true);
		const isOffline = () => resolve(false);

		const xhr = new XMLHttpRequest();

		xhr.onerror = isOffline;
		xhr.ontimeout = isOffline;
		xhr.onreadystatechange = () => {
			if (xhr.readyState === xhr.HEADERS_RECEIVED) {
				if (xhr.status) {
					isOnline();
				}
				else {
					isOffline();
				}
			}
		};

		xhr.open("GET", url);
		xhr.timeout = timeout;
		xhr.send();
	});
};

const propTypes = {
	children: PropTypes.node,
	onChange: PropTypes.func,
	polling: PropTypes.oneOfType([
		PropTypes.shape({
			url: PropTypes.string,
			interval: PropTypes.number,
			timeout: PropTypes.number
		}),
		PropTypes.bool
	]),
	wrapperType: PropTypes.string
};

const defaultProps = {
	polling: true,
	wrapperType: "span"
};

const defaultPollingConfig = {
	enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
	url: "https://httpbin.org/get",
	timeout: 5000,
	interval: 5000
};

// base class that detects offline/online changes
class Base extends Component {
	constructor() {
		super();
		dOnlineNetwork(inBrowser && typeof navigator.onLine === "boolean" ? navigator.onLine : true);
		// bind event handlers
		this.goOnline = this.goOnline.bind(this);
		this.goOffline = this.goOffline.bind(this);
	}

	componentDidMount() {
		window.addEventListener("online", this.goOnline);
		window.addEventListener("offline", this.goOffline);

		if (this.getPollingConfig().enabled) {
			this.startPolling();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("online", this.goOnline);
		window.removeEventListener("offline", this.goOffline);

		if (this.pollingId) {
			this.stopPolling();
		}
	}

	renderChildren() {
		const {children, wrapperType} = this.props;

		// usual case: one child that is a react Element
		if (isValidElement(children)) {
			return children;
		}

		// no children
		if (!children) {
			return null;
		}

		// string children, multiple children, or something else
		return createElement(wrapperType, {}, ...Children.toArray(children));
	}

	getPollingConfig() {
		switch (this.props.polling) {
			case true:
				return defaultPollingConfig;
			case false:
				return {enabled: false};
			default:
				return {...defaultPollingConfig, ...this.props.polling};
		}
	}

	goOnline() {
		const online = Store.getState().Ui?.OnlineNetwork;
		if (!online) {
			this.callOnChangeHandler(true);
			dOnlineNetwork(true);
		}
	}

	goOffline() {
		const online = Store.getState().Ui?.OnlineNetwork;
		if (online) {
			this.callOnChangeHandler(false);
			dOnlineNetwork(false);
		}
	}

	callOnChangeHandler(online) {
		if (this.props.onChange) {
			this.props.onChange(online);
		}
	}

	startPolling() {
		const {interval} = this.getPollingConfig();
		this.pollingId = setInterval(() => {
			const {url, timeout} = this.getPollingConfig();
			ping({url, timeout}).then(online => {
				online ? this.goOnline() : this.goOffline();
			});
		}, interval);
	}

	stopPolling() {
		clearInterval(this.pollingId);
	}
}
Base.propTypes = propTypes;
Base.defaultProps = defaultProps;

export class Online extends Base {
	render() {
		const online = Store.getState().Ui?.OnlineNetwork;
		return online ? this.renderChildren() : null;
	}
}
Online.propTypes = propTypes;
Online.defaultProps = defaultProps;

export class Offline extends Base {
	render() {
		const online = Store.getState().Ui?.OnlineNetwork;
		return !online ? this.renderChildren() : null;
	}
}
Offline.propTypes = propTypes;
Offline.defaultProps = defaultProps;

export class Detector extends Base {
	render() {
		const online = Store.getState().Ui?.OnlineNetwork;
		return this.props.render ? this.props.render({online}) : null;
	}
}
Detector.propTypes = {...propTypes, render: PropTypes.func};
Detector.defaultProps = defaultProps;
