import {Flex, InvalidUriView, Link} from "@hps/hops-react";
import React from "react";

import withAuthUser from "Hoc/withAuthUser.js";

const AuthedInvalidUriView = props => {

	return (
		<>
			<InvalidUriView />

			{!props.authed && props.history.location.pathname && <Flex px={1}>
				<Link
					endArrow={true}
					label="Login to POS"
					uri={`/?redirect=${encodeURI(props.history.location.pathname)}`} />
			</Flex>}
		</>
	);

};

export default withAuthUser(AuthedInvalidUriView);
