import {String} from "@hps/hops-react";
import pluralize from "pluralize";
import React, {useEffect, useState} from "react";

import withBasket from "Hoc/withBasket.js";


/**
 * Basket expiration timer display
 *
 * It works by using an interval timer to change the rendered 
 * element's React `key` each second, forcing a re-render that 
 * causes the current expiration to always be displayed.
 * 
 * @package HOPS
 * @subpackage Basket
 * @copyright Heritage Operations Processing Limited
 */
const BasketExpirationTimer = props => {

	const [key, setKey] = useState(Date.now());

	useEffect(() => {

		const updateBasketRemainingLifetime = () => {
			setKey(Date.now());
		};

		// Set an interval to calculate the remaining basket lifetime every second
		const keyInterval = setInterval(updateBasketRemainingLifetime, 1000);

		// Clean up function, remove the interval
		return () => clearInterval(keyInterval);

	}, [key]);


	const expirationSeconds = () => {
		const expiry = props.BasketIdentity?.Expiry;
		return (expiry ? Math.max(0, (expiry - Math.floor((Date.now() / 1000)))) : null);
	};
	const seconds = expirationSeconds();

	let str = "-";

	if (!isNaN(seconds)) {

		if (seconds > 60) {
			const mins = Math.ceil((seconds / 60));
			str = `${mins} ${pluralize("minute", mins)}`;
		}
		else {
			str = `${seconds} ${pluralize("second", seconds)}`;
		}

	}

	return (
		<String
			centre={props.centre}
			color={((seconds > 300) ? "textSecondary" : "error")}
			key={key}
			noFlex={true}
			str={(((seconds === 0) && props.expiredText) || `${(props.prefix || "")} ${str} ${(props.suffix || "")}`)} />
	);

};

export default withBasket(BasketExpirationTimer);
