import {Button, EmptyStateGate, Flex, History, Loadable, Paper, String, Table, TabPanel, TextField} from "@hps/hops-react";
import {PriceUtils} from "@hps/hops-sdk-js";
import {Pagination} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import {useEffect, useState} from "react";

import useQuery from "Hooks/useQuery";
import OrderStatusChip from "Search/OrderDetails/OrderStatusChip";
import OrderService from "Services/OrderService.js";

const AdvancedSearchView = () => {

	const query = useQuery();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchResults, setSearchResults] = useState(null);
	const [activeTab, setActiveTab] = useState(0);

	const [searchTerms, setSearchTerms] = useState({});

	const [maxPages, setMaxPages] = useState(1);

	const tableFields = [
		{
			label: "Order",
			render: i => <OrderStatusChip Id={i.Id} uri={`/search/orders/${i.Id}`} Status={i.Status} />
		},
		{
			label: "Order Date",
			render: i => (new moment(i.Timestamp)).format("HH:mm DD/MM/YYYY")
		},
		{
			label: "Tax Date",
			render(i) {
				let taxLabel = "-";
				if (i.TaxTimestamp) taxLabel = (new moment(i.TaxTimestamp)).format("DD/MM/YYYY");

				return <String str={taxLabel} />;
			}
		},
		{
			label: "Device",
			render(i) {
				return (
					<>
						{(i.BillingContact?.Name) && <String str={`Cust: ${i.BillingContact?.Name}`} />}
						{(i.PosDevice?.Name) && <String str={`POS: ${i.PosDevice?.Name}`} />}
					</>
				);
			}
		},
		{
			label: "Items",
			render: i => PriceUtils.getDisplayStringIntl(i.NetTotal)
		},
		{
			label: "VAT",
			render: i => PriceUtils.getDisplayStringIntl(i.VATTotal)
		},
		{
			label: "Total",
			render: i => PriceUtils.getDisplayStringIntl(i.GrossTotal)
		},
		{
			label: "GA-able",
			render: i => PriceUtils.getDisplayStringIntl(i.GATotal)
		}
	];


	/**
	 * Get the order details for a given search criteria
	 *
	 * @async
	 * @return {void}
	 */
	const getResults = async searchParams => {

		setError(null);
		setLoading(true);

		try {
			await OrderService.search(searchParams).then(data => {
				setSearchResults(data);
				setMaxPages(Math.ceil(data.TotalResults / data.Limit));
			});
		}
		catch (e) {
			setError(e);
		}

		setLoading(false);

	};


	/**
	 * If there are variables set in the route, retrieve the search results on load
	 */
	useEffect(() => {

		if (query.size) {
			setSearchTerms(Object.fromEntries(query));
			getResults(query);
		}

	}, [query]);


	/**
	 * Input fields changed
	 */
	const handleChange = (value, name) => {

		const newSearchTerms = {...searchTerms, [name]: value};

		// Remove the property if no value
		if (!value) delete newSearchTerms[name];

		setSearchTerms(newSearchTerms);

	};


	/**
	 * Search form submitted
	 */
	const handleSubmit = e => {

		e?.preventDefault?.();

		History.push(`?${new URLSearchParams(searchTerms)}`);
	};


	/**
	 * Search form cleared
	 */
	const handleClear = () => {
		setSearchTerms({});
		setSearchResults(null);
	};


	const handleChangePage = (e, v) => {
		getResults({...searchTerms, Page: v});
	};


	const renderResults = () => {

		const resultStart = (searchResults.Limit * searchResults.Page - searchResults.Limit) + 1;
		const resultEnd = resultStart + searchResults.Results.length - 1;

		return (
			<EmptyStateGate isEmpty={!searchResults?.Results}>
				<Flex alignItems="center"
					columnar={true}
					justifyContent="space-between">
					<String str={`Results ${resultStart}-${resultEnd} of ${searchResults.TotalResults}.`} />
					<Pagination count={maxPages} page={parseInt(searchResults.Page)} onChange={handleChangePage} showFirstButton showLastButton />
				</Flex>

				<Table
					items={searchResults?.Results}
					fields={tableFields} />

				<Flex alignItems="center"
					columnar={true}
					justifyContent="space-between">
					<String str={`Results ${resultStart}-${resultEnd} of ${searchResults.TotalResults}.`} />
					<Pagination count={maxPages} page={parseInt(searchResults.Page)} onChange={handleChangePage} showFirstButton showLastButton />
				</Flex>
			</EmptyStateGate>
		);
	};


	const renderTabSimpleSearch = () => {
		return (
			<Flex>
				<TextField
					name="q"
					label="Keywords"
					onChange={handleChange}
					value={searchTerms?.q} />
			</Flex>
		);
	};


	const renderTabBillingContact = () => {
		return (
			<Flex gap={0.5}>
				<Flex columnar={true} gap={0.5}>
					<TextField
						name="name"
						label="First Name"
						onChange={handleChange}
						value={searchTerms?.name} />
					<TextField
						name="surname"
						label="Last Name"
						onChange={handleChange}
						value={searchTerms?.surname} />
					<TextField
						name="email"
						label="Email Address"
						onChange={handleChange}
						value={searchTerms?.email} />

					<TextField
						name="phone"
						label="Telephone"
						onChange={handleChange}
						value={searchTerms?.phone} />
				</Flex>
				<TextField
					name="address"
					label="Address"
					onChange={handleChange}
					value={searchTerms?.address} />
			</Flex>
		);
	};


	const renderTabDeliveryContact = () => {

		return (
			<Flex gap={0.5}>
				<Flex columnar={true} gap={0.5}>
					<TextField
						name="name_delivery"
						label="First Name"
						onChange={handleChange}
						value={searchTerms?.name_delivery} />
					<TextField
						name="surname_delivery"
						label="Last Name"
						onChange={handleChange}
						value={searchTerms?.surname_delivery} />
					<TextField
						name="email_delivery"
						label="Email Address"
						onChange={handleChange}
						value={searchTerms?.email_delivery} />
					<TextField
						name="phone_delivery"
						label="Telephone"
						onChange={handleChange}
						value={searchTerms?.phone_delivery} />
				</Flex>
				<TextField
					name="address_delivery"
					label="Address"
					onChange={handleChange}
					value={searchTerms?.address_delivery} />
			</Flex>
		);

	};


	const renderTabOrderDetails = () => {
		return (
			<Flex gap={0.5}>
				<Flex columnar={true} gap={0.5}>

					<DatePicker
						desktopModeMediaQuery="(min-width:600px)"
						name="after"
						label="Placed After"
						onChange={newValue => handleChange(newValue ? newValue.format("YYYY-MM-DD") : null, "after")}
						slotProps={{
							field: {clearable: true}
						}}
						value={searchTerms?.after ? moment(searchTerms?.after) : null} />

					<DatePicker
						desktopModeMediaQuery="(min-width:600px)"
						name="before"
						label="Placed Before"
						onChange={newValue => handleChange(newValue ? newValue.format("YYYY-MM-DD") : null, "before")}
						slotProps={{
							field: {clearable: true}
						}}
						value={searchTerms?.before ? moment(searchTerms?.before) : null} />

				</Flex>
				<TextField
					name="remarks"
					label="Remarks"
					onChange={handleChange}
					value={searchTerms?.remarks} />
			</Flex>
		);
	};


	const tabs = [
		{
			label: "Keywords",
			render: renderTabSimpleSearch
		},
		{
			label: "Billing Contact",
			render: renderTabBillingContact
		},
		{
			label: "Delivery Contact",
			render: renderTabDeliveryContact
		},
		{
			label: "Order Details",
			render: renderTabOrderDetails
		}
	];


	return (
		<Flex
			gap={2}
			px={1}
			py={1}>
			<String
				bold={true}
				noFlex={true}
				str="Advanced Order Search"
				variant="h5" />

			<Paper>
				<form onSubmit={handleSubmit}>
					<Flex>

						<TabPanel
							gap={1}
							onChange={setActiveTab}
							tabs={tabs}
							scrollButtons="auto"
							value={activeTab}
							variant="scrollable" />

						<Flex columnar={true}>
							<Button label="Search" onClick={handleChange} type="submit" variant="contained" />
							<Button label="Clear" onClick={handleClear} type="clear" />
						</Flex>

					</Flex>
				</form>
			</Paper>

			<Loadable
				error={!!error}
				FlexProps={{alignItems: "flex-start"}}
				loading={loading}>
				{(
					searchResults ?
						renderResults() :
						(loading ?
							<String
								color="textSecondary"
								noFlex={true}
								str="Loading..." /> :
							"")
				)}
			</Loadable>
		</Flex>
	);

};

export default AdvancedSearchView;
