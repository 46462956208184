import {Button, Dialog, Flex, OrderQuestionSet, SnackbarService} from "@hps/hops-react";
import React, {useCallback, useRef} from "react";
import {useEffect, useState} from "react";

import CheckoutCustomerDetailsForm from "Checkout/CustomerDetails/CheckoutCustomerDetailsForm.js";
import dBasketCustomerDetails from "Dispatchers/dBasketCustomerDetails.js";
import dBasketQuestionsAnswers from "Dispatchers/dBasketQuestionsAnswers.js";
import dBasketQuestionsAnswersDeclined from "Dispatchers/dBasketQuestionsAnswersDeclined.js";
import dQuestionsDialog from "Dispatchers/dQuestionsDialog.js";
import withBasket from "Hoc/withBasket.js";
import withUi from "Hoc/withUi";

import scss from "./CheckoutQuestionsDialog.module.scss";

/**
 * Checkout questions dialog
 *
 * @param {Object} props
 * @return {ReactNode}
 */
const CheckoutQuestionsDialog = props => {

	const formRef = useRef();
	const formRefCustomerDetails = useRef();
	const [enforceCustomerDetailsRequired, setEnforceCustomerDetailsRequired] = useState(false);

	/**
	 * Toggling a question's "I'm not answering" option.
	 *
	 * @param {Boolean} declined
	 * @param {String} id Question ID
	 * @return {void}
	 */
	const handleAnswerDecline = (declined, id) => {

		const [itemClaimUuid, questionId, unitIndex, personIndex] = id.split(".");

		/**
		 * Clear any answer to the question
		 */
		if (itemClaimUuid === "order") {
			dBasketQuestionsAnswers({
				...props.BasketQuestionsAnswers,
				Order: {
					...props.BasketQuestionsAnswers?.Order,
					[questionId]: null
				}
			});
		}
		else if (personIndex !== undefined) {
			dBasketQuestionsAnswers({
				...props.BasketQuestionsAnswers,
				People: {
					...props.BasketQuestionsAnswers?.People,
					[personIndex]: {
						...props.BasketQuestionsAnswers?.People?.[personIndex],
						[itemClaimUuid]: {
							...props.BasketQuestionsAnswers?.People?.[personIndex]?.[itemClaimUuid],
							[unitIndex]: {
								...props.BasketQuestionsAnswers?.People?.[personIndex]?.[itemClaimUuid]?.[unitIndex],
								[questionId]: null
							}
						}
					}
				}
			});
		}
		else {
			dBasketQuestionsAnswers({
				...props.BasketQuestionsAnswers,
				Items: {
					...props.BasketQuestionsAnswers?.Items,
					[itemClaimUuid]: {
						...props.BasketQuestionsAnswers?.[itemClaimUuid],
						[unitIndex]: {
							...props.BasketQuestionsAnswers?.[itemClaimUuid]?.[unitIndex],
							[questionId]: null
						}
					}
				}
			});
		}

		/**
		 * Mark the question as declined
		 */
		dBasketQuestionsAnswersDeclined({
			...props.BasketQuestionsAnswersDeclined,
			[id]: declined
		});

	};

	/**
	 * Customer details changed
	 */
	const handleCustomerDetailsChange = useCallback((value, name) => {
		setEnforceCustomerDetailsRequired(false);
		dBasketCustomerDetails({...props.BasketCustomerDetails, [name]: value});
	}, [props.BasketCustomerDetails]);

	/**
	 * Saving answers without validation
	 */
	const handleSave = useCallback(() => {
		if (!props.hasAllowedCustomerDetails || formRefCustomerDetails?.current?.reportValidity()) {
			dQuestionsDialog(false);
		}
	}, [props.hasAllowedCustomerDetails]);

	/**
	 * Quitting the form without saving
	 */
	const handleCancel = () => {
		dQuestionsDialog(false);
	};

	/**
	 * Validating question answers
	 */
	const handleValidate = useCallback(() => {
		if (formRef?.current?.formRef?.current?.reportValidity?.()) {
			setEnforceCustomerDetailsRequired(true);
		}
	}, [formRef]);

	/**
	 * Validation flow completion
	 */
	useEffect(() => {
		if (enforceCustomerDetailsRequired) {
			if (!props.hasAllowedCustomerDetails || formRefCustomerDetails?.current?.reportValidity?.()) {
				SnackbarService.snack("All answers are valid.", "success");
			}
		}
	}, [enforceCustomerDetailsRequired, props.hasAllowedCustomerDetails]);

	/**
	 * Render the customer details form
	 */
	const renderCustomerDetails = () => (
		<CheckoutCustomerDetailsForm
			allowedDetails={props.allowedCustomerDetails}
			enforceRequired={enforceCustomerDetailsRequired}
			formRef={formRefCustomerDetails}
			onChange={handleCustomerDetailsChange}
			values={props.BasketCustomerDetails} />
	);

	/**
	 * Render our `OrderQuestionSet`
	 */
	const renderQuestionSet = () => (
		<OrderQuestionSet
			answers={(props.BasketQuestionsAnswers || {})}
			optionalNoAnswerAcknowledgements={props.BasketQuestionsAnswersDeclined}
			itemClaims={props.BasketItems}
			noQuestionsText={(props.hasAllowedCustomerDetails ? " " : undefined)}
			onChange={dBasketQuestionsAnswers}
			onChangeOptionalNoAnswerAcknowledgement={handleAnswerDecline}
			perOrderQuestionsSectionLabel="Order Questions"
			ref={formRef}
			requireOptionalNoAnswerAcknowledgement={true}
			questions={(props.BasketQuestions || {})} />
	);

	/**
	 * Render!
	 */
	return (
		<Dialog
			actions={<Button label="Check Validation" onClick={handleValidate} variant="text" />}
			classNameActions={scss.dialogActions}
			classNameTitle={scss.dialogTitle}
			fullScreen
			okLabel="Save"
			onClose={handleCancel}
			onOk={handleSave}
			open={props.Ui.QuestionsDialog}
			title="Questions">
			<Flex gap={2}>
				{(props.hasAllowedCustomerDetails && renderCustomerDetails())}
				{(props.BasketQuestions && renderQuestionSet())}
			</Flex>

		</Dialog>
	);

};

export default withBasket(withUi(CheckoutQuestionsDialog));
