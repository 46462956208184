import {Dialog, NumericInput} from "@hps/hops-react";

/**
 * Price input dialog
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const InventoryPriceInputDialog = props => {

	return (
		<Dialog
			noOkButton={true}
			noCloseButton={true}
			onClose={props.onClose}
			onOk={props.onSubmit}
			open={props.open}
			width="xs"
			title="Price Input">

			<NumericInput
				label="Please enter an amount"
				onCancel={props.onClose}
				onSubmit={props.onSubmit}
				formatAsCurrency={true} />

		</Dialog>
	);

};

export default InventoryPriceInputDialog;
