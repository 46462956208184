import withBasket from "Hoc/withBasket";
import PaymentProcessorCash from "Payment/Processors/PaymentProcessorCash";
import RefundProcessorCash from "Payment/Processors/RefundProcessorCash";

const PaymentHandlerCash = props => {

	/*
	 * All PaymentTypes.Cash payments come through this gatehouse.
	 * 
	 * Use this component to determine which processor to use for a PaymentType/PaymentMethod.
	 */

	// Negative payments go through the cash refund processor
	if (props.PaymentsBalanceDue < 0) return <RefundProcessorCash {...props} />;

	return <PaymentProcessorCash {...props} />;
};

export default withBasket(PaymentHandlerCash);
