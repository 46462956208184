import {ErrorReporter, Flex, withRegistration} from "@hps/hops-react";
import {OrderStatuses, PosCheckoutService} from "@hps/hops-sdk-js";
import React from "react";
import {memo, useCallback, useEffect, useState} from "react";

import dBasketLoading from "Dispatchers/dBasketLoading.js";
import dBasketReset from "Dispatchers/dBasketReset.js";
import dOrderHistoryAdd from "Dispatchers/dOrderHistoryAdd.js";
import dOrderUploadQueue from "Dispatchers/dOrderUploadQueue.js";
import dResetSearch from "Dispatchers/dResetSearch.js";
import withAuthUser from "Hoc/withAuthUser.js";
import withBasket from "Hoc/withBasket.js";
import withPaymentType from "Hoc/withPaymentType.js";
import withUi from "Hoc/withUi.js";
import useGateway from "Hooks/useGateway.js";
import useQuery from "Hooks/useQuery";
import BasketService from "Services/BasketService.js";
import PosDeviceService from "Services/PosDeviceService.js";
import OrderStationeryService from "Stationery/OrderStationeryService.js";

import CheckoutViewCheckoutStage from "./CheckoutViewCheckoutStage.js";
import CheckoutViewSuccessStage from "./CheckoutViewSuccessStage.js";

/**
 * Checkout view
 *
 * @package HOPS
 * @subpackage Checkout
 * @copyright Heritage Operations Processing Limited
 */
const CheckoutView = memo(props => {

	const {
		AuthUser,
		BasketDiscounts,
		BasketIdentity,
		BasketItems,
		BasketCustomerDetails,
		BasketQuestionsAnswers,
		hasNoPayments,
		Payments,
		PaymentsBalanceDue,
		PaymentsChange,
		PaymentsChangeIsDue,
		PaymentsIsSettled,
		PaymentType,
		Registration
	} = props;

	const gateway = useGateway();

	const [error, setError] = useState(null);
	const [outcome, setOutcome] = useState(null);

	const [renderedStationery, setRenderedStationery] = useState(null);

	const isDepositCheckout = (
		Registration.Device?.UsesLegacyCheckout ?
			false : // Legacy checkout doesn't handle deposits
			!PaymentsIsSettled // Modern checkout uses a partial list of Payments[]
	);

	const isProvisionalCheckout = (
		Registration.Device?.UsesLegacyCheckout ?
			PaymentType === null : // Legacy checkout uses null payment type
			(hasNoPayments && !PaymentsIsSettled) // Modern checkout uses an empty list of Payments[] and the order total isn't zero
	);

	const query = useQuery();
	const skipconfirm = query.get("skipconfirm");


	/**
	 * Use Gateway to print the stationery.
	 *
	 * @return {void}
	 */
	const printRenderedStationery = useCallback(async () => {

		await OrderStationeryService.printRenderedStationery(renderedStationery);

	}, [renderedStationery]);


	/**
	 * Self-confirm checkout if set in the query string
	 */
	useEffect(() => {
		if (skipconfirm) handleCheckout();
	}, [skipconfirm]);

	/**
	 * We are checking out!
	 *
	 * @return {void}
	 */
	const handleCheckout = useCallback(async () => {

		/**
		 * We are loading
		 */
		dBasketLoading(true);


		/**
		 * Will we be printing our stationery?
		 */
		let willPrintStationery = false;


		/*
		 * Variables to hold our basket and outcome states.
		 * These need to be outside the Try scope so basket is available
		 * further down where we do error capture.
		 */
		let basket = {};
		let outcome = {};


		/**
		 * Check Gateway connectivity to determine whether to print stationery
		 */
		try {
			willPrintStationery = await gateway.checkConnectivity();
		}
		catch (e) {
			ErrorReporter.report(e);
		}


		/**
		 * Make our API call
		 */
		try {

			/**
			 * Stationery barcodes to submit
			 */
			let stationeryBarcodes = {};


			/**
			 * Try to generate our barcodes
			 */
			try {
				if (!isProvisionalCheckout && !isDepositCheckout) {
					stationeryBarcodes = OrderStationeryService.createBasketBarcodes(BasketItems);
				}
			}
			catch (e) {
				ErrorReporter.report(e);
			}

			/**
			 * Stationery printing data to submit
			 */
			const StationeryPrints = {
				Barcodes: stationeryBarcodes,
				Printed: willPrintStationery
			};


			/**
			 * Call the checkout API!
			 */
			basket = {
				BasketId: BasketIdentity.Id,
				Items: BasketItems,
				Payments,
				Discounts: BasketDiscounts.map(discount => {
					return {
						ItemClaimUuid: discount.ItemClaim,
						DiscountClaimUuid: discount.Discount.Claim,
						DiscountAmount: discount.Discount.Amount,
						DiscountQty: discount.DiscountQty
					};
				}),
				CustomerDetails: BasketCustomerDetails,
				QuestionAnswers: BasketQuestionsAnswers,
				StationeryPrints,
				Timestamp: Math.floor((Date.now() / 1000)),
				UserId: AuthUser.Id,
				PosOffline: BasketService.isBasketOffline(),
				OfflineBasketUuid: BasketIdentity.OfflineBasketUuid
			};

			if (!basket.PosOffline) {

				/**
				 * Send the order to the checkout API
				 */
				outcome = await PosCheckoutService.checkout(basket);

			}
			else {

				/** 
				 * Queue the order for upload
				 */

				dOrderUploadQueue(basket);

				/**
				 * Generate a dummy outcome for Receipt Utils
				 */

				outcome = {
					CheckoutData: null,
					Order: "(Offline)",
					PosDevice: {
						...Registration.Device,
						Offline: true
					},
					OrderStatus: OrderStatuses.Complete,
					OrderTimestamp: new Date() / 1000,
					OrderTotal: BasketItems.reduce((subTotal, item) => subTotal + ((item.Price) * item.Quantity), 0),
					PaymentOutcome: null,
					PaymentOutcomes: [],
					PaymentErrors: [],
					RedeemedOrderVouchers: [],
					StationeryBarcodes: [],
					VatSummary: [] // We don't show a VAT summary for Offline orders
				};

			}


			/**
			 * We're about to clear the basket (so we can't 
			 * check it out again and get into availability 
			 * issues) so we need to cache the basket content 
			 * with our outcome data so it can be accessed 
			 * by the receipt UI
			 */
			const checkoutOutcome = {
				...outcome,
				BasketItems,
				CustomerDetails: BasketCustomerDetails,
				Discounts: BasketDiscounts,
				Payments,
				PaymentsBalanceDue,
				PaymentsChange,
				PaymentsChangeIsDue,
				PaymentsIsSettled,
				wasDepositCheckout: isDepositCheckout,
				wasProvisionalCheckout: isProvisionalCheckout
			};

			// Log TXN outcome telemetry
			if (!basket.PosOffline) {
				try {
					await PosDeviceService.setTxnOutcome(outcome.Order, true, basket, checkoutOutcome);
				}
				catch (e) {
				// ... (This can fail silently)
				}
			}

			// Store the order in the recall history
			dOrderHistoryAdd(checkoutOutcome);

			// Store in the component state
			setOutcome(checkoutOutcome);

			/**
			 * Reset the state
			 * 
			 * (Main submission is now complete)
			 */
			dBasketReset();
			dResetSearch();
			dBasketLoading(false);


			/**
			 * Carry out stationery operations now
			 *
			 * Note: We always want to render the stationery, 
			 * even if we found out above that we're not expecting 
			 * to print them - this is so that if for instance Gateway 
			 * was offline but subsequently comes online, the operator 
			 * is able to request a reprint and have it succeed.
			 */
			try {

				/**
				 * Render the stationery for our items
				 */
				const renderedStationery = OrderStationeryService.generateBasketItemsStationery(
					BasketItems,
					BasketDiscounts,
					(Registration.Stationery || {}),
					outcome.Order,
					outcome.OrderTimestamp,
					Registration.Device.Name,
					(outcome?.CheckoutData || {}),
					StationeryPrints.Barcodes,
					(outcome?.StationeryBarcodes || {})
				);


				setRenderedStationery(renderedStationery);

			}
			catch (e) {
				ErrorReporter.report(e);
			}

		}
		catch (error) {

			// Log TXN outcome telemetry
			try {
				await PosDeviceService.setTxnOutcome(outcome.Order, false, basket, error);
			}
			catch (e) {
				// ... (This can fail silently)
			}

			setError(error);
		}


		/**
		 * Done!
		 */
		dBasketLoading(false);

	}, [
		AuthUser,
		BasketItems,
		BasketDiscounts,
		BasketIdentity,
		BasketCustomerDetails,
		BasketQuestionsAnswers,
		Payments,
		PaymentsBalanceDue,
		PaymentsChange,
		PaymentsChangeIsDue,
		PaymentsIsSettled,
		Registration,
		gateway,
		isDepositCheckout,
		isProvisionalCheckout
	]);


	/**
	 * Print our stationery when it renders
	 */
	useEffect(() => {

		const renderStationery = async () => {
			try {
				const hasGatewayConnection = await gateway.checkConnectivity();
				if (hasGatewayConnection) printRenderedStationery();
			}
			catch (e) {
				// ...
			}
		};

		renderStationery();

	}, [gateway, printRenderedStationery]);


	/**
	 * Render the checkout stage.
	 * 
	 * @return {ReactNode}
	 */
	const renderCheckout = useCallback(() => {
		return (<CheckoutViewCheckoutStage
			{...props}
			error={error}
			license={Registration?.License}
			onCheckout={handleCheckout} />);
	}, [props, error, handleCheckout, Registration]);


	/**
	 * Render the success stage.
	 *
	 * @return {ReactNode}
	 */
	const renderSuccess = useCallback(() => {
		return (<CheckoutViewSuccessStage
			isStationeryPrinting={props.Ui.StationeryPrinting}
			onPrintStationery={((gateway.Active && renderedStationery?.length) ? printRenderedStationery : undefined)}
			PaymentOutcome={outcome}
			PaymentsZeroValueBasket={props.PaymentsZeroValueBasket} />);
	}, [gateway, outcome, printRenderedStationery, props.PaymentsZeroValueBasket, renderedStationery, props.Ui.StationeryPrinting]);


	/**
	 * Render!
	 */
	return (
		<Flex alignItems="center" gap={2}>
			{(!outcome ? renderCheckout() : renderSuccess())}
		</Flex>
	);

});

export default withAuthUser(withBasket(withPaymentType(withRegistration(withUi(CheckoutView)))));
