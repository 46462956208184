import {Hidden, String} from "@hps/hops-react";
import {Api} from "@hps/hops-sdk-js";
import {useCallback} from "react";

import withReducedFunctionality from "Hoc/withReducedFunctionality";
import InventoryGridItemBase from "Inventory/InventoryGridItemBase";

import scss from "./InventoryGridItemSessionProduct.module.scss";

const InventoryGridItemSessionProduct = ({product, orgId, onSelect, ReducedFunctionality}) => {

	const unavailable = ReducedFunctionality && !product.AvailableOffline;

	const handleSelect = useCallback(() => {
		onSelect(product);
	}, [product, onSelect]);

	return (
		<InventoryGridItemBase
			disabled={unavailable}
			imageSrc={`${Api.baseURL}/uploads/local/${orgId}/tickets/images/products/${product.Id}`}
			onClick={handleSelect}
			xs={12}
			sm={12}
			md={6}
			lg={6}
			xl={4}>
			<String
				classNameTypography={scss.SessionProductButtonTextLabel}
				color="primary"
				str={product.Name}
				variant="h6" />

			{/* Unavailable Text */}
			<Hidden hidden={!unavailable}>
				<String
					bold={true}
					color="error"
					str={"Unavailable Offline"}
					variant="subtitle1" />
			</Hidden>
		</InventoryGridItemBase>
	);

};

export default withReducedFunctionality(InventoryGridItemSessionProduct);
