import {Dialog, Divider, Flex, SnackbarService} from "@hps/hops-react";
import React, {useCallback, useRef, useState} from "react";

import CheckoutCustomerDetailsForm from "Checkout/CustomerDetails/CheckoutCustomerDetailsForm";
import OrderService from "Services/OrderService";

/**
 * Price input dialog
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const EditCustomerDetailsDialog = props => {

	const formRefBillingContactDetails = useRef();
	const formRefDeliveryContactDetails = useRef();

	const [loading, setLoading] = useState(false);
	const [billingContactDetails, setBillingContactDetails] = useState(props.order?.BillingContact);
	const [deliveryContactDetails, setDeliveryContactDetails] = useState(props.order?.DeliveryContact);

	const handleSave = async () => {

		setLoading(true);

		try {
			await OrderService.updateCustomerDetails(
				props.order.Id,
				billingContactDetails,
				deliveryContactDetails
			);
		}
		catch (ex) {
			SnackbarService.snack(ex);
		}

		setLoading(false);

		props.onSubmitted?.();
		props.onClose?.();

	};

	const handleBillingContactDetailsChange = useCallback((value, name) => {
		setBillingContactDetails({...billingContactDetails, [name]: value});
	}, [billingContactDetails]);

	const handleDeliveryContactDetailsChange = useCallback((value, name) => {
		setDeliveryContactDetails({...deliveryContactDetails, [name]: value});
	}, [deliveryContactDetails]);

	return (
		<Dialog
			disabled={loading}
			loading={loading}
			okLabel="Save"
			onOk={handleSave}
			onClose={props.onClose}
			open={props.open}
			title="Edit Customer Details">

			<Flex gap={2}>
				<CheckoutCustomerDetailsForm
					allowedDetails={[1, 2, 3, 4]}
					disabled={loading}
					formRef={formRefBillingContactDetails}
					onChange={handleBillingContactDetailsChange}
					title="Billing Contact"
					values={billingContactDetails} />
				<Divider/>
				<CheckoutCustomerDetailsForm
					allowedDetails={[1, 2, 3, 4]}
					disabled={loading}
					formRef={formRefDeliveryContactDetails}
					onChange={handleDeliveryContactDetailsChange}
					title="Delivery Contact"
					values={deliveryContactDetails} />
			</Flex>
		</Dialog>
	);

};

export default EditCustomerDetailsDialog;
