const {Chip} = require("@mui/material");

const SeatReservationChip = ({seat}) => {

	return <Chip
		color="warning"
		label={`${seat?.Item?.TrainAsset?.Letter} ${(seat?.Item?.Space?.Label || seat?.Item?.Space?.Id)}`.trim()}
		size="small"
	/>;

};

export default SeatReservationChip;
