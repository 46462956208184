import {Dialog, Flex, Hidden, String, TextField} from "@hps/hops-react";
import React, {useEffect, useState} from "react";

/**
 * Price input dialog
 * 
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const PrintTicketsConfirmationDialog = props => {

	const inputRef = React.createRef();

	const [error, setError] = useState(false);
	const [invalidatedReason, setInvalidatedReason] = useState(null);

	const handleChange = invalidatedReason => {
		setInvalidatedReason(invalidatedReason);
		setError(false);
	};

	useEffect(() => {
		if (error) inputRef?.current?.select?.();
	}, [error, inputRef]);

	const handleSubmit = e => {

		const willInvalidate = props.printLines.some(x => x.StationeryPrints?.length);

		e?.preventDefault?.();

		if ((!willInvalidate) || (willInvalidate && invalidatedReason)) props.onSubmit(invalidatedReason);
		else setError(true);

	};

	const formStyles = {width: "100%"};
	const inputProps = {step: 0.01};

	const willInvalidate = props.printLines.some(x => x.StationeryPrints?.length);

	return (
		<Dialog
			closeLabel={willInvalidate ? undefined : "No"}
			okLabel={willInvalidate ? undefined : "Yes"}
			onClose={props.onClose}
			onOk={handleSubmit}
			open={props.open}
			title="Print Tickets">
			<form
				onSubmit={handleSubmit}
				style={formStyles}>

				<String
					str="Are you sure you want to print this order stationery?" />

				<Hidden hidden={!willInvalidate}>
					<Flex gap={0}>
						<String
							color="error"
							str="This action will invalidate existing ticket barcode(s)." />

						<TextField
							autoFocus={true}
							error={error}
							errorText="Please enter an reason."
							fullWidth={true}
							inputProps={inputProps}
							inputRef={inputRef}
							label="Reason for re-printing tickets:"
							multiline={true}
							onChange={handleChange}
							onEnter={handleSubmit}
							required={willInvalidate}
							rows={3}
							value={invalidatedReason}
							variant="standard" />
					</Flex>
				</Hidden>

			</form>
		</Dialog>
	);

};

export default PrintTicketsConfirmationDialog;
