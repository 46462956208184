import {Link, String, TableList} from "@hps/hops-react";
import {PaymentStatuses, PriceUtils} from "@hps/hops-sdk-js";
import React from "react";

/**
 * Checkout order outcome details table
 *
 * Displays information about a checkout order result.
 * 
 * @package HOPS
 * @subpackage Checkout
 * @copyright Heritage Operations Processing Limited
 */
const CheckoutOutcomeDetailsTable = props => {

	const outcome = props.outcome;
	const paymentOutcome = outcome.PaymentOutcome;

	const data = [
		{
			label: "Order No.",
			content: () => (
				outcome.PosDevice.Offline ?
					<String str={outcome.Order?.toString()} /> :
					<Link
						endArrow={true}
						label={outcome.Order?.toString()}
						uri={`/search/orders/${outcome.Order?.toString()}`} />
			)
		},
		{
			label: `${(paymentOutcome ? "Paid Amount" : "Order Total")}`,
			content: PriceUtils.getDisplayStringIntl(outcome.OrderTotal)
		},
		{
			label: "Payment Status",
			content: () => (
				<String
					bold={true}
					color={((paymentOutcome?.Status === PaymentStatuses.Complete) ? "primary" : "error")}
					str={(Object.keys(PaymentStatuses)[Object.values(PaymentStatuses).indexOf(paymentOutcome?.Status)] || "Unknown")} />
			),
			hidden: !paymentOutcome
		}
	];


	return (
		<TableList
			data={data}
			labelsWidth="18rem"
			valuesWidth="18rem" />
	);

};

export default CheckoutOutcomeDetailsTable;
