import {Button, Flex, Paper, String} from "@hps/hops-react";
import {CheckoutBasketPayment, PaymentProcessorsEnum, PaymentTypes, PriceUtils} from "@hps/hops-sdk-js";
import {useState} from "react";

import dPaymentsInProgress from "Dispatchers/dPaymentsInProgress";
import dPaymentType from "Dispatchers/dPaymentType";

import PaymentProcessorBase from "./PaymentProcessorBase";

// the different notes available
const denominations = [500, 1000, 2000];

/**
 * Process a payment made with cash.
 * 
 * @param {object} onPaymentFailure callback for payment successful
 * @param {object} onPaymentSuccess callback for payment failure
 * @param {number} RequestedAmount in the currency's smallest minor unit (i.e. British Pennies in the UK)
 * @returns JSX
 */
const PaymentProcessorCash = ({onPaymentFailure, onPaymentSuccess, RequestedAmount}) => {

	const [tenderedAmount, setTenderedAmount] = useState(0);

	const getCashDueCeil =(cashDue, denomination) => {
		return Math.ceil(cashDue / (denomination)) * denomination;
	};

	const denominationAmounts = new Set([]); // the shortcut amounts to display for receiving cash, more than or equal to the cash due
	RequestedAmount > 0 && denominationAmounts.add(RequestedAmount); // the first denomination amount button is always the exact cash due
	denominations.forEach(d => {
		const cashDueCeil = getCashDueCeil(RequestedAmount, d);
		if (cashDueCeil !== RequestedAmount) {
			denominationAmounts.add(cashDueCeil);
		}
	});

	const handleCancel = () => {
		return onPaymentFailure({Type: "Cash", Requested: RequestedAmount, Received: null});
	};

	const handleOK = async () => {

		dPaymentsInProgress(true);

		await onPaymentSuccess([
			CheckoutBasketPayment.construct({
				PaymentProcessor: PaymentProcessorsEnum.HOPS,
				PaymentProcessorData: {
					TenderedAmount: tenderedAmount,
					Change: (Math.max(tenderedAmount - RequestedAmount, 0))
				},
				PaymentType: PaymentTypes.Cash,
				TenderedAmount: Number(tenderedAmount),
				Value: (Math.min(tenderedAmount, RequestedAmount))
			})
		]);

		dPaymentsInProgress(false);
		dPaymentType(null);
	};

	return (
		<Flex>
			<String bold={true} str="Cash Payment" variant="h5" />
			<Paper>
				<Flex alignItems="center">
					<PaymentProcessorBase
						CanOvercharge={true}
						onCancel={handleCancel}
						onChange={setTenderedAmount}
						onOK={handleOK}
						RequestedAmount={RequestedAmount}
						Title="Cash"
						value={tenderedAmount}>

						<Flex justifyContent="center" columnar={true} gap={0.5}>
							{[...denominationAmounts].map((amount, key) => {
								return <Button
									key={key}
									label={PriceUtils.getDisplayStringIntl(amount)}
									onClick={setTenderedAmount}
									value={amount}
									variant="contained" />;
							})}
						</Flex>

					</PaymentProcessorBase>
				</Flex>
			</Paper>
		</Flex>
	);
};

export default PaymentProcessorCash;
