import {ReactUtils, String} from "@hps/hops-react";
import React from "react";

import withReducedFunctionality from "Hoc/withReducedFunctionality";

/**
 * Device Offline gate
 *
 * Requires the device to be online (recent Heartbeat)
 * 
 * @package HOPS
 * @subpackage Components
 * @copyright Heritage Operations Processing Limited
 */
const AppOfflineGate = props => {

	if (!props.Offline) return (props.children || null);
	else return ((props.offlineState && ReactUtils.render(props.offlineState)) || <String color="textSecondary" str={(props.offlineMessage || "Unavailable - app is offline.")} />);

};

export default withReducedFunctionality(AppOfflineGate);
