import {memo} from "react";

import useGateway from "Hooks/useGateway.js";

const withGateway = Component => {

	return memo(props => {

		const gateway = useGateway();

		return (
			<Component
				{...props}
				gateway={gateway} />
		);

	});

};

export default withGateway;
