import {Store, StorePersistor} from "@hps/hops-react";
import {PaymentTypes} from "@hps/hops-sdk-js";
import moment from "moment/moment";
import {v4 as uuid} from "uuid";

import State from "./State";


/**
 * Async Reducers which are specific to this hosted app.
 * They are combined with the static reducer(s) in hops-react.
 */
export const addAsyncReducers = () => {

	const offlineBasketLifetimeMinutes = 15;

	/**
	 * A list of app-specific reducers to add to the host store.
	 * 
	 * When first written this mirrored the old class-based reducer.
	 * 
	 * As the app moves forward try to rationalise items in to stores
	 * such as Basket, Payments, UI, etc. and resolve all the action
	 * types within the same function.
	 * 
	 * @prop {String} key The name to use for the store
	 * @prop {Function} reducer The reducer function
	 * @prop {Boolean} persist If this reducer is added to the persist whitelist
	 */
	const appReducers = [
		{
			key: "AuthUser",
			reducer(state = State.AuthUser, action = {}) {
				switch (action.type) {
					case "authUser":
						return action.user;
					default:
						return state;
				}
			},
			persist: true
		},
		{
			key: "Basket",
			reducer(state = State.Basket, action = {}) {

				/** Basket is now Basket.Items! */

				switch (action.type) {
					case "basket":
						return {...state, Items: action.Basket};

					case "basketAdd":
						return {...state, Items: [...state.Items, ...action.items]};

					case "basketClaimData":
						return {...state, ClaimData: {Items: action.Items, Result: action.Result}};

					case "basketRemove":
						/* Only used in Offline mode, online mode dispatches "basket" filtered with the UUIDs of items still in the server basket. */
						return {...state, Items: state.Items.filter(i => !action.items.some(x => x.Uuid === i.Uuid))};

					case "basketReset":
						return State.Basket;

					case "basketUpdate": {
						const Items = [...state.Items];
						const index = Items.indexOf(state.Items.find(i => (i.Uuid === action.item)));
						if (index !== -1) Items[index] = {...Items[index], ...action.props};
						return {...state, Items};
					}

					case "basketCustomerDetails":
						return {...state, CustomerDetails: action.BasketCustomerDetails};

					case "basketDiscounts":
						return {...state, Discounts: action.BasketDiscounts};

					case "basketIdentity":
						return {
							...state,
							Identity: {
								...action.BasketIdentity,
								OfflineBasketUuid: null
							}
						};

					case "basketIdentityOffline":
						return {
							...state,
							Identity: {
								Id: null,
								OfflineBasketUuid: state.Identity?.OfflineBasketUuid ?? uuid(), // Preserve any existing basket UUID
								Expiry: Math.floor(moment(new Date()).add(offlineBasketLifetimeMinutes, "m").toDate() / 1000)
							}
						};

					case "basketLoading":
						return {...state, Loading: action.BasketLoading};

					case "basketQuestions":
						return {...state, Questions: action.BasketQuestions};

					case "basketQuestionsAnswers":
						return {...state, QuestionsAnswers: action.BasketQuestionsAnswers};

					case "basketQuestionsAnswersDeclined":
						return {...state, QuestionsAnswersDeclined: action.BasketQuestionsAnswersDeclined};

					case "payments":
						return {...state, Payments: action.Payments};

					case "paymentsAdd":
						return {...state, Payments: [...state.Payments, ...action.items]};

					case "paymentsRemove":
						return {...state, Payments: state.Payments.filter(i => !action.items.includes(i.Uuid))};

					case "paymentsUpdate": {
						const Payments = [...state.Payments];
						const index = Payments.indexOf(state.Payments.find(i => (i.Uuid === action.item)));
						if (index !== -1) Payments[index] = {...Payments[index], ...action.props};
						return {...state, Payments};
					}

					case "paymentType":
						return {...state, PaymentType: action.PaymentType};

					case "paymentsInProgress":
						return {...state, PaymentsInProgress: action.PaymentsInProgress};

					case "basketGoOffline":
					{
						return {
							...state,
							Identity: {
								Id: null,
								OfflineBasketUuid: state.Identity?.OfflineBasketUuid ?? uuid(),
								Expiry: Math.floor(moment(new Date()).add(offlineBasketLifetimeMinutes, "m").toDate() / 1000)
							},
							Discounts: [], // Discounts don't work offline
							Payments: state.Payments.filter(i => i.PaymentType !== PaymentTypes.Voucher,) // Vouchers can't be verified offline
						};
					}
					default:
						return state;
				}
			},
			persist: true
		},
		{
			key: "Cache",
			reducer(state = State.Cache, action = {}) {
				switch (action.type) {
					case "cacheFares":
						return {
							...state,
							Fares: action.Fares
						};

					default:
						return state;
				}
			},
			persist: true
		},
		{
			key: "Gateway",
			reducer(state = State.Gateway, action = {}) {
				switch (action.type) {
					case "gateway":
						return action.Gateway;
					default:
						return state;
				}
			},
			persist: true
		},
		{
			key: "Orders",
			reducer(state = State.Orders, action = {}) {
				switch (action.type) {
					case "orders/addHistory": {

						const midnight = new Date();
						midnight.setHours(0, 0, 0, 0);

						return {
							...state,
							History: [
								action.order, // Add current order to front of history
								...state.History // Filter existing orders, since midnight
									.filter(order => order.OrderTimestamp >= (midnight / 1000))
									.sort((a, b) => b.Order - a.Order)
							]
						};
					}
					case "orders/queue":
						return {...state, UploadQueue: state.UploadQueue.concat([action.order])};
					case "orders/syncing":
						return {...state, Syncing: true};
					case "orders/synced": {

						const time = Math.floor((Date.now() / 1000));
						const updateTime = ((action.pending.length === 0) && (state.UploadQueue.length > 0));

						return {
							...state,
							Syncing: false,
							UploadQueue: action.pending,
							SyncTime: (updateTime ? time : state.SyncTime)
						};

					}
					default:
						return state;
				}
			},
			persist: true
		},
		{
			key: "Settings",
			reducer(state = State.Settings, action = {}) {
				switch (action.type) {
					case "settings":
						return action.Settings;
					default:
						return state;
				}
			},
			persist: true
		},
		{
			key: "Tickets",
			reducer(state = State.Tickets, action = {}) {
				switch (action.type) {
					case "ticketsSelection":
						return {
							...state,
							Selection: action.TicketsSelection,
							SelectionKey: (state.SelectionKey + 1)
						};

					case "ticketsSelectionMerge":
						return {
							...state,
							Selection: {...state.Selection, ...action.TicketsSelection},
							SelectionKey: (state.SelectionKey + 1)
						};

					case "ticketsJourneySelectionKeyIncrement":
						return {
							...state,
							SelectionKey: state.SelectionKey + 1
						};

					default:
						return state;
				}
			}
		},
		{
			key: "Ui",
			reducer(state = State.Ui, action = {}) {

				switch (action.type) {
					case "ui/customerServicesTab":
						return {...state, CustomerServicesTab: action.CustomerServicesTab};
					case "ui/inventoryTab":
						return {...state, InventoryTab: action.InventoryTab};
					case "ui/logoutDialog":
						return {...state, LogoutDialog: action.LogoutDialog};
					case "ui/onlineApp":
						return {...state, OnlineApp: action.OnlineApp};
					case "ui/onlineNetwork":
						return {...state, OnlineNetwork: action.OnlineNetwork};
					case "ui/questionsDialog":
						return {...state, QuestionsDialog: action.QuestionsDialog};
					case "ui/refund":
						return {...state, Refund: action.Refund};
					case "ui/seatReservationDialog":
						return {...state, SeatReservationDialog: action.SeatReservationDialog};
					case "ui/stationeryPrinting":
						return {...state, StationeryPrinting: action.StationeryPrinting};
					default:
						return state;
				}
			},
			persist: true
		}
	];

	Store.addAsyncReducers(appReducers);

	StorePersistor.persist();

};

export default addAsyncReducers;
