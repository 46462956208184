import {Card, String} from "@hps/hops-react";
import {Grid} from "@mui/material";
import React from "react";

import UserIcon from "@mui/icons-material/Person";

import scss from "./LoginUserSelectorItem.module.scss";

/**
 * A user card on the login user selector view
 * 
 * @package HOPS
 * @subpackage Login
 * @copyright Heritage Operations Processing Limited
 */
const LoginUserSelectorItem = props => {

	const FlexProps = {alignItems: "center"};

	return (
		<Grid item xs={12} sm={6} md={4} lg={4} xl={4} className={scss.GridItem}>
			<Card
				classNameContentArea={scss.cardContent}
				FlexProps={FlexProps}
				horizontal={true}
				onClick={props.onUserSelect}
				outlined={true}
				px={0}
				py={0}
				media={<UserIcon fontSize="large" />}
				style={{width: "1em !important"}}
				value={props.user}>
				<String
					centre={true}
					str={`${props.user.Account.Fname} ${props.user.Account.Sname}`}
					variant="h6" />
			</Card>
		</Grid>
	);

};

export default LoginUserSelectorItem;
